/* Dashboard CSS */

/* Fonts */

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v30-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v30-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v30-latin-700.woff2') format('woff2');
}

/* Icons */

@import "./icons/feather";

/* Abstracts */

@import './abstracts/variable';
@import './abstracts/inheritance';
@import './abstracts/mixin';
@import './abstracts/maps';

/* Bootstrap */

@import "./src/assets/vendors/bootstrap/scss/bootstrap";

/* Base */

@import './base/helper';
@import './base/reset';
@import './base/colors';
@import './base/custom-grid';

/* Layout */

@import './layout/footer/footer';

@import './layout/header/nav-header/nav-control';
@import './layout/header/header-global';
@import './layout/header/header-left';
@import './layout/header/header-right';

@import './layout/sidebar/sidebar-global';
@import './layout/sidebar/mega-menu';
@import './layout/sidebar/sidebar-full';
@import './layout/sidebar/sidebar-mini-nav';
@import './layout/sidebar/sidebar-horizontal';
@import './layout/sidebar/sidebar-compact-nav';
@import './layout/sidebar/sidebar-icon-hover';
@import './layout/sidebar/sidebar-modern';
@import './layout/sidebar/sidebar-overlay';
@import './layout/sidebar/sidebar-vertical-nav';

@import './layout/theme/theme-bg';
@import './layout/theme/theme-boxed';
@import './layout/theme/theme-wide-boxed';
@import './layout/theme/theme-color';

@import "./layout/version-dark/dark-reset";
@import "./layout/version-dark/dark-global";
@import "./layout/version-dark/dark-header";
@import "./layout/version-dark/dark-nav-header";
@import "./layout/version-dark/dark-left-sidebar";
@import "./layout/version-dark/dark-footer";

/* Components */

@import "./components/ui/ui-accordion";
@import "./components/ui/ui-alert";
@import "./components/ui/ui-badge";
@import "./components/ui/ui-breadcrumb";
@import "./components/ui/ui-button";
@import "./components/ui/ui-card";
@import "./components/ui/ui-carousel";
@import "./components/ui/ui-dropdown";
@import "./components/ui/ui-grid";
@import "./components/ui/ui-label";
@import "./components/ui/ui-list-group";
@import "./components/ui/ui-media";
@import "./components/ui/ui-menu";
@import "./components/ui/ui-modal";
@import "./components/ui/ui-pagination";
@import "./components/ui/ui-progressbar";
@import "./components/ui/ui-ribbon";
@import "./components/ui/ui-scrollbar";
@import "./components/ui/ui-step";
@import "./components/ui/ui-tab";
@import "./components/ui/ui-tooltip";
@import "./components/ui/ui-timeline";

@import "./components/widget/widget-stat";
@import "./components/widget/widget-card-list";
@import "./components/widget/widget-media";

@import "./components/forms/form-basic";
@import "./components/forms/form-checkbox";
@import "./components/forms/form-radio-button";
@import "./components/forms/form-switch";
@import "./components/forms/form-pickers";
@import "./components/forms/form-addons-select2";
@import "./components/forms/form-dropzone";
@import "./components/forms/form-summernote";
@import "./components/forms/form-signature";

@import "./components/tables/table-basic";
@import "./components/tables/table-datatable";
