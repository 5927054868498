
[data-theme-version="dark"] {
    .dropdown-menu {
        background-color: $d-bg;
		box-shadow: 0px 0px 0px 1px rgba(255, 255, 255,0.1);
		
		.dropdown-item {
			&.selected,
			&.selected.active,
			&.active,
			&:hover, 
			&:focus {
				background-color: $dark-card;
				color: $white;
			}
		}
    }
	.dropdown{
		svg{
			path{
				stroke:$white;
			}
		}
	}
	.nav-tabs.card-body-tabs{
		background-color: transparent;
	}
    .form-control {
        background-color: $d-bg;
        border-color: $d-border;
        color: $white;
		&:hover{
			border-color:$d-border;
		}
    }
    .author-profile .info-list li,
	.profile-card .card-footer,
	.author-profile .upload-link{
		border-color:$d-border;
	}
	.author-profile .info-list li a,
	.author-profile .author-info span,
	.author-profile .info-list li span{
		color:rgba(255,255,255,0.6);
	}
	.author-profile .card-footer .form-control{
		border-color:$d-border;
		background:$d-bg!important;
		
	}
	.sidebar-right .form-control {
		background-color: $white;
        border-color: $border-color;
		 .btn {
			border-color: $border-color !important;
			color: #000 !important;
		}
		.dropdown-menu{
			background-color:$white;
			border-color:$body-color;
		}
		.dropdown-item.active.selected{
			background-color:$border-color;
			border-color:$body-color;
		}
		.dropdown-item{
			&:hover{
				background-color:$border-color;
				border-color:$body-color;
				color:#828690;
			}
		}
	}
    .card {
        background-color: $dark-card;
		box-shadow:none;
		border-color:$d-bg;
    }
	.btn-outline-dark:hover{
		background-color: $d-bg;
		border-color: $d-bg;
	}
	.tdl-holder input[type=text],
	.tdl-holder li{
	    background-color: $dark-card;
		border-color:$d-border;
	}
	.tdl-holder input[type=text]{
		&::placeholder {
			color: $d-ctl;
		}
	}
    .tdl-holder label:hover {
		background-color: $d-border;
		color: $white;
	}
	.text-muted {
		color: $d-ctl !important;
	}
	.modal-content{
		background:$dark-card;
	}
	.modal-footer,
	.modal-header{
		border-color: $d-border;
	}
	.close{
	    font-weight: 400;
		color: $white;
		text-shadow: none;
		
		&:hover{
			color: $white;
		}
	}
	.table strong {
		color: #fff;
	}
	.text-dark {
		color: $white !important;
	}
	.star-rating .product-review,
	.new-arrival-content .item{
		color: $white;
	}
	.custom-dropdown .dropdown-menu{
		border-color: $d-border;
	}
	.widget-stat .media > span{
	    background: $d-border;
	    border-color: $d-border;
		color:$white;
	}
	.plus-minus-input .custom-btn{
		background: $d-bg;
	    border-color: $d-border;
	}
	.dropdown-divider,
	.size-filter ul li{
		border-color: $d-border;
	}
	.custom-select{
	    border-color: $d-border;
		color: $d-ctl;
		background:$d-bg;
	}
	.nav-tabs{
		border-color: $d-border;
		background-color:$d-bg;	
	}
	.default-tab {
		.nav-tabs{
			background-color:transparent;	
		}			
	}
	.custom-tab-1{
		.nav-tabs{
			background-color:transparent;	
		}
	}
	.tab-width-icon{
		.nav-tabs{
			background-color:transparent;	
		}
	}
	.mail-list .list-group-item.active i {
		color: $white;
	}
	hr{
		border-color: $d-border;
	}
	
	.grid-col{
		background:$d-bg;
		color:$white;
	}
	
	.noUi-target{
		border-color:$d-border;
		border-radius: 8px;
		box-shadow: none;
		
		.noUi-connects{
			background:$d-border;
		}
	}
	.noUi-marker-large,
	.noUi-marker{
		background:$d-border;
	}
	.input-group-text{
		background:$dark-card;
		color:$body-color;
		border-color:$d-border;
		
	}
	.note-editor.note-frame{
		border-color:$d-border;
		.btn{
			color:$white;
		}
		.note-editing-area .note-editable{
			color:$white;
		}
	}
	.widget-media .timeline .timeline-panel{
		border-color:$d-border; 
	}
	.notification_dropdown .dropdown-menu-right .all-notification{
		border-color:$d-border; 
	}
	#user-activity .nav-tabs .nav-link{
		border-color:$d-border; 
	}
	.list-group-item-action{
		color:$body-color;
	}
	.list-group-item-action:focus, 
	.list-group-item-action:hover, 
	.list-group-item-action:focus{
		background-color:$d-bg; 
		border-color:$d-bg; 
	}
	.list-group-item.active{
		color:$white;
		border-color: var(--primary);
		&:focus, 
		&:hover, 
		&:focus{
			background-color:var(--primary); 
			border-color:var(--primary); 
			color:$white;
		}
	}
	.swal2-popup{
		background:$dark-card;
	}
	
	.daterangepicker{
		background-color:$dark-card;
		border-color: $d-border;
	}
	.dataTables_wrapper .dataTables_filter{
		color: $white;
	}
	.dataTables_wrapper .dataTables_info{
		color:$white;
	}
	td {
		.btn-link{
			svg{
				g{
					circle{
						fill:#fff;
					}
				}
			}
		}
	}
	
#radialChart {
		svg{
			g{
				circle{
					fill:#1e2746;
				}
				text{
					fill:#ffffff;
				}				
			}
		}
	} 

}

