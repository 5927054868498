@charset "UTF-8";
/* Dashboard CSS */
/* Fonts */
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto/roboto-v30-latin-300.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/roboto-v30-latin-regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto/roboto-v30-latin-500.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto/roboto-v30-latin-700.woff2") format("woff2");
}
/* Icons */
@font-face {
  font-family: "feather";
  src: url("../fonts/feather/feather.ttf?4mrs4q") format("truetype"), url("../fonts/feather/feather.woff?4mrs4q") format("woff"), url("../fonts/feather/feather.svg?4mrs4q#feather") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\e900";
}

.icon-airplay:before {
  content: "\e901";
}

.icon-alert-circle:before {
  content: "\e902";
}

.icon-alert-octagon:before {
  content: "\e903";
}

.icon-alert-triangle:before {
  content: "\e904";
}

.icon-align-center:before {
  content: "\e905";
}

.icon-align-justify:before {
  content: "\e906";
}

.icon-align-left:before {
  content: "\e907";
}

.icon-align-right:before {
  content: "\e908";
}

.icon-anchor:before {
  content: "\e909";
}

.icon-aperture:before {
  content: "\e90a";
}

.icon-archive:before {
  content: "\e90b";
}

.icon-arrow-down:before {
  content: "\e90c";
}

.icon-arrow-down-circle:before {
  content: "\e90d";
}

.icon-arrow-down-left:before {
  content: "\e90e";
}

.icon-arrow-down-right:before {
  content: "\e90f";
}

.icon-arrow-left:before {
  content: "\e910";
}

.icon-arrow-left-circle:before {
  content: "\e911";
}

.icon-arrow-right:before {
  content: "\e912";
}

.icon-arrow-right-circle:before {
  content: "\e913";
}

.icon-arrow-up:before {
  content: "\e914";
}

.icon-arrow-up-circle:before {
  content: "\e915";
}

.icon-arrow-up-left:before {
  content: "\e916";
}

.icon-arrow-up-right:before {
  content: "\e917";
}

.icon-at-sign:before {
  content: "\e918";
}

.icon-award:before {
  content: "\e919";
}

.icon-bar-chart:before {
  content: "\e91a";
}

.icon-bar-chart-2:before {
  content: "\e91b";
}

.icon-battery:before {
  content: "\e91c";
}

.icon-battery-charging:before {
  content: "\e91d";
}

.icon-bell:before {
  content: "\e91e";
}

.icon-bell-off:before {
  content: "\e91f";
}

.icon-bluetooth:before {
  content: "\e920";
}

.icon-bold:before {
  content: "\e921";
}

.icon-book:before {
  content: "\e922";
}

.icon-book-open:before {
  content: "\e923";
}

.icon-bookmark:before {
  content: "\e924";
}

.icon-box:before {
  content: "\e925";
}

.icon-briefcase:before {
  content: "\e926";
}

.icon-calendar:before {
  content: "\e927";
}

.icon-camera:before {
  content: "\e928";
}

.icon-camera-off:before {
  content: "\e929";
}

.icon-cast:before {
  content: "\e92a";
}

.icon-check:before {
  content: "\e92b";
}

.icon-check-circle:before {
  content: "\e92c";
}

.icon-check-square:before {
  content: "\e92d";
}

.icon-chevron-down:before {
  content: "\e92e";
}

.icon-chevron-left:before {
  content: "\e92f";
}

.icon-chevron-right:before {
  content: "\e930";
}

.icon-chevron-up:before {
  content: "\e931";
}

.icon-chevrons-down:before {
  content: "\e932";
}

.icon-chevrons-left:before {
  content: "\e933";
}

.icon-chevrons-right:before {
  content: "\e934";
}

.icon-chevrons-up:before {
  content: "\e935";
}

.icon-chrome:before {
  content: "\e936";
}

.icon-circle:before {
  content: "\e937";
}

.icon-clipboard:before {
  content: "\e938";
}

.icon-clock:before {
  content: "\e939";
}

.icon-cloud:before {
  content: "\e93a";
}

.icon-cloud-drizzle:before {
  content: "\e93b";
}

.icon-cloud-lightning:before {
  content: "\e93c";
}

.icon-cloud-off:before {
  content: "\e93d";
}

.icon-cloud-rain:before {
  content: "\e93e";
}

.icon-cloud-snow:before {
  content: "\e93f";
}

.icon-code:before {
  content: "\e940";
}

.icon-codepen:before {
  content: "\e941";
}

.icon-codesandbox:before {
  content: "\e942";
}

.icon-coffee:before {
  content: "\e943";
}

.icon-columns:before {
  content: "\e944";
}

.icon-command:before {
  content: "\e945";
}

.icon-compass:before {
  content: "\e946";
}

.icon-copy:before {
  content: "\e947";
}

.icon-corner-down-left:before {
  content: "\e948";
}

.icon-corner-down-right:before {
  content: "\e949";
}

.icon-corner-left-down:before {
  content: "\e94a";
}

.icon-corner-left-up:before {
  content: "\e94b";
}

.icon-corner-right-down:before {
  content: "\e94c";
}

.icon-corner-right-up:before {
  content: "\e94d";
}

.icon-corner-up-left:before {
  content: "\e94e";
}

.icon-corner-up-right:before {
  content: "\e94f";
}

.icon-cpu:before {
  content: "\e950";
}

.icon-credit-card:before {
  content: "\e951";
}

.icon-crop:before {
  content: "\e952";
}

.icon-crosshair:before {
  content: "\e953";
}

.icon-database:before {
  content: "\e954";
}

.icon-delete:before {
  content: "\e955";
}

.icon-disc:before {
  content: "\e956";
}

.icon-dollar-sign:before {
  content: "\e957";
}

.icon-download:before {
  content: "\e958";
}

.icon-download-cloud:before {
  content: "\e959";
}

.icon-droplet:before {
  content: "\e95a";
}

.icon-edit:before {
  content: "\e95b";
}

.icon-edit-2:before {
  content: "\e95c";
}

.icon-edit-3:before {
  content: "\e95d";
}

.icon-external-link:before {
  content: "\e95e";
}

.icon-eye:before {
  content: "\e95f";
}

.icon-eye-off:before {
  content: "\e960";
}

.icon-facebook:before {
  content: "\e961";
}

.icon-fast-forward:before {
  content: "\e962";
}

.icon-feather:before {
  content: "\e963";
}

.icon-figma:before {
  content: "\e964";
}

.icon-file:before {
  content: "\e965";
}

.icon-file-minus:before {
  content: "\e966";
}

.icon-file-plus:before {
  content: "\e967";
}

.icon-file-text:before {
  content: "\e968";
}

.icon-film:before {
  content: "\e969";
}

.icon-filter:before {
  content: "\e96a";
}

.icon-flag:before {
  content: "\e96b";
}

.icon-folder:before {
  content: "\e96c";
}

.icon-folder-minus:before {
  content: "\e96d";
}

.icon-folder-plus:before {
  content: "\e96e";
}

.icon-framer:before {
  content: "\e96f";
}

.icon-frown:before {
  content: "\e970";
}

.icon-gift:before {
  content: "\e971";
}

.icon-git-branch:before {
  content: "\e972";
}

.icon-git-commit:before {
  content: "\e973";
}

.icon-git-merge:before {
  content: "\e974";
}

.icon-git-pull-request:before {
  content: "\e975";
}

.icon-github:before {
  content: "\e976";
}

.icon-gitlab:before {
  content: "\e977";
}

.icon-globe:before {
  content: "\e978";
}

.icon-grid:before {
  content: "\e979";
}

.icon-hard-drive:before {
  content: "\e97a";
}

.icon-hash:before {
  content: "\e97b";
}

.icon-headphones:before {
  content: "\e97c";
}

.icon-heart:before {
  content: "\e97d";
}

.icon-help-circle:before {
  content: "\e97e";
}

.icon-hexagon:before {
  content: "\e97f";
}

.icon-home:before {
  content: "\e980";
}

.icon-image:before {
  content: "\e981";
}

.icon-inbox:before {
  content: "\e982";
}

.icon-info:before {
  content: "\e983";
}

.icon-instagram:before {
  content: "\e984";
}

.icon-italic:before {
  content: "\e985";
}

.icon-key:before {
  content: "\e986";
}

.icon-layers:before {
  content: "\e987";
}

.icon-layout:before {
  content: "\e988";
}

.icon-life-buoy:before {
  content: "\e989";
}

.icon-link:before {
  content: "\e98a";
}

.icon-link-2:before {
  content: "\e98b";
}

.icon-linkedin:before {
  content: "\e98c";
}

.icon-list:before {
  content: "\e98d";
}

.icon-loader:before {
  content: "\e98e";
}

.icon-lock:before {
  content: "\e98f";
}

.icon-log-in:before {
  content: "\e990";
}

.icon-log-out:before {
  content: "\e991";
}

.icon-mail:before {
  content: "\e992";
}

.icon-map:before {
  content: "\e993";
}

.icon-map-pin:before {
  content: "\e994";
}

.icon-maximize:before {
  content: "\e995";
}

.icon-maximize-2:before {
  content: "\e996";
}

.icon-meh:before {
  content: "\e997";
}

.icon-menu:before {
  content: "\e998";
}

.icon-message-circle:before {
  content: "\e999";
}

.icon-message-square:before {
  content: "\e99a";
}

.icon-mic:before {
  content: "\e99b";
}

.icon-mic-off:before {
  content: "\e99c";
}

.icon-minimize:before {
  content: "\e99d";
}

.icon-minimize-2:before {
  content: "\e99e";
}

.icon-minus:before {
  content: "\e99f";
}

.icon-minus-circle:before {
  content: "\e9a0";
}

.icon-minus-square:before {
  content: "\e9a1";
}

.icon-monitor:before {
  content: "\e9a2";
}

.icon-moon:before {
  content: "\e9a3";
}

.icon-more-horizontal:before {
  content: "\e9a4";
}

.icon-more-vertical:before {
  content: "\e9a5";
}

.icon-mouse-pointer:before {
  content: "\e9a6";
}

.icon-move:before {
  content: "\e9a7";
}

.icon-music:before {
  content: "\e9a8";
}

.icon-navigation:before {
  content: "\e9a9";
}

.icon-navigation-2:before {
  content: "\e9aa";
}

.icon-octagon:before {
  content: "\e9ab";
}

.icon-package:before {
  content: "\e9ac";
}

.icon-paperclip:before {
  content: "\e9ad";
}

.icon-pause:before {
  content: "\e9ae";
}

.icon-pause-circle:before {
  content: "\e9af";
}

.icon-pen-tool:before {
  content: "\e9b0";
}

.icon-percent:before {
  content: "\e9b1";
}

.icon-phone:before {
  content: "\e9b2";
}

.icon-phone-call:before {
  content: "\e9b3";
}

.icon-phone-forwarded:before {
  content: "\e9b4";
}

.icon-phone-incoming:before {
  content: "\e9b5";
}

.icon-phone-missed:before {
  content: "\e9b6";
}

.icon-phone-off:before {
  content: "\e9b7";
}

.icon-phone-outgoing:before {
  content: "\e9b8";
}

.icon-pie-chart:before {
  content: "\e9b9";
}

.icon-play:before {
  content: "\e9ba";
}

.icon-play-circle:before {
  content: "\e9bb";
}

.icon-plus:before {
  content: "\e9bc";
}

.icon-plus-circle:before {
  content: "\e9bd";
}

.icon-plus-square:before {
  content: "\e9be";
}

.icon-pocket:before {
  content: "\e9bf";
}

.icon-power:before {
  content: "\e9c0";
}

.icon-printer:before {
  content: "\e9c1";
}

.icon-radio:before {
  content: "\e9c2";
}

.icon-refresh-ccw:before {
  content: "\e9c3";
}

.icon-refresh-cw:before {
  content: "\e9c4";
}

.icon-repeat:before {
  content: "\e9c5";
}

.icon-rewind:before {
  content: "\e9c6";
}

.icon-rotate-ccw:before {
  content: "\e9c7";
}

.icon-rotate-cw:before {
  content: "\e9c8";
}

.icon-rss:before {
  content: "\e9c9";
}

.icon-save:before {
  content: "\e9ca";
}

.icon-scissors:before {
  content: "\e9cb";
}

.icon-search:before {
  content: "\e9cc";
}

.icon-send:before {
  content: "\e9cd";
}

.icon-server:before {
  content: "\e9ce";
}

.icon-settings:before {
  content: "\e9cf";
}

.icon-share:before {
  content: "\e9d0";
}

.icon-share-2:before {
  content: "\e9d1";
}

.icon-shield:before {
  content: "\e9d2";
}

.icon-shield-off:before {
  content: "\e9d3";
}

.icon-shopping-bag:before {
  content: "\e9d4";
}

.icon-shopping-cart:before {
  content: "\e9d5";
}

.icon-shuffle:before {
  content: "\e9d6";
}

.icon-sidebar:before {
  content: "\e9d7";
}

.icon-skip-back:before {
  content: "\e9d8";
}

.icon-skip-forward:before {
  content: "\e9d9";
}

.icon-slack:before {
  content: "\e9da";
}

.icon-slash:before {
  content: "\e9db";
}

.icon-sliders:before {
  content: "\e9dc";
}

.icon-smartphone:before {
  content: "\e9dd";
}

.icon-smile:before {
  content: "\e9de";
}

.icon-speaker:before {
  content: "\e9df";
}

.icon-square:before {
  content: "\e9e0";
}

.icon-star:before {
  content: "\e9e1";
}

.icon-stop-circle:before {
  content: "\e9e2";
}

.icon-sun:before {
  content: "\e9e3";
}

.icon-sunrise:before {
  content: "\e9e4";
}

.icon-sunset:before {
  content: "\e9e5";
}

.icon-tablet:before {
  content: "\e9e6";
}

.icon-tag:before {
  content: "\e9e7";
}

.icon-target:before {
  content: "\e9e8";
}

.icon-terminal:before {
  content: "\e9e9";
}

.icon-thermometer:before {
  content: "\e9ea";
}

.icon-thumbs-down:before {
  content: "\e9eb";
}

.icon-thumbs-up:before {
  content: "\e9ec";
}

.icon-toggle-left:before {
  content: "\e9ed";
}

.icon-toggle-right:before {
  content: "\e9ee";
}

.icon-tool:before {
  content: "\e9ef";
}

.icon-trash:before {
  content: "\e9f0";
}

.icon-trash-2:before {
  content: "\e9f1";
}

.icon-trello:before {
  content: "\e9f2";
}

.icon-trending-down:before {
  content: "\e9f3";
}

.icon-trending-up:before {
  content: "\e9f4";
}

.icon-triangle:before {
  content: "\e9f5";
}

.icon-truck:before {
  content: "\e9f6";
}

.icon-tv:before {
  content: "\e9f7";
}

.icon-twitch:before {
  content: "\e9f8";
}

.icon-twitter:before {
  content: "\e9f9";
}

.icon-type:before {
  content: "\e9fa";
}

.icon-umbrella:before {
  content: "\e9fb";
}

.icon-underline:before {
  content: "\e9fc";
}

.icon-unlock:before {
  content: "\e9fd";
}

.icon-upload:before {
  content: "\e9fe";
}

.icon-upload-cloud:before {
  content: "\e9ff";
}

.icon-user:before {
  content: "\ea00";
}

.icon-user-check:before {
  content: "\ea01";
}

.icon-user-minus:before {
  content: "\ea02";
}

.icon-user-plus:before {
  content: "\ea03";
}

.icon-user-x:before {
  content: "\ea04";
}

.icon-users:before {
  content: "\ea05";
}

.icon-video:before {
  content: "\ea06";
}

.icon-video-off:before {
  content: "\ea07";
}

.icon-voicemail:before {
  content: "\ea08";
}

.icon-volume:before {
  content: "\ea09";
}

.icon-volume-1:before {
  content: "\ea0a";
}

.icon-volume-2:before {
  content: "\ea0b";
}

.icon-volume-x:before {
  content: "\ea0c";
}

.icon-watch:before {
  content: "\ea0d";
}

.icon-wifi:before {
  content: "\ea0e";
}

.icon-wifi-off:before {
  content: "\ea0f";
}

.icon-wind:before {
  content: "\ea10";
}

.icon-x:before {
  content: "\ea11";
}

.icon-x-circle:before {
  content: "\ea12";
}

.icon-x-octagon:before {
  content: "\ea13";
}

.icon-x-square:before {
  content: "\ea14";
}

.icon-youtube:before {
  content: "\ea15";
}

.icon-zap:before {
  content: "\ea16";
}

.icon-zap-off:before {
  content: "\ea17";
}

.icon-zoom-in:before {
  content: "\ea18";
}

.icon-zoom-out:before {
  content: "\ea19";
}

/* Abstracts */
:root {
  --primary: #002381;
  --secondary: #5C3799;
  --primary-hover: #00154e;
  --primary-dark: black;
  --rgba-primary-1: rgba(0, 35, 129, 0.1);
  --rgba-primary-2: rgba(0, 35, 129, 0.2);
  --rgba-primary-3: rgba(0, 35, 129, 0.3);
  --rgba-primary-4: rgba(0, 35, 129, 0.4);
  --rgba-primary-5: rgba(0, 35, 129, 0.5);
  --rgba-primary-6: rgba(0, 35, 129, 0.6);
  --rgba-primary-7: rgba(0, 35, 129, 0.7);
  --rgba-primary-8: rgba(0, 35, 129, 0.8);
  --rgba-primary-9: rgba(0, 35, 129, 0.9);
  --rgba-secondary-1: rgba(92, 55, 153, 0.1);
  --rgba-secondary-2: rgba(92, 55, 153, 0.2);
  --rgba-secondary-3: rgba(92, 55, 153, 0.3);
  --rgba-secondary-4: rgba(92, 55, 153, 0.4);
  --rgba-secondary-5: rgba(92, 55, 153, 0.5);
  --rgba-secondary-6: rgba(92, 55, 153, 0.6);
  --rgba-secondary-7: rgba(92, 55, 153, 0.7);
  --rgba-secondary-8: rgba(92, 55, 153, 0.8);
  --rgba-secondary-9: rgba(92, 55, 153, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #424562;
}

:root {
  --width: 17.5rem;
  --height: 5rem;
}

.badge.bg-primary {
  background: var(--primary);
}

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f0a907", endColorstr="#f53c79",GradientType=0 );
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4dedf5", endColorstr="#480ceb",GradientType=0 );
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#51f5ae", endColorstr="#3fbcda",GradientType=0 );
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f25521", endColorstr="#f9c70a",GradientType=1 );
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f53c79", endColorstr="#f0a907",GradientType=1 );
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#36b9d8", endColorstr="#4bffa2",GradientType=1 );
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4400eb", endColorstr="#44e7f5",GradientType=1 );
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#F7B00F", endColorstr="#F25521",GradientType=1 );
}

.datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today:hover, .datepicker table tr td.today, .datepicker table tr td.selected, .datepicker table tr td.active.active, .datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover, .gradient-9 {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f31e7a", endColorstr="#fd712c",GradientType=1 );
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f25521", endColorstr="#f9c70a",GradientType=1 );
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#3398fb", endColorstr="#8553ee",GradientType=1 );
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#36e1b4", endColorstr="#11cae7",GradientType=1 );
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffbf31", endColorstr="#ff890e",GradientType=1 );
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#23bdb8", endColorstr="#43e794",GradientType=1 );
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#9a56ff", endColorstr="#e36cd9",GradientType=1 );
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f48665", endColorstr="#fda23f",GradientType=1 );
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e36cd9", endColorstr="#fe60ae",GradientType=1 );
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a15cff", endColorstr="#ce82fd",GradientType=1 );
}

/*
	0 - 600: Phone
	600 - 900: Tablet portrait
	900 - 1200: Tablet landscape
	1200 - 1800: Normal styles
	1800+ : Big Desktop
	1em = 16px
	The smaller device rules always should write below the bigger device rules
	Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/* Bootstrap */
/*!
 * Bootstrap  v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #374C98;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #EB62D0;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #002381;
  --bs-secondary: #5C3799;
  --bs-success: #1EBA62;
  --bs-info: #002381;
  --bs-warning: #FFAB2D;
  --bs-danger: #fd5353;
  --bs-light: #dedeed;
  --bs-dark: #333333;
  --bs-primary-rgb: 0, 35, 129;
  --bs-secondary-rgb: 92, 55, 153;
  --bs-success-rgb: 30, 186, 98;
  --bs-info-rgb: 0, 35, 129;
  --bs-warning-rgb: 255, 171, 45;
  --bs-danger-rgb: 253, 83, 83;
  --bs-light-rgb: 222, 222, 237;
  --bs-dark-rgb: 51, 51, 51;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 51, 51, 51;
  --bs-body-bg-rgb: 244, 244, 244;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #333333;
  --bs-body-bg: #F4F4F4;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #EEEEEE;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #002381;
  --bs-link-hover-color: #001c67;
  --bs-code-color: #EB62D0;
  --bs-highlight-bg: #fffee2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #424562;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #89879f;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\a0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F4F4F4;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.875rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.875rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccd3e6;
  --bs-table-border-color: #b8becf;
  --bs-table-striped-bg: #c2c8db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8becf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc3d5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #ded7eb;
  --bs-table-border-color: #c8c2d4;
  --bs-table-striped-bg: #d3ccdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8c2d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cdc7d9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d2f1e0;
  --bs-table-border-color: #bdd9ca;
  --bs-table-striped-bg: #c8e5d5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdd9ca;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2dfcf;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccd3e6;
  --bs-table-border-color: #b8becf;
  --bs-table-striped-bg: #c2c8db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8becf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc3d5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffeed5;
  --bs-table-border-color: #e6d6c0;
  --bs-table-striped-bg: #f2e2ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d6c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdcc5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffdddd;
  --bs-table-border-color: #e6c7c7;
  --bs-table-striped-bg: #f2d2d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c7c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccccc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #dedeed;
  --bs-table-border-color: #c8c8d5;
  --bs-table-striped-bg: #d3d3e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8c8d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cdcddb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #333333;
  --bs-table-border-color: #474747;
  --bs-table-striped-bg: #3d3d3d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #474747;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #424242;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #89879f;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #F4F4F4;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  appearance: none;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #333333;
  background-color: #F4F4F4;
  border-color: #8091c0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #333333;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #333333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.75rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.75rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  background-color: #F4F4F4;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8091c0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333333;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #F4F4F4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8091c0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.form-check-input:checked {
  background-color: #002381;
  border-color: #002381;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #002381;
  border-color: #002381;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238091c0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F4F4F4, 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F4F4F4, 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #002381;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3bdd9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #002381;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3bdd9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #1EBA62;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(30, 186, 98, 0.9);
  border-radius: 0.75rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1EBA62;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231EBA62' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #1EBA62;
  box-shadow: 0 0 0 0.25rem rgba(30, 186, 98, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #1EBA62;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231EBA62' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #1EBA62;
  box-shadow: 0 0 0 0.25rem rgba(30, 186, 98, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #1EBA62;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #1EBA62;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(30, 186, 98, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1EBA62;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fd5353;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(253, 83, 83, 0.9);
  border-radius: 0.75rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fd5353;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fd5353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5353' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fd5353;
  box-shadow: 0 0 0 0.25rem rgba(253, 83, 83, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #fd5353;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fd5353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5353' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #fd5353;
  box-shadow: 0 0 0 0.25rem rgba(253, 83, 83, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #fd5353;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #fd5353;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 83, 83, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fd5353;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #333333;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.75rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #002381;
  --bs-btn-border-color: #002381;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001e6e;
  --bs-btn-hover-border-color: #001c67;
  --bs-btn-focus-shadow-rgb: 38, 68, 148;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001c67;
  --bs-btn-active-border-color: #001a61;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #002381;
  --bs-btn-disabled-border-color: #002381;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5C3799;
  --bs-btn-border-color: #5C3799;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4e2f82;
  --bs-btn-hover-border-color: #4a2c7a;
  --bs-btn-focus-shadow-rgb: 116, 85, 168;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4a2c7a;
  --bs-btn-active-border-color: #452973;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5C3799;
  --bs-btn-disabled-border-color: #5C3799;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #1EBA62;
  --bs-btn-border-color: #1EBA62;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #40c47a;
  --bs-btn-hover-border-color: #35c172;
  --bs-btn-focus-shadow-rgb: 26, 158, 83;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #4bc881;
  --bs-btn-active-border-color: #35c172;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #1EBA62;
  --bs-btn-disabled-border-color: #1EBA62;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #002381;
  --bs-btn-border-color: #002381;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001e6e;
  --bs-btn-hover-border-color: #001c67;
  --bs-btn-focus-shadow-rgb: 38, 68, 148;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001c67;
  --bs-btn-active-border-color: #001a61;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #002381;
  --bs-btn-disabled-border-color: #002381;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFAB2D;
  --bs-btn-border-color: #FFAB2D;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffb84d;
  --bs-btn-hover-border-color: #ffb342;
  --bs-btn-focus-shadow-rgb: 217, 145, 38;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffbc57;
  --bs-btn-active-border-color: #ffb342;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFAB2D;
  --bs-btn-disabled-border-color: #FFAB2D;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #fd5353;
  --bs-btn-border-color: #fd5353;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fd6d6d;
  --bs-btn-hover-border-color: #fd6464;
  --bs-btn-focus-shadow-rgb: 215, 71, 71;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd7575;
  --bs-btn-active-border-color: #fd6464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fd5353;
  --bs-btn-disabled-border-color: #fd5353;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #dedeed;
  --bs-btn-border-color: #dedeed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bdbdc9;
  --bs-btn-hover-border-color: #b2b2be;
  --bs-btn-focus-shadow-rgb: 189, 189, 201;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b2b2be;
  --bs-btn-active-border-color: #a7a7b2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #dedeed;
  --bs-btn-disabled-border-color: #dedeed;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #525252;
  --bs-btn-hover-border-color: #474747;
  --bs-btn-focus-shadow-rgb: 82, 82, 82;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c5c5c;
  --bs-btn-active-border-color: #474747;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #333333;
  --bs-btn-disabled-border-color: #333333;
}

.btn-outline-primary {
  --bs-btn-color: #002381;
  --bs-btn-border-color: #002381;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #002381;
  --bs-btn-hover-border-color: #002381;
  --bs-btn-focus-shadow-rgb: 0, 35, 129;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #002381;
  --bs-btn-active-border-color: #002381;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #002381;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #002381;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #5C3799;
  --bs-btn-border-color: #5C3799;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5C3799;
  --bs-btn-hover-border-color: #5C3799;
  --bs-btn-focus-shadow-rgb: 92, 55, 153;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5C3799;
  --bs-btn-active-border-color: #5C3799;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5C3799;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5C3799;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1EBA62;
  --bs-btn-border-color: #1EBA62;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #1EBA62;
  --bs-btn-hover-border-color: #1EBA62;
  --bs-btn-focus-shadow-rgb: 30, 186, 98;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #1EBA62;
  --bs-btn-active-border-color: #1EBA62;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1EBA62;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1EBA62;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #002381;
  --bs-btn-border-color: #002381;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #002381;
  --bs-btn-hover-border-color: #002381;
  --bs-btn-focus-shadow-rgb: 0, 35, 129;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #002381;
  --bs-btn-active-border-color: #002381;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #002381;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #002381;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFAB2D;
  --bs-btn-border-color: #FFAB2D;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFAB2D;
  --bs-btn-hover-border-color: #FFAB2D;
  --bs-btn-focus-shadow-rgb: 255, 171, 45;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFAB2D;
  --bs-btn-active-border-color: #FFAB2D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFAB2D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFAB2D;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fd5353;
  --bs-btn-border-color: #fd5353;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fd5353;
  --bs-btn-hover-border-color: #fd5353;
  --bs-btn-focus-shadow-rgb: 253, 83, 83;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd5353;
  --bs-btn-active-border-color: #fd5353;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fd5353;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd5353;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #dedeed;
  --bs-btn-border-color: #dedeed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dedeed;
  --bs-btn-hover-border-color: #dedeed;
  --bs-btn-focus-shadow-rgb: 222, 222, 237;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dedeed;
  --bs-btn-active-border-color: #dedeed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dedeed;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dedeed;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #333333;
  --bs-btn-border-color: #333333;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #333333;
  --bs-btn-hover-border-color: #333333;
  --bs-btn-focus-shadow-rgb: 51, 51, 51;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333333;
  --bs-btn-active-border-color: #333333;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #333333;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #333333;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 68, 148;
  text-decoration: underline;
}
.btn-link:focus {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.765625rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #333333;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.75rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.75rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #002381;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #002381;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.75rem;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.75rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #F4F4F4;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #F4F4F4;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.75rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #002381;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3359375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.75rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.75rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #F4F4F4;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.75rem;
  --bs-accordion-inner-border-radius: calc(0.75rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002074'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8091c0;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #002074;
  --bs-accordion-active-bg: #e6e9f2;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.75rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #002381;
  --bs-pagination-active-border-color: #002381;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.75rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.75rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #00154d;
  --bs-alert-bg: #ccd3e6;
  --bs-alert-border-color: #b3bdd9;
}
.alert-primary .alert-link {
  color: #00113e;
}

.alert-secondary {
  --bs-alert-color: #37215c;
  --bs-alert-bg: #ded7eb;
  --bs-alert-border-color: #cec3e0;
}
.alert-secondary .alert-link {
  color: #2c1a4a;
}

.alert-success {
  --bs-alert-color: #12703b;
  --bs-alert-bg: #d2f1e0;
  --bs-alert-border-color: #bcead0;
}
.alert-success .alert-link {
  color: #0e5a2f;
}

.alert-info {
  --bs-alert-color: #00154d;
  --bs-alert-bg: #ccd3e6;
  --bs-alert-border-color: #b3bdd9;
}
.alert-info .alert-link {
  color: #00113e;
}

.alert-warning {
  --bs-alert-color: #664412;
  --bs-alert-bg: #ffeed5;
  --bs-alert-border-color: #ffe6c0;
}
.alert-warning .alert-link {
  color: #52360e;
}

.alert-danger {
  --bs-alert-color: #983232;
  --bs-alert-bg: #ffdddd;
  --bs-alert-border-color: #fecbcb;
}
.alert-danger .alert-link {
  color: #7a2828;
}

.alert-light {
  --bs-alert-color: #59595f;
  --bs-alert-bg: #f8f8fb;
  --bs-alert-border-color: #f5f5fa;
}
.alert-light .alert-link {
  color: #47474c;
}

.alert-dark {
  --bs-alert-color: #1f1f1f;
  --bs-alert-bg: #d6d6d6;
  --bs-alert-border-color: #c2c2c2;
}
.alert-dark .alert-link {
  color: #191919;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.75rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #002381;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.75rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #333333;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #002381;
  --bs-list-group-active-border-color: #002381;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00154d;
  background-color: #ccd3e6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00154d;
  background-color: #b8becf;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #00154d;
  border-color: #00154d;
}

.list-group-item-secondary {
  color: #37215c;
  background-color: #ded7eb;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #37215c;
  background-color: #c8c2d4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #37215c;
  border-color: #37215c;
}

.list-group-item-success {
  color: #12703b;
  background-color: #d2f1e0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #12703b;
  background-color: #bdd9ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #12703b;
  border-color: #12703b;
}

.list-group-item-info {
  color: #00154d;
  background-color: #ccd3e6;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00154d;
  background-color: #b8becf;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00154d;
  border-color: #00154d;
}

.list-group-item-warning {
  color: #664412;
  background-color: #ffeed5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664412;
  background-color: #e6d6c0;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664412;
  border-color: #664412;
}

.list-group-item-danger {
  color: #983232;
  background-color: #ffdddd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #983232;
  background-color: #e6c7c7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #983232;
  border-color: #983232;
}

.list-group-item-light {
  color: #59595f;
  background-color: #f8f8fb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #59595f;
  background-color: #dfdfe2;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #59595f;
  border-color: #59595f;
}

.list-group-item-dark {
  color: #1f1f1f;
  background-color: #d6d6d6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f1f1f;
  background-color: #c1c1c1;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.75rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.75rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  position: absolute;
  z-index: 1090;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.75rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.765625rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #333333;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1439.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1439.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1440px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 35, 129, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(92, 55, 153, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(30, 186, 98, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(0, 35, 129, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 171, 45, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(253, 83, 83, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(222, 222, 237, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(51, 51, 51, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #002381 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #001c67 !important;
}

.link-secondary {
  color: #5C3799 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #4a2c7a !important;
}

.link-success {
  color: #1EBA62 !important;
}
.link-success:hover, .link-success:focus {
  color: #4bc881 !important;
}

.link-info {
  color: #002381 !important;
}
.link-info:hover, .link-info:focus {
  color: #001c67 !important;
}

.link-warning {
  color: #FFAB2D !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffbc57 !important;
}

.link-danger {
  color: #fd5353 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #fd7575 !important;
}

.link-light {
  color: #dedeed !important;
}
.link-light:hover, .link-light:focus {
  color: #e5e5f1 !important;
}

.link-dark {
  color: #333333 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #292929 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.875rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.875rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.875rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.875rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.875rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.875rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.875rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.875rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.875rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.875rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.875rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #89879f !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.875rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.875rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.875rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.875rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.875rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.875rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.875rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.875rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.875rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.875rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.875rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.875rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.875rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.875rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.875rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.875rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.875rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.875rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.875rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.875rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.875rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.875rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.875rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }
  .fs-2 {
    font-size: 1.875rem !important;
  }
  .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Base */
.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

::selection {
  color: #fff;
  background: #002381;
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem;
}
@media only screen and (max-width: 991px) {
  body {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 1400px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}
p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

#main-wrapper {
  position: relative;
}

.rounded-lg {
  border-radius: 0.25rem;
}

.rounded-xl {
  border-radius: 12px !important;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #333333;
  text-decoration: none;
}
a:hover, a:focus, a.active {
  text-decoration: none;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.btn-link:hover, .btn-link:focus, .btn-link.active {
  text-decoration: none;
}

.nav-link {
  color: #7e7e7e;
}

[data-theme-version=dark] .border-end {
  border-color: #333a54 !important;
}

.w-space-no {
  white-space: nowrap;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-r-radius {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.img-fix {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-body .container {
  margin-top: 40px;
}
.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
}
@media only screen and (max-width: 1600px) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.b-radius {
  border-radius: 0.25rem;
}

/* sp */
.row.sp80,
.sp80 {
  margin-left: -40px;
  margin-right: -40px;
}
.row.sp80 [class*=col-],
.sp80 [class*=col-] {
  padding-left: 40px;
  padding-right: 40px;
}

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px;
}
.row.sp60 [class*=col-],
.sp60 [class*=col-] {
  padding-left: 30px;
  padding-right: 30px;
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}
.row.sp40 [class*=col-],
.sp40 [class*=col-] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.sp20 [class*=col-],
.sp20 [class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.sp16 [class*=col-],
.sp16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row.sp10 [class*=col-],
.sp10 [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp4,
.sp4 {
  margin-left: -2px;
  margin-right: -2px;
}
.row.sp4 [class*=col-],
.sp4 [class*=col-] {
  padding-left: 2px;
  padding-right: 2px;
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}
.row.spno [class*=col-],
.spno [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.content-heading {
  font-size: 16px;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 1px solid #f0f1f5;
  padding-bottom: 10px;
}
[direction=rtl] .content-heading {
  text-align: right;
}

.text-ov {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.wspace-no {
  white-space: nowrap;
}

.support-ticket {
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 999999;
}

.support-ticket-btn {
  width: 100px;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 50px;
  color: #fff;
  font-size: 8px;
  font-size: 16px;
  padding: 5px 10px 7px;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
}
.support-ticket-btn:hover, .support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #374C98;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #EB62D0;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

.bg-gray-dark {
  background: #143b64 !important;
}

.bg-purpal {
  background: #4527a0 !important;
}

.bg-red {
  background: #c62828 !important;
}

.bg-blue-dark {
  background: #283593 !important;
}

.bg-blue {
  background: #7356f1 !important;
}

.bg-blue-light {
  background: #3695eb !important;
}

.bg-green-light {
  background: #00838f !important;
}

.bg-green {
  background: #ff8f16 !important;
}

.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}
[data-theme-version=dark] .text-black {
  color: #fff !important;
}

.dlab-scroll {
  position: relative;
}

.h-auto {
  height: auto !important;
}

.mh-auto {
  min-height: auto !important;
}

.list-inline li {
  display: inline-block;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 1.3;
}

.fs-13 {
  font-size: 13px !important;
  line-height: 1.4;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-15 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 1.5;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 1.5;
}

.fs-20 {
  font-size: 20px !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 22px !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 26px !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 28px !important;
  line-height: 1.4;
}

.fs-30 {
  font-size: 30px !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 32px !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 34px !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 35px !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 36px !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 38px !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 46px !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 48px !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.height10 {
  height: 10px;
}

.height20 {
  height: 20px;
}

.height30 {
  height: 30px;
}

.height40 {
  height: 40px;
}

.height50 {
  height: 50px;
}

.height60 {
  height: 60px;
}

.height70 {
  height: 70px;
}

.height80 {
  height: 80px;
}

.height90 {
  height: 90px;
}

.height100 {
  height: 100px;
}

.height110 {
  height: 110px;
}

.height120 {
  height: 120px;
}

.height130 {
  height: 130px;
}

.height140 {
  height: 140px;
}

.height150 {
  height: 150px;
}

.height160 {
  height: 160px;
}

.height170 {
  height: 170px;
}

.height180 {
  height: 180px;
}

.height190 {
  height: 190px;
}

.height200 {
  height: 200px;
}

.height210 {
  height: 210px;
}

.height220 {
  height: 220px;
}

.height230 {
  height: 230px;
}

.height240 {
  height: 240px;
}

.height250 {
  height: 250px;
}

.height260 {
  height: 260px;
}

.height270 {
  height: 270px;
}

.height280 {
  height: 280px;
}

.height290 {
  height: 290px;
}

.height300 {
  height: 300px;
}

.height310 {
  height: 310px;
}

.height320 {
  height: 320px;
}

.height330 {
  height: 330px;
}

.height340 {
  height: 340px;
}

.height350 {
  height: 350px;
}

.height360 {
  height: 360px;
}

.height370 {
  height: 370px;
}

.height380 {
  height: 380px;
}

.height390 {
  height: 390px;
}

.height400 {
  height: 400px;
}

.height480 {
  height: 480px;
}

.height520 {
  height: 520px;
}

.height620 {
  height: 620px;
}

.height640 {
  height: 640px;
}

.height830 {
  height: 830px;
}

.width10 {
  width: 10px;
}

.width20 {
  width: 20px;
}

.width30 {
  width: 30px;
}

.width40 {
  width: 40px;
}

.width50 {
  width: 50px;
}

.width60 {
  width: 60px;
}

.width70 {
  width: 70px;
}

.width80 {
  width: 80px;
}

.width90 {
  width: 90px;
}

.width100 {
  width: 100px;
}

.width110 {
  width: 110px;
}

.width120 {
  width: 120px;
}

.width130 {
  width: 130px;
}

.width140 {
  width: 140px;
}

.width150 {
  width: 150px;
}

.width160 {
  width: 160px;
}

.width170 {
  width: 170px;
}

.width180 {
  width: 180px;
}

.width190 {
  width: 190px;
}

.width200 {
  width: 200px;
}

.width210 {
  width: 210px;
}

.width220 {
  width: 220px;
}

.width230 {
  width: 230px;
}

.width240 {
  width: 240px;
}

.width250 {
  width: 250px;
}

.width260 {
  width: 260px;
}

.width270 {
  width: 270px;
}

.width280 {
  width: 280px;
}

.width290 {
  width: 290px;
}

.width300 {
  width: 300px;
}

.width310 {
  width: 310px;
}

.width320 {
  width: 320px;
}

.width330 {
  width: 330px;
}

.width340 {
  width: 340px;
}

.width350 {
  width: 350px;
}

.width360 {
  width: 360px;
}

.width370 {
  width: 370px;
}

.width380 {
  width: 380px;
}

.width390 {
  width: 390px;
}

.width400 {
  width: 400px;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}
@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}
@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}
@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}
.bg-primary {
  background-color: var(--primary) !important;
}

.border-2 {
  border-width: 2px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}
/* Layout */
.footer {
  padding-left: 21.563rem;
  background-color: #F4F4F4;
}
@media only screen and (max-width: 1400px) {
  .footer {
    padding-left: 17rem;
  }
}
.footer .copyright {
  padding: 0.9375rem;
}
.footer .copyright p {
  text-align: center;
  margin: 0;
}
.footer .copyright a {
  color: var(--primary);
}
@media only screen and (min-width: 991px) {
  .footer .copyright {
    border-radius: 0 0 0 50px;
  }
}

[data-sidebar-style=mini] .nav-control,
[data-layout=horizontal] .nav-control {
  display: none;
}

@media only screen and (max-width: 767px) {
  [data-sidebar-style=overlay] .nav-header .logo-abbr {
    display: block;
  }
}

[data-header-position=fixed] .nav-header {
  position: fixed;
  left: auto;
}

.nav-header {
  height: 5rem;
  width: var(--width);
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: #fff;
  transition: all 0.2s ease;
  z-index: 10;
}
.nav-header .logo-abbr {
  max-width: 52px;
  min-width: 52px;
}
@media only screen and (max-width: 575px) {
  .nav-header .logo-abbr {
    max-width: 40px;
  }
}
.nav-header .logo-compact {
  display: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .nav-header {
    width: 17rem;
  }
}
.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  border-bottom: 1px solid #EEEEEE;
  color: #fff;
  text-decoration: none;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 700;
}
@media only screen and (max-width: 1400px) {
  .nav-header .brand-logo {
    padding-left: 25px;
    padding-right: 25px;
  }
}
[data-sidebar-style=compact] .nav-header .brand-logo, [data-sidebar-style=mini] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}
.nav-header .brand-title {
  margin-left: 10px;
  max-width: 120px;
  margin-top: 0px;
}
[data-theme-version=dark] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
    height: 5rem;
  }
  .nav-header .brand-title {
    display: none;
  }
}
.nav-control {
  cursor: pointer;
  position: absolute;
  right: -4.0625rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 2px 0.5rem 0;
  border-radius: 2px;
}
@media only screen and (max-width: 767px) {
  .nav-control {
    right: -2.75rem;
  }
}
@media only screen and (max-width: 575px) {
  .nav-control {
    right: -2.75rem;
  }
}
.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 3px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 26px;
  z-index: 999;
}

.hamburger .line {
  background: #002381;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 20px;
}

.hamburger .line:nth-child(2) {
  width: 26px;
}

.hamburger .line:nth-child(3) {
  width: 22px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 26px;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 10px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
  transform: translateX(0px);
  width: 22px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
  transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-4px) rotate(-45deg);
}

@media (min-width: 767px) {
  [data-sidebar-style=compact] .nav-control {
    display: none;
  }
  [data-sidebar-style=compact] .nav-header {
    width: 11.25rem;
  }
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .brand-title {
  display: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style=full][data-layout=horizontal] .logo-compact {
  display: none;
}

[data-sidebar-style=mini] .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style=compact][data-layout=vertical] .nav-header .brand-title {
  display: none;
}
[data-sidebar-style=compact][data-layout=vertical] .nav-header .logo-compact {
  max-width: 75px;
}

[data-sidebar-style=compact][data-layout=horizontal] .nav-header .brand-logo {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: start;
}

[data-sidebar-style=modern][data-layout=vertical] .nav-header {
  width: 9.375rem;
}
[data-sidebar-style=modern][data-layout=vertical] .nav-header .brand-title {
  display: none;
}
[data-sidebar-style=modern][data-layout=vertical] .nav-header .logo-compact {
  display: none;
}

.header {
  height: var(--height);
  border-bottom: 1px solid #EEEEEE;
  position: relative;
  padding: 0rem;
  background-color: #fff;
  z-index: 9;
  padding-left: var(--width);
  transition: all 0.2s ease;
}
@media only screen and (max-width: 1400px) {
  .header {
    padding-left: 17rem;
  }
}
.header .header-content {
  height: 100%;
  padding-left: 5.3125rem;
  padding-right: 2.4rem;
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
    border-radius: 0;
  }
}
.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}
.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .header {
    height: 5rem;
  }
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}
svg.pulse-svg .first-circle, svg.pulse-svg .third-circle, svg.pulse-svg .second-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: #002381;
}
svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1rem;
  position: absolute;
  background: #FF507A;
  right: 5px;
  top: 5px;
  width: 1rem;
}
.pulse-css:after, .pulse-css:before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -0.2rem;
  background-color: #FF507A;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}
[direction=rtl] .pulse-css:after, [direction=rtl] .pulse-css:before {
  left: auto;
  right: -0.2rem;
}

@media only screen and (max-width: 1400px) {
  .pulse-css {
    height: 0.5rem;
    width: 0.5rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}
[data-sidebar-style=full] .header,
[data-sidebar-style=overlay] .header {
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  [data-sidebar-style=full] .header,
[data-sidebar-style=overlay] .header {
    width: 100%;
    padding-left: 17rem;
  }
}
@media only screen and (max-width: 1199px) {
  [data-sidebar-style=full] .header,
[data-sidebar-style=overlay] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style=mini] .header {
  width: 100%;
  padding-left: 5rem;
}

[data-sidebar-style=compact] .header {
  width: 100%;
  padding-left: 15rem;
}
[data-sidebar-style=compact] .header .header-content {
  padding-left: 2.4rem;
}

[data-header-position=fixed] .header {
  position: fixed;
  top: 0;
  width: 100%;
}
[data-header-position=fixed] .content-body {
  padding-top: var(--height);
}
@media only screen and (max-width: 1199px) {
  [data-header-position=fixed] .content-body {
    padding-top: 5rem;
  }
}
[data-header-position=fixed] .dlabnav {
  margin-top: 0;
}

[data-sidebar-style=compact][data-header-position=fixed][data-container=boxed][data-layout=vertical] .header {
  width: 1199px;
}

[data-sidebar-style=modern] .header {
  width: 100%;
  padding-left: 9.375rem;
}
[data-sidebar-style=modern][data-layout=horizontal] .nav-header .brand-logo {
  justify-content: start;
}
[data-sidebar-style=modern][data-layout=horizontal] .header .header-content {
  padding-left: 30px;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-left .dashboard_bar {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}
@media only screen and (max-width: 1400px) {
  .header-left .dashboard_bar {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .header-left .dashboard_bar {
    display: none;
  }
}
.header-left input {
  background: #F4F4F4 !important;
  min-width: 170px;
  min-height: 40px;
  color: #333333 !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding: 10px 20px;
  font-size: 16px;
  border-right-width: 0 !important;
  border: 1px solid #ebebeb;
  border-width: 1px;
  font-weight: 300;
}
[direction=rtl] .header-left input {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.header-left input:focus, .header-left input:active, .header-left input.active {
  border-color: #ebebeb;
  box-shadow: none;
}
.header-left input::-webkit-input-placeholder {
  /* Edge */
  color: #333333;
}
.header-left input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #333333;
}
.header-left input::placeholder {
  color: #333333;
}
.header-left .search_bar {
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    display: none;
  }
}
.header-left .search_bar .dropdown-menu {
  box-shadow: none;
}
.header-left .search_bar .search_icon {
  background: #F4F4F4 !important;
  height: 56px;
  line-height: 22px;
  padding: 15px 20px 15px 0 !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border: 1px solid #ebebeb;
  border-width: 1px;
  border-left-width: 0;
}
.header-left .search_bar .search_icon i {
  font-size: 24px;
  color: #333333;
  line-height: 1;
}
@media only screen and (max-width: 1400px) {
  .header-left .search_bar .search_icon {
    height: 41px;
    padding: 8px 15px 8px 0 !important;
  }
  .header-left .search_bar .search_icon svg {
    width: 18px;
    height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    position: static;
  }
  .header-left .search_bar .dropdown-menu {
    width: 300px;
    left: -15vw;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
  }
}
@media only screen and (max-width: 767px) and (max-width: 575px) {
  .header-left .search_bar .dropdown-menu {
    width: 250px;
    left: -25vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-left .search_bar .dropdown-menu .form-control {
    border-radius: 4px !important;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  [direction=rtl] .header-left .search_bar .dropdown-menu {
    right: -98px;
  }
}
.header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
  border: 0px;
  background-color: transparent;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}
@media only screen and (min-width: 768px) {
  .header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
    left: 0;
    top: 0;
    transform: none;
    display: block;
    position: unset;
  }
}

[data-sidebar-style=compact] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}
.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 18px;
}
.header-right .right-sidebar {
  margin-right: -30px;
}
.header-right .right-sidebar a {
  height: 80px;
  width: 80px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 1px solid #dedeed;
}
.header-right > li:not(:first-child) {
  padding-left: 1.5rem;
}
@media only screen and (max-width: 1199px) {
  .header-right > li:not(:first-child) {
    padding-left: 0.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .header-right .notification_dropdown {
    position: static;
  }
}
.header-right .notification_dropdown .nav-link {
  position: relative;
  color: #002381;
  background: #fff;
  border-radius: 100%;
  padding: 15px;
  line-height: 1;
}
@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link {
    padding: 10px;
  }
}
.header-right .notification_dropdown .nav-link.secondary {
  background: #d4c6ea;
  color: #5C3799;
}
.header-right .notification_dropdown .nav-link.secondary [fill] {
  fill: #5C3799;
}
.header-right .notification_dropdown .nav-link.primary {
  background: #4e7eff;
  color: #002381;
}
.header-right .notification_dropdown .nav-link.primary [fill] {
  fill: #002381;
}
.header-right .notification_dropdown .nav-link.warning {
  background: #fff9ef;
  color: #FFAB2D;
}
.header-right .notification_dropdown .nav-link.warning [fill] {
  fill: #FFAB2D;
}
.header-right .notification_dropdown .nav-link.success {
  background: #d0f7e1;
  color: #1EBA62;
}
.header-right .notification_dropdown .nav-link.success [fill] {
  fill: #1EBA62;
}
.header-right .notification_dropdown .nav-link i {
  font-size: 24px;
}
@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link svg {
    width: 24px;
    height: 24px;
  }
}
.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 14px;
  border-radius: 0;
  right: 5px;
  box-shadow: 0px 0px 10px 3px rgba(67, 220, 128, 0.1);
  top: 5px;
  font-weight: normal;
  height: 26px;
  width: 26px;
  line-height: 24px;
  text-align: center;
  padding: 0px;
}
@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link .badge {
    right: -2px;
    top: -2px;
    font-size: 12px;
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
}
.header-right .notification_dropdown .dropdown-item:focus a, .header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}
.header-right .notification_dropdown .dropdown-item a {
  color: #333333;
}
.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}
.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}
[data-theme-version=dark] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .header-profile > a.nav-link {
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  border-left: 1px solid #EEEEEE;
  padding-left: 30px;
}
@media only screen and (max-width: 575px) {
  .header-right .header-profile > a.nav-link {
    background: transparent;
    padding: 0;
    border-color: transparent;
  }
}
.header-right .header-profile > a.nav-link i {
  font-weight: 700;
}
.header-right .header-profile > a.nav-link .header-info {
  text-align: left;
  padding-left: 15px;
}
@media only screen and (max-width: 1199px) {
  .header-right .header-profile > a.nav-link .header-info {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .header-right .header-profile > a.nav-link .header-info {
    display: none;
  }
}
.header-right .header-profile > a.nav-link .header-info span {
  font-size: 16px;
  display: block;
  font-weight: 600;
}
.header-right .header-profile > a.nav-link .header-info strong {
  font-weight: 600;
}
.header-right .header-profile > a.nav-link .header-info small, .header-right .header-profile > a.nav-link .header-info .small {
  display: block;
  font-size: 13px;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}
.header-right .header-profile .dropdown-menu {
  padding: 15px 0;
  min-width: 12.5rem;
  left: auto;
  right: 0;
}
.header-right .header-profile .dropdown-menu a:hover, .header-right .header-profile .dropdown-menu a:focus, .header-right .header-profile .dropdown-menu a.active {
  color: #002381;
}
.header-right .header-profile img {
  width: 57px;
  height: 57px;
  border-radius: 100%;
}
@media only screen and (max-width: 1400px) {
  .header-right .header-profile img {
    width: 39px;
    height: 39px;
  }
}
.header-right .header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}
@media only screen and (max-width: 575px) {
  .header-right .header-profile .dropdown-toggle span {
    display: none;
  }
}
.header-right .header-profile .profile_title {
  background: #002381;
  color: #fff;
  padding: 10px 20px;
}
.header-right .header-profile .profile_title h5, .header-right .header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 3px;
}
.header-right .header-profile .dropdown-item {
  padding: 8px 24px;
}

.notification_dropdown .dropdown-menu-right {
  min-width: 310px;
  padding: 0rem 0 1rem;
  top: 100%;
  left: unset;
  right: 0;
}
.notification_dropdown .dropdown-menu-right .notification_title {
  background: #002381;
  color: #fff;
  padding: 10px 20px;
}
.notification_dropdown .dropdown-menu-right .notification_title h5, .notification_dropdown .dropdown-menu-right .notification_title .h5 {
  color: #fff;
  margin-bottom: 3px;
}
.notification_dropdown .dropdown-menu-right .media {
  width: 45px !important;
  height: 45px !important;
  font-size: 18px !important;
}
[data-theme-version=dark] .notification_dropdown .dropdown-menu-right .media {
  border-color: #333a54;
}

.notification_dropdown .dropdown-menu-right .media > span {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: inline-block;
  padding: 7px 9px;
  margin-right: 10px;
}
[direction=rtl].notification_dropdown .dropdown-menu-right .media > span {
  margin-right: 0;
  margin-left: 10px;
}

.notification_dropdown .dropdown-menu-right .media > span.success {
  background: #d0f7e1;
  color: #1EBA62;
}
.notification_dropdown .dropdown-menu-right .media > span.success [fill] {
  fill: #1EBA62;
}
.notification_dropdown .dropdown-menu-right .media > span.primary {
  background: #4e7eff;
  color: #002381;
}
.notification_dropdown .dropdown-menu-right .media > span.primary svg [fill] {
  fill: #002381;
}
.notification_dropdown .dropdown-menu-right .media > span.danger {
  background: #ffeaea;
  color: #fd5353;
}
.notification_dropdown .dropdown-menu-right .media > span.danger [fill] {
  fill: #fd5353;
}
.notification_dropdown .dropdown-menu-right .media > span [fill] {
  fill: #002381;
}
.notification_dropdown .dropdown-menu-right .media .notify-time {
  width: 100% !important;
  margin-right: 0 !important;
  color: #828690;
}
.notification_dropdown .dropdown-menu-right .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  margin-bottom: 0;
  margin-top: 5px;
}
@media only screen and (max-width: 575px) {
  .notification_dropdown .dropdown-menu-right .media p {
    max-width: 100px;
  }
}
.notification_dropdown .dropdown-menu-right .all-notification {
  display: block;
  padding: 15px 30px 0;
  text-align: center;
  border-top: 1px solid #dedeed;
}
.notification_dropdown .dropdown-menu-right .all-notification i {
  margin-left: 10px;
}

.nav-label {
  margin: 10px 30px 0;
  padding: 1.5625rem 0 10px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 1px solid #eaeaea;
  color: #999999;
}
[data-theme-version=dark] .nav-label {
  border-color: #333a54;
}

.nav-label.first {
  border: 0px;
  margin-top: 0px;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: var(--width);
  z-index: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .content-body {
    margin-left: 17rem;
  }
}

.bell img {
  -webkit-animation: ring 8s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s 0.7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }
  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}
@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }
  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
.dlabnav {
  width: var(--width);
  padding-bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: var(--height);
  z-index: 3;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
  transition: all 0.2s ease;
}
@media only screen and (max-width: 1199px) {
  .dlabnav {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .dlabnav {
    width: 18rem;
  }
}
.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .dlabnav {
    width: 17rem;
  }
}
.dlabnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
@media only screen and (max-width: 767px) {
  .dlabnav .metismenu {
    padding-top: 0;
  }
}
.dlabnav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}
.dlabnav .metismenu > li {
  display: flex;
  flex-direction: column;
}
.dlabnav .metismenu > li a > i {
  font-size: 1.25rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  line-height: 1;
  margin-right: 15px;
  text-align: center;
  padding: 0px;
}
[data-sidebar-style=compact] .dlabnav .metismenu > li a > i {
  display: block;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .dlabnav .metismenu > li a > i {
    font-size: 1.25rem;
    padding: 0 0.75rem 0 0;
    margin-right: 0px;
  }
}
.dlabnav .metismenu > li > a {
  font-weight: 500;
  display: inline-block;
  font-size: 15px;
}
.dlabnav .metismenu > li > a svg {
  max-width: 24px;
  max-height: 24px;
  height: 100%;
  margin-right: 5px;
  margin-top: -3px;
  color: var(--primary);
}
.dlabnav .metismenu > li > a g [fill] {
  fill: var(--primary);
}
.dlabnav .metismenu > li:hover > a, .dlabnav .metismenu > li:focus > a {
  color: var(--primary);
}
.dlabnav .metismenu > li:hover > a g [fill], .dlabnav .metismenu > li:focus > a g [fill] {
  fill: var(--primary);
}
.dlabnav .metismenu > li.mm-active > a {
  color: var(--primary);
}
.dlabnav .metismenu > li.mm-active > a g [fill] {
  fill: var(--primary);
}
.dlabnav .metismenu li {
  position: relative;
}
.dlabnav .metismenu ul {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 0 0;
}
.dlabnav .metismenu ul a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 16px;
  padding-left: 4.67rem;
}
@media only screen and (max-width: 1400px) {
  .dlabnav .metismenu ul a {
    padding-left: 4rem;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .dlabnav .metismenu ul a {
    padding-left: 3.5rem;
  }
}
.dlabnav .metismenu ul a:hover, .dlabnav .metismenu ul a:focus, .dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}
.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #333333;
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}
.dlabnav .metismenu .has-arrow:after {
  width: 0.5rem;
  height: 0.5rem;
  right: 1.875rem;
  top: 48%;
  border-color: inherit;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}
.dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
.dlabnav .metismenu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 767px) {
  .brand-title {
    display: none;
  }
  .footer {
    padding-left: 0;
  }
  .dlabnav {
    left: 0;
    top: 5rem;
    padding-top: 1rem;
  }
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu > ul.collapse:not(.in),
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu > ul.collapse:not(.in) {
  height: 252px !important;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu:hover > a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu:hover > a {
  width: calc(70vw + 3.75rem);
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu:hover > ul,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu:hover > ul ul a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu:hover > ul ul a {
  width: 101%;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-xl:hover > a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(70vw + 3rem);
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-xl:hover > ul,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 200px;
  width: 70vw;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-xl:hover > ul ul a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-xl:hover > ul ul a {
  width: 101%;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-lg:hover > a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(55vw + 3rem);
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-lg:hover > ul,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 200px;
  width: 55vw;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-lg:hover > ul ul a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-lg:hover > ul ul a {
  width: 101%;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-md:hover > a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-md:hover > a {
  width: calc(45vw + 3rem);
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-md:hover > ul,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-md:hover > ul ul a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-md:hover > ul ul a {
  width: 101%;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-sm:hover > a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(30vw + 3rem);
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-sm:hover > ul,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 30vw;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mega-menu-sm:hover > ul ul a,
[data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mega-menu-sm:hover > ul ul a {
  width: 101%;
}

[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu:hover > a {
  width: calc(60vw + 3.75rem);
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu:hover > ul ul a {
  width: 101%;
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(60vw + 3.75rem);
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 25.625rem;
  width: 60vw;
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(50vw + 3.75rem);
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 16.25rem;
  width: 50vw;
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-md:hover > a {
  width: calc(40vw + 3.75rem);
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 40vw;
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(22vw + 3.75rem);
}
[data-sidebar-style=mini][data-layout=vertical][data-container=boxed] .dlabnav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:not(:last-child) {
  position: static;
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul {
  left: 0;
  right: 0;
}
[data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #333a54;
}

[data-sibebarbg=color_2][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #03090f;
}

[data-sibebarbg=color_3][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #00071b;
}

[data-sibebarbg=color_4][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #22134e;
}

[data-sibebarbg=color_5][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #711717;
}

[data-sibebarbg=color_6][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #121843;
}

[data-sibebarbg=color_7][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #3511d0;
}

[data-sibebarbg=color_8][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #1161aa;
}

[data-sibebarbg=color_9][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #002629;
}

[data-sibebarbg=color_10][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li {
  border-color: #af5b00;
}

[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li a {
  /* transition: all .4s ease-in-out;*/
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}
[data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #1d2544;
}

[data-sibebarbg=color_2][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #0c223a;
}

[data-sibebarbg=color_3][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #00154e;
}

[data-sibebarbg=color_4][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #331d77;
}

[data-sibebarbg=color_5][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #9c1f1f;
}

[data-sibebarbg=color_6][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #1d276b;
}

[data-sibebarbg=color_7][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #4c27ed;
}

[data-sibebarbg=color_8][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #167cd8;
}

[data-sibebarbg=color_9][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #00545c;
}

[data-sibebarbg=color_10][data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul::after {
  background-color: #e27500;
}

[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu:hover > ul ul a {
  width: 101%;
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 210px;
  width: 70vw;
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 210px;
  width: 700px;
  height: 210px !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-lg:hover > ul {
    width: 700px;
  }
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 20rem;
  width: 54vw;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-md:hover > ul {
    width: 60vw;
  }
}
[data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 20rem;
  width: 25vw;
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout=horizontal] .dlabnav .metismenu > li.mega-menu-sm:hover > ul {
    width: 35vw;
  }
}

[data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
[data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 21.875rem;
  width: 100%;
}
[data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 21.875rem;
  width: 55vw;
}
[data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw;
}
[data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 50vw;
}

[data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li {
  padding: 0 0 0 0;
}
[data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li > a {
  font-size: 18px;
  padding: 20px 20px 20px 35px;
  position: relative;
}
@media only screen and (max-width: 1400px) {
  [data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li > a {
    padding: 15px 25px;
    font-size: 16px;
  }
}
[data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li > a:before {
  position: absolute;
  height: 100%;
  width: 7px;
  background: var(--primary);
  content: "";
  left: -7px;
  top: 0;
  border-radius: 0.25rem;
}
[data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li.mm-active {
  /* & > a{
  	color:	var(--primary);
  	i{
  		color: var(--primary);
  	}
  	&:before{
  		left:0;
  	}
  } */
}
[data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li .has-arrow:after {
  right: 2.5rem;
}
@media only screen and (max-width: 1400px) {
  [data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li .has-arrow:after {
    right: 1.5rem;
  }
}
@media only screen and (max-width: 1400px) {
  [data-sidebar-style=full][data-layout=vertical] .dlabnav .metismenu > li {
    padding: 0;
  }
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: var(--primary) !important;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .copyright,
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .add-menu-sidebar {
  display: none !important;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .header {
  padding-left: 6rem;
  width: 100%;
}
[direction=rtl][data-sidebar-style=full][data-layout=vertical] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 5rem;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav {
  width: 5rem;
  overflow: visible;
  position: absolute;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .nav-text {
  display: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .slimScrollDiv,
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .dlabnav-scroll {
  overflow: visible !important;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li {
  position: relative;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li a {
  padding: 13px;
  margin: 5px 0;
  border-radius: 100%;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li a svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 0;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li a:before {
  content: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul {
  position: absolute;
  left: 5rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 1px;
  height: auto !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
  border-radius: 6px;
  margin-left: 0;
  border: 0;
  background: #fff;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul:after {
  content: "";
  position: absolute;
  background: inherit;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  left: -5px;
  top: 20px;
}
[direction=rtl][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul {
  left: auto;
  right: 3rem;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul li:hover ul:after {
  content: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li:hover > ul {
  display: block;
  height: auto;
  overflow: visible;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li {
  transition: all 0.4s ease-in-out;
  padding: 0 13px;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li > a {
  text-align: center;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li > a.has-arrow:after {
  display: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li > a i {
  padding: 0;
  width: auto;
  height: auto;
  margin: 0;
  line-height: 1;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a {
  background: var(--primary);
  border-radius: 100%;
  color: #fff;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a i {
  color: #fff;
  padding: 0;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover:nth-last-child(-n+1) > ul {
  bottom: 0;
  top: auto;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  border-radius: 100%;
  background: var(--primary);
  color: #fff;
}
[data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background: #1e2746;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a i {
  color: #fff;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > ul {
  height: auto !important;
  padding: 10px 0;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > ul ul {
  padding: 10px 0;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > ul ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu .nav-label,
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu .nav-badge {
  display: none;
}
[data-sidebar-style=full][data-layout=vertical] .menu-toggle .content-body {
  margin-left: 5rem;
}
[direction=rtl][data-sidebar-style=full][data-layout=vertical] .menu-toggle .content-body {
  margin-right: 5rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle + .footer {
  padding-left: 5rem;
}
[direction=rtl][data-sidebar-style=full][data-layout=vertical] .menu-toggle + .footer {
  padding-left: 0;
  padding-right: 5rem;
}

[data-sidebar-style=full][data-layout=horizontal] .dlabnav .metismenu {
  padding: 0 30px;
  display: inline-flex;
  flex-wrap: wrap;
}
[data-sidebar-style=full][data-layout=horizontal] .header .header-content {
  padding-left: 1.875rem;
}

@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini] .nav-header {
    width: 5rem;
  }
  [data-sidebar-style=mini] .nav-header .nav-control {
    z-index: -1;
  }
  [data-sidebar-style=mini] .nav-header .nav-control .hamburger {
    left: 5rem !important;
  }
  [data-sidebar-style=mini] .nav-header .nav-control .hamburger .line {
    background-color: #333333 !important;
  }
  [data-sidebar-style=mini] .nav-header .brand-title {
    display: none;
  }
  [data-sidebar-style=mini] .nav-header .hamburger {
    display: none;
  }
  [data-sidebar-style=mini] .header .header-content {
    padding-left: 1.875rem;
  }
  [direction=rtl][data-sidebar-style=mini] .header .header-content {
    padding-right: 1.875rem;
  }
  [data-sidebar-style=mini] .dlabnav {
    width: 5rem;
    overflow: visible;
    position: absolute;
  }
  [data-sidebar-style=mini] .dlabnav .copyright,
[data-sidebar-style=mini] .dlabnav .add-menu-sidebar {
    display: none !important;
  }
  [data-sidebar-style=mini] .dlabnav .nav-text {
    display: none;
  }
  [data-sidebar-style=mini] .dlabnav .slimScrollDiv,
[data-sidebar-style=mini] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }
  [data-sidebar-style=mini] .dlabnav .nav-user {
    padding: 11px;
  }
  [data-sidebar-style=mini] .dlabnav .nav-user .media-body {
    display: none;
  }
  [data-sidebar-style=mini] .dlabnav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }
  [data-sidebar-style=mini] .dlabnav .metismenu li a svg {
    margin-right: 0;
  }
  [data-sidebar-style=mini] .dlabnav .metismenu li > ul {
    position: absolute;
    left: 5rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 1px;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 6px;
    background: #fff;
  }
  [direction=rtl]:not([data-layout=horizontal])[data-sidebar-style=mini] .dlabnav .metismenu li > ul {
    left: auto;
    right: 5rem;
    box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
  }
  [data-sidebar-style=mini] .dlabnav .metismenu > li {
    padding: 2px 15px;
  }
  [data-sidebar-style=mini] .dlabnav .metismenu > li > a.has-arrow:after {
    display: none;
  }
  [data-sidebar-style=mini] .dlabnav .metismenu .nav-label,
[data-sidebar-style=mini] .dlabnav .metismenu .nav-badge {
    display: none;
  }
  [data-sidebar-style=mini] .content-body {
    margin-left: 5rem;
  }
  [data-sidebar-style=mini] .footer {
    padding-left: 5rem;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li:hover > ul {
    display: block;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:nth-last-child(-n+1) > ul {
    bottom: 0;
    top: auto !important;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:nth-last-child(-n+1) > ul:after {
    top: auto;
    bottom: 20px;
  }
  /* [data-sidebar-style="mini"][data-header-position="fixed"][data-layout="horizontal"]{
  	.content-body {
  	padding-top: 10.3rem;
  	}
  }  */
  /* [data-sidebar-style="mini"][data-container="wide-boxed"][data-layout="horizontal"] {	 
  	.content-body{
  		padding-top:10.1rem;
  	}
  } */
  /* [data-sidebar-style="mini"][data-container="boxed"][data-layout="horizontal"] {
  	.content-body{
  		padding-top:10rem;
  	}
  } */
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:nth-last-child(-n+1) > ul {
    bottom: 0;
    top: auto !important;
  }
}
@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li > ul {
    overflow: visible;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li > ul:after {
    content: none;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li > ul li:hover ul {
    padding: 10px 0;
    width: 13rem;
    left: 13rem;
    top: -10px;
    border: 0;
    margin: 0;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li > ul li:hover ul:after {
    content: none;
  }
  [direction=rtl][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li > ul li:hover ul {
    left: auto;
    right: 13rem;
  }
}
@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a {
    background: #002381;
    color: #fff;
    border-radius: 4rem;
  }
  [data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a {
    background: #181f39;
  }
}
@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
    background: #002381;
    color: #fff;
    border-radius: 4rem;
    position: unset;
  }
  [data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
    background: #1e2746;
  }
  [direction=rtl][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 5rem;
    width: 13rem;
    border-radius: 12px;
    border: 0;
    padding: 10px 0;
    top: 0;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul:after {
    content: "";
    position: absolute;
    background: inherit;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    left: -5px;
    top: 20px;
  }
  [data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul {
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
  }
  [direction=rtl][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul {
    left: auto;
    right: 5rem;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul a {
    padding: 6px 20px 6px 20px;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul a:before {
    content: none;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul a.has-arrow:after {
    right: 1.25rem;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul ul a {
    padding: 6px 20px 6px 20px;
    margin-left: -1.6px;
  }
  [data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > ul ul a:before {
    content: none;
  }
}
@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini][data-header-position=fixed][data-container=boxed][data-layout=vertical] .header {
    width: 1199px;
    padding-left: 5rem;
  }
}
@media only screen and (min-width: 768px) {
  [data-sidebar-style=mini][data-layout=horizontal] {
    /* .content-body {
    	padding-top: 10.3rem;
    } */
    /*  .dlabnav {
    	 position: fixed; 
    	padding: 0.625rem 0;
    } */
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a {
    padding: 18px 20px;
    border-radius: 50%;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a:hover {
    background-color: var(--rgba-primary-1);
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a i {
    margin-right: 0;
    padding-right: 0;
  }
  [direction=rtl][data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a {
    padding: 15px 20px;
  }
  [direction=rtl][data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a svg {
    margin-left: 0;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li > a svg {
    margin-right: 0;
    margin-top: 0;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .dlabnav .metismenu > li.mm-active > a {
    background: var(--rgba-primary-1);
    border-radius: 50%;
  }
}
@media only screen and (max-width: 1199px) {
  [data-sidebar-style=mini] .dlabnav li.mm-active ul {
    height: auto !important;
  }
}
@media (min-width: 1199px) {
  [data-layout=horizontal] .nav-header {
    width: 16rem;
  }
  [data-layout=horizontal] .nav-header .nav-control {
    display: none;
  }
  [data-layout=horizontal] .header {
    width: 100%;
    padding-left: 16rem;
  }
  [data-layout=horizontal] .dlabnav {
    width: 100%;
    position: relative;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }
  [data-layout=horizontal] .dlabnav .copyright {
    display: none;
  }
  [data-layout=horizontal] .dlabnav .add-menu-sidebar {
    display: none;
  }
  [data-layout=horizontal] .dlabnav .slimScrollDiv {
    overflow: visible !important;
  }
  [data-layout=horizontal] .dlabnav .slimScrollDiv .dlabnav-scroll {
    overflow: visible !important;
  }
  [data-layout=horizontal] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }
  [data-layout=horizontal] .dlabnav .slimScrollBar {
    display: none !important;
  }
  [data-layout=horizontal] .dlabnav .nav-user,
[data-layout=horizontal] .dlabnav .nav-label {
    display: none;
  }
  [data-layout=horizontal] .dlabnav .metismenu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  [data-layout=horizontal] .dlabnav .metismenu .collapse.in {
    display: none;
  }
  [data-layout=horizontal] .dlabnav .metismenu ul {
    border-left: 0;
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [data-layout=horizontal] .dlabnav .metismenu li {
    flex-direction: column;
    position: relative;
  }
  [data-layout=horizontal] .dlabnav .metismenu li:hover > ul {
    display: block;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul {
    position: absolute;
    height: auto !important;
    top: 3.4rem;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu li > ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
    background: #1e2746;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul li {
    padding: 0;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul li a {
    transition: all 0.4s ease-in-out;
    padding: 8px 20px 8px 20px;
    margin-left: -0.1rem;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu li > ul li a {
    padding: 8px 45px 8px 20px;
    text-align: right;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul li a:hover {
    border-radius: 0.4rem;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul li a:before {
    left: 22px;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu li > ul li a:before {
    left: auto;
    right: 21px;
  }
  [data-layout=horizontal] .dlabnav .metismenu li > ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu li > ul ul {
    left: auto;
    right: 100%;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li {
    flex: 0 0 auto;
    position: relative;
    margin: auto 0;
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li {
    border-color: rgba(255, 255, 255, 0.07);
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li.mm-active {
    border-color: transparent;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li.mm-active {
    padding: 0;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li.mm-active > a {
    background: var(--rgba-primary-1);
    box-shadow: unset;
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li.mm-active > a {
    background: #181f39;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li:first-child {
    border-right: 0;
  }
  [data-theme-version=dark][direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li {
    border-color: #333a54;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li > a {
    padding: 15px 40px 15px 15px;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li > a {
    padding: 15px 15px 15px 40px;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li > a i {
    padding: 0 0.4375rem 0 0;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li > a i {
    padding: 0 0 0 0.4375rem;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li > a .nav-badge {
    display: none;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li > a:after {
    right: 20px;
    transform: rotate(-135deg) translateY(-50%);
  }
  [data-layout=horizontal] .dlabnav .metismenu > li:hover {
    border-color: transparent;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li:hover > ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li:hover > ul {
    box-shadow: 0px 0 13px 0px rgba(0, 0, 0, 0.1);
  }
  [data-layout=horizontal] .dlabnav .metismenu > li > ul > li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li:nth-last-child(-n+5) > ul {
    left: 0;
    right: 0;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li:nth-last-child(-n+5) > ul > li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }
  [data-layout=horizontal] .dlabnav .metismenu > li:last-child > ul ul {
    left: -100%;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li:nth-last-child(-n+3) > ul {
    left: 0;
    right: auto;
  }
  [direction=rtl][data-layout=horizontal] .dlabnav .metismenu > li:nth-last-child(-n+3) > ul > li:hover ul.collapse {
    right: -100% !important;
    left: auto !important;
  }
  [data-layout=horizontal] .content-body {
    margin-left: 0 !important;
  }
  [data-layout=horizontal] .content-body .page-titles {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.875rem;
  }
  [data-layout=horizontal] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }
  [data-layout=horizontal] .sidebar-right {
    z-index: 1;
  }
  [data-header-position=fixed][data-layout=horizontal] .dlabnav {
    top: 5rem;
  }
  [data-header-position=fixed][data-sidebar-position=fixed] .dlabnav {
    position: fixed;
  }
  /*  [data-header-position="fixed"][data-layout="horizontal"]{
       .content-body {
           padding-top:  unset 8.3rem ;
       }
   } */
  [data-container=boxed][data-layout=horizontal][data-sidebar-position=fixed][data-header-position=fixed] .content-body {
    padding-top: 11.5rem;
  }
  [data-container=boxed][data-layout=horizontal][data-sidebar-position=fixed][data-header-position=fixed][data-sidebar-style=mini] .content-body {
    padding-top: 8.9rem;
  }
  /*  [data-container="boxed"][data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"]{
  	.content-body{
  		 padding-top: 15.5rem;
  	}
  } */
  [data-layout=horizontal][data-container=boxed] .footer {
    margin-left: 0;
    max-width: 1199px;
    margin: 0 auto;
  }
  [data-layout=horizontal][data-container=wide] .page-titles {
    margin-left: -30px;
    margin-right: -30px;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .page-titles {
    margin-top: 0;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu > li > ul {
    top: 4.315rem;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu > li > a {
    padding: 0.8125rem 1.1rem;
    margin-right: 0.625rem;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu > li > a::after {
    display: none;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu > li li {
    text-align: left;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav ul.mm-collapse li a::before {
    display: none;
  }
  [data-theme-version=dark][data-sidebar-style=mini][data-layout=horizontal] .content-body {
    border-left: 1px solid #333a54;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .nav-header {
    width: 6rem;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #EEEEEE;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .nav-header .brand-logo {
    justify-content: start;
    border-bottom: 0;
  }
  [data-theme-version=dark][data-sidebar-style=mini][data-layout=horizontal] .nav-header {
    border-color: #333a54;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .header {
    width: 100%;
    padding-left: 6rem;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .metismenu > li:hover a {
    width: auto;
  }
  [data-sidebar-style=mini][data-layout=horizontal] .metismenu > li:hover a .nav-text {
    display: none;
  }
  [data-header-position=fixed][data-layout=horizontal][data-sidebar-position=fixed] .content-body {
    padding-top: 8.5rem;
  }
  /* [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="full"]{
  	  .content-body {
             padding-top: 8.5rem;
         }
  } */
  [data-header-position=fixed][data-layout=horizontal][data-sidebar-position=fixed][data-sidebar-style=compact] .content-body {
    padding-top: 10.85rem;
  }
  [data-header-position=fixed][data-layout=horizontal][data-sidebar-position=fixed][data-sidebar-style=compact] .dlabnav .metismenu li a i {
    margin-right: 0px;
  }
  [data-sidebar-position=fixed][data-layout=horizontal] .dlabnav.fixed {
    position: fixed;
    left: 0;
    top: 0;
  }
  [data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu li a i {
    font-size: 1.5rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=compact] .dlabnav .nav-user {
    display: none;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu > li > a {
    padding: 1.5rem 1.625rem 1.5rem 1.5rem;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu > li ul {
    padding: 1rem 0;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu > li ul li > a {
    padding: 0.5rem 1rem;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu > li ul li > a.has-arrow:after {
    content: "";
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li {
    text-align: center;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li a svg {
    max-width: 21px;
    max-height: 21px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li a i {
    font-size: 2.2rem;
    margin-right: 0;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li a:after {
    content: none;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li a:before {
    content: "";
    height: 75px;
    width: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    background: #002381;
    border-radius: 0 0.25rem 0.25rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li ul:after {
    content: none;
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li.mm-active > a, [data-sidebar-style=compact] .dlabnav .metismenu li:hover > a {
    /* 	background: -moz-linear-gradient(left,  rgba(224,224,228,1) 0%, rgba(125,185,232,0) 100%); 
    	background: -webkit-linear-gradient(left,  rgba(224,224,228,1) 0%,rgba(125,185,232,0) 100%); 
    	background: linear-gradient(to right,  rgba(224,224,228,1) 0%,rgba(125,185,232,0) 100%);  */
  }
  [data-sidebar-style=compact] .dlabnav .metismenu li.mm-active > a:before, [data-sidebar-style=compact] .dlabnav .metismenu li:hover > a:before {
    width: 10px;
  }
  [data-sidebar-style=compact] .dlabnav .copyright {
    display: none;
  }
  [data-sidebar-style=compact] .add-menu-sidebar {
    display: none;
  }
  [data-sidebar-style=compact] .nav-text {
    display: inline-block;
    margin-top: 0.7125rem;
  }
  [data-sidebar-style=compact] .nav-label.first {
    display: none;
  }
  [data-sidebar-style=compact] .nav-badge {
    display: none;
  }
  [data-sidebar-style=compact] .footer {
    padding-left: 9.375rem;
  }
  [data-sidebar-style=compact] .content-body {
    margin-left: 15rem;
  }
}
[data-layout=horizontal][data-sidebar-style=compact] .footer {
  padding-left: 0;
}
[data-layout=horizontal][data-sidebar-style=compact] .content-body {
  margin-left: 0;
}
[data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu {
  padding: 0 30px;
}
[data-layout=horizontal][data-sidebar-style=compact] .dlabnav .metismenu > li a:before {
  display: none;
}

[data-layout=vertical][data-sidebar-style=compact] .dlabnav {
  width: 11.25rem;
}
[data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu ul {
  background: rgba(0, 0, 0, 0.02);
}
[data-theme-version=dark][data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu ul a:before {
  content: none;
}
[data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}
[data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu > li > a.has-arrow::after {
  top: 1px;
  display: inline-block;
  right: auto;
  margin-left: 5px;
  position: relative;
  width: 7px;
  height: 7px;
  border-width: 2px 0px 0px 2px;
}
[direction=rtl][data-layout=vertical][data-sidebar-style=compact] .dlabnav .metismenu > li > a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 5px;
}

[data-layout=vertical][data-sidebar-style=compact] [data-sidebar-style=compact][data-container=boxed] .content-body {
  margin-left: 11.3rem;
}

@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=horizontal] .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style=icon-hover][data-layout=horizontal] .header .header-content {
    padding-left: 1.875rem;
  }
  [data-layout=vertical][data-sidebar-style=icon-hover][data-container=boxed] .dlabnav {
    position: absolute;
  }
  [data-sidebar-style=icon-hover][data-container=wide-boxed] .header {
    width: 92.5rem;
  }
  [data-container=wide-boxed] {
    background-color: var(--primary);
  }
  [data-container=wide-boxed] .content-body {
    background-color: #EEEEEE;
  }
  [data-theme-version=dark][data-container=wide-boxed] .content-body {
    background-color: #181f39;
    border-right: 1px solid #333a54;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu li.mm-active > ul {
    display: none;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .header-info2 {
    justify-content: space-between;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .header-info2 img {
    order: 1;
    margin-right: 0;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .copyright {
    display: none;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .dlabnav .metismenu li.mm-active > ul {
    display: block;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .dlabnav .copyright {
    display: block;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .nav-header {
    width: 4.9rem;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .nav-header .brand-logo {
    padding-left: 14px;
    padding-right: 20px;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .nav-header .brand-logo .logo-abbr {
    display: block;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .nav-header .brand-logo .brand-title {
    display: none;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .nav-header .nav-control {
    display: none;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .header {
    padding-left: 5.063rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .header .header-content {
    padding-left: 1.375rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .header .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav {
    overflow: visible;
    position: absolute;
    left: -12.6rem;
    transition: 0.5s;
  }
}
@media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav {
    left: -10rem;
  }
}
@media (min-width: 767px) {
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav {
    left: auto;
    right: -12.125rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .nav-label {
    display: none;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .add-menu-sidebar {
    display: none;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu > li > a {
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu > li > a > svg, [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu > li > a > i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 28px;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
    padding-right: 0;
    padding-left: 28px;
  }
  [data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
    border-color: #333a54;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul:after {
    left: auto;
    right: 28px;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul:after {
    left: 28px;
    right: auto;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul a {
    position: relative;
    padding-left: 2rem;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul a::before {
    left: auto;
    right: -5px;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul a::before {
    right: auto;
    left: -5px;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu .has-arrow::after {
    right: 5rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu .has-arrow::after {
    right: auto;
    left: 5rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .nav-header {
    width: 17.5rem;
    left: auto;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .nav-header .brand-logo .logo {
    display: flex;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .nav-header .brand-logo .brand-title {
    display: inline-block;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1400px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .nav-header {
    width: 17rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle.mm-show {
    display: block;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .header {
    padding-left: 4.38rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .dlabnav {
    left: 0;
  }
  [data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .dlabnav .metismenu .has-arrow:after {
    opacity: 1;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .iconhover-toggle .dlabnav {
    left: auto;
    right: 0;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .content-body {
    margin-left: 4.9rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .content-body {
    margin-left: 0;
    margin-right: 5rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=icon-hover][data-layout=vertical] .footer {
    padding-left: 4.375rem;
  }
  [direction=rtl][data-sidebar-style=icon-hover][data-layout=vertical] .footer {
    margin-left: 0;
    margin-right: 4.375rem;
  }
}
@media (min-width: 767px) {
  [data-sidebar-style=modern] .nav-header {
    width: 9.375rem;
  }
  [data-sidebar-style=modern] .nav-header .brand-logo {
    justify-content: center;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li {
    text-align: center;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li > a {
    padding: 20px 15px 20px 15px !important;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li > a::after {
    display: none;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li > a:hover > a, [data-sidebar-style=modern] .dlabnav .metismenu > li > a:focus > a, [data-sidebar-style=modern] .dlabnav .metismenu > li > a:active > a, [data-sidebar-style=modern] .dlabnav .metismenu > li > a.mm-active > a {
    background-color: #81a3ff;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active {
    padding: 0;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu > li li {
    text-align: left;
  }
  [direction=rtl][data-sidebar-style=modern] .dlabnav .metismenu > li li {
    text-align: right;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu li ul:after {
    content: none;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu li > ul {
    height: auto !important;
  }
  [data-sidebar-style=modern] .dlabnav .metismenu .nav-label {
    display: none;
  }
  [data-sidebar-style=modern] .dlabnav .nav-label {
    display: none;
  }
  [data-sidebar-style=modern] .dlabnav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }
  [data-sidebar-style=modern] .footer {
    padding-left: 9.375rem;
  }
  [data-sidebar-style=modern] .content-body {
    margin-left: 9.375rem;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav {
    width: 9.375rem;
    left: 0;
  }
  [direction=rtl][data-sidebar-style=modern][data-layout=vertical] .dlabnav {
    left: auto;
    right: 0;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .slimScrollDiv,
[data-sidebar-style=modern][data-layout=vertical] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu > li > a i {
    font-size: 20px;
    margin: 0;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu > li > ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li {
    position: relative;
    padding: 0 0.625rem;
    margin-bottom: 0.625rem;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li a {
    padding: 0.625rem 1.5rem;
    border-radius: 0.25rem;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li a:hover {
    background-color: var(--rgba-primary-1);
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li a:before {
    content: none;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 1px solid #f0f1f5;
    width: 200px;
  }
  [data-theme-version=dark][data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li ul {
    background: #1e2746;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [direction=rtl][data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li ul {
    left: auto;
    right: 105%;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li:hover > ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: -10px;
    border: 0;
    box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-theme-version=dark][data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li:hover > ul {
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [direction=rtl][data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu li:hover > ul {
    left: auto;
    right: 100%;
    box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .nav-label {
    display: none;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .nav-text {
    display: block;
    margin-top: 0;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .add-menu-sidebar {
    display: none;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .copyright {
    display: none;
  }
  [data-sidebar-style=modern][data-layout=vertical] .menu-toggle .dlabnav {
    left: -9.375rem;
  }
  [direction=rtl][data-sidebar-style=modern][data-layout=vertical] .menu-toggle .dlabnav {
    left: auto;
    right: -9.375rem;
  }
  [data-sidebar-style=modern][data-layout=vertical] .menu-toggle .content-body {
    margin-left: 0;
  }
  [direction=rtl][data-sidebar-style=modern][data-layout=vertical] .menu-toggle .content-body {
    margin-right: 0;
  }
  [data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a {
    background-color: var(--rgba-primary-1);
    border-radius: 0.25rem;
  }
  [data-theme-version=dark][data-sidebar-style=modern][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a {
    background: #181f39;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .nav-header {
    width: 16rem;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .footer,
[data-sidebar-style=modern][data-layout=horizontal] .content-body {
    margin-left: 0;
    /* padding-top:10.6rem; */
  }
  [data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu > li {
    margin-right: 0.625rem;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu > li > a {
    padding: 0.8125rem 2.25rem;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu > li > a i {
    margin-right: 0;
    padding-right: 0;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu > li > ul {
    top: 4.5625rem;
  }
  [data-sidebar-style=modern][data-layout=horizontal] .nav-header .logo-abbr {
    min-width: 52px;
  }
  [data-sidebar-style=modern][data-layout=horizontal][data-container=boxed] .dlabnav .metismenu > li > a {
    padding: 0.8125rem 1.25rem;
  }
  [data-theme-version=dark][data-sidebar-style=modern][data-layout=horizontal] .dlabnav .metismenu > li.mm-active > a {
    background: #181f39;
  }
  [data-sidebar-style=modern][data-sidebar-position=fixed][data-layout=horizontal] .content-body {
    margin-left: 0;
  }
  [data-sidebar-style=modern][data-layout=horizontal][data-sidebar-position=fixed][data-header-position=fixed] .content-body {
    padding-top: 10.5rem;
  }
}
[data-sidebar-style=overlay] .dlabnav {
  left: -100%;
}
[direction=rtl][data-sidebar-style=overlay] .dlabnav {
  left: auto;
  right: -100%;
}

[data-sidebar-style=overlay] .content-body {
  margin-left: 0;
}
[data-sidebar-style=overlay] .nav-header {
  position: absolute;
}
[data-sidebar-style=overlay] .nav-header .hamburger.is-active {
  left: 0;
}
[data-sidebar-style=overlay] .nav-header .hamburger.is-active .line {
  background-color: #002381;
}
[data-sidebar-style=overlay] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}
[data-sidebar-style=overlay] .menu-toggle .dlabnav {
  left: 0;
}
[direction=rtl][data-sidebar-style=overlay] .menu-toggle .dlabnav {
  left: auto;
  right: 0;
}

[data-sidebar-style=overlay] .footer {
  padding-left: 0;
}
[data-sidebar-style=overlay] .dlabnav .metismenu ul a {
  padding-left: 4.4rem;
}

[data-sidebar-style=overlay][data-header-position=fixed] .nav-header {
  position: fixed;
}

[data-sidebar-position=fixed][data-header-position=fixed] .nav-header {
  position: fixed;
}

[data-sidebar-style=overlay][data-sidebar-style=overlay] .nav-control {
  right: -3.0625rem;
}

[data-sidebar-position=fixed][data-layout=vertical] .nav-header {
  position: fixed;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
[data-sidebar-position=fixed][data-layout=vertical] .dlabnav {
  position: fixed;
}
[data-sidebar-position=fixed][data-layout=vertical] .dlabnav .dlabnav-scroll {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
[data-sidebar-position=fixed][data-layout=vertical] .menu-toggle .dlabnav {
  position: fixed;
}

[data-header-position=fixed][data-sidebar-position=fixed][data-sidebar-style=overlay][data-layout=vertical][data-container=boxed] .dlabnav {
  position: absolute;
}

[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_2] .nav-header {
  background-color: #143b64;
  border-bottom: 1px solid #1d548f !important;
}
[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_2] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_2] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_2] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_2] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_2][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_2] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_2][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_2][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_3] .nav-header {
  background-color: #002381;
  border-bottom: 1px solid #0031b4 !important;
}
[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_3] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_3] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_3] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_3] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_3][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_3] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_3][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_3][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_4] .nav-header {
  background-color: #4527a0;
  border-bottom: 1px solid #5731c9 !important;
}
[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_4] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_4] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_4] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_4] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_4][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_4] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_4][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_4][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_5] .nav-header {
  background-color: #c62828;
  border-bottom: 1px solid #da4747 !important;
}
[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_5] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_5] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_5] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_5] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_5][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_5] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_5][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_5][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_6] .nav-header {
  background-color: #283593;
  border-bottom: 1px solid #3343bb !important;
}
[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_6] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_6] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_6] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_6] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_6][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_6] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_6][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_6][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_7] .nav-header {
  background-color: #7356f1;
  border-bottom: 1px solid #9a85f5 !important;
}
[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_7] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_7] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_7] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_7] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_7][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_7] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_7][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_7][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_8] .nav-header {
  background-color: #3695eb;
  border-bottom: 1px solid #64adf0 !important;
}
[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_8] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_8] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_8] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_8] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_8][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_8] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_8][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_8][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_9] .nav-header {
  background-color: #00838f;
  border-bottom: 1px solid #00b2c2 !important;
}
[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_9] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_9] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_9] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_9] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_9][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_9] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_9][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_9][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_10] .nav-header {
  background-color: #ff8f16;
  border-bottom: 1px solid #ffa849 !important;
}
[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_10] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_10] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_10] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_10] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_10][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_10] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_10][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_10][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_11] .nav-header {
  background-color: #6673fd;
  border-bottom: 1px solid #98a1fe !important;
}
[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_11] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_11] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_11] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_11] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_11][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_11] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_11][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_11][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_12] .nav-header {
  background-color: #558b2f;
  border-bottom: 1px solid #6cb13c !important;
}
[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_12] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_12] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_12] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_12] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_12][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_12] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_12][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_12][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_13] .nav-header {
  background-color: #2a2a2a;
  border-bottom: 1px solid #444444 !important;
}
[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_13] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_13] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_13] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_13] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_13][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_13] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_13][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_13][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_14] .nav-header {
  background-color: #1367c8;
  border-bottom: 1px solid #2480ea !important;
}
[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_14] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_14] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_14] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_14] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_14][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_14] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_14][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_14][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header,
[data-nav-headerbg=color_15] .nav-header {
  background-color: #FF005C;
  border-bottom: 1px solid #ff337d !important;
}
[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header .brand-logo,
[data-nav-headerbg=color_15] .nav-header .brand-logo {
  color: #fff;
  border: none;
}
[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header .brand-logo:hover,
[data-nav-headerbg=color_15] .nav-header .brand-logo:hover {
  color: #fff;
}
[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header .logo-abbr svg g path,
[data-nav-headerbg=color_15] .nav-header .logo-abbr svg g path {
  fill: #fff;
}
[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header .brand-title svg g,
[data-nav-headerbg=color_15] .nav-header .brand-title svg g {
  fill: #fff;
}
[data-nav-headerbg=color_15][data-theme-version=dark] .nav-header .hamburger .line,
[data-nav-headerbg=color_15] .nav-header .hamburger .line {
  background: #fff;
}
[data-nav-headerbg=color_15][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg=color_15][data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff;
}

[data-headerbg=color_2][data-theme-version=dark] .header,
[data-headerbg=color_2] .header {
  background-color: #143b64;
  color: #fff;
  border-color: #1d548f;
}
[data-headerbg=color_2][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_2] .header .search-area .input-group-text {
  border-color: #1d548f;
  background-color: transparent;
}
[data-headerbg=color_2][data-theme-version=dark] .header .form-control,
[data-headerbg=color_2] .header .form-control {
  border-color: #1d548f;
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left input,
[data-headerbg=color_2] .header-left input {
  border-color: #1d548f;
  background-color: #1d548f !important;
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_2] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_2] .header-left input:focus {
  border-color: #0c223a;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_2] .header-left .search_icon {
  background-color: #1d548f !important;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_2] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_2] .header-left .btn {
  background-color: #0c223a;
  border-color: #0c223a;
}
[data-headerbg=color_2][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_2][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_2][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_2] .header-left .btn:hover,
[data-headerbg=color_2] .header-left .btn:focus,
[data-headerbg=color_2] .header-left .btn:active {
  background-color: #03090f;
  border-color: #03090f;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_2] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #1d548f;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_2] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_2][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_2] .header-right .pulse-css:after,
[data-headerbg=color_2] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_2] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_2] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_2] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_2] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_2][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_2] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_2] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_2] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_2][data-theme-version=dark] .nav-control,
[data-headerbg=color_2] .nav-control {
  background-color: #1d548f;
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_2] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_2][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_2] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_2] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_2][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_2] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_2][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_2] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_2][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_2] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_2][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_2] .search-area .form-control:hover {
  border-color: #1d548f;
}
[data-headerbg=color_2][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_2] .search-area .form-control:focus {
  border-color: #1d548f;
}

[data-headerbg=color_3][data-theme-version=dark] .header,
[data-headerbg=color_3] .header {
  background-color: #002381;
  color: #fff;
  border-color: #0031b4;
}
[data-headerbg=color_3][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_3] .header .search-area .input-group-text {
  border-color: #0031b4;
  background-color: transparent;
}
[data-headerbg=color_3][data-theme-version=dark] .header .form-control,
[data-headerbg=color_3] .header .form-control {
  border-color: #0031b4;
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left input,
[data-headerbg=color_3] .header-left input {
  border-color: #0031b4;
  background-color: #0031b4 !important;
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_3] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_3] .header-left input:focus {
  border-color: #00154e;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_3] .header-left .search_icon {
  background-color: #0031b4 !important;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_3] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_3] .header-left .btn {
  background-color: #00154e;
  border-color: #00154e;
}
[data-headerbg=color_3][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_3][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_3][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_3] .header-left .btn:hover,
[data-headerbg=color_3] .header-left .btn:focus,
[data-headerbg=color_3] .header-left .btn:active {
  background-color: #00071b;
  border-color: #00071b;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_3] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #0031b4;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_3] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_3][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_3] .header-right .pulse-css:after,
[data-headerbg=color_3] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_3] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_3] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_3] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_3] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_3][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_3] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_3] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_3] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_3][data-theme-version=dark] .nav-control,
[data-headerbg=color_3] .nav-control {
  background-color: #0031b4;
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_3] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_3][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_3] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_3] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_3][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_3] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_3][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_3] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_3][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_3] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_3][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_3] .search-area .form-control:hover {
  border-color: #0031b4;
}
[data-headerbg=color_3][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_3] .search-area .form-control:focus {
  border-color: #0031b4;
}

[data-headerbg=color_4][data-theme-version=dark] .header,
[data-headerbg=color_4] .header {
  background-color: #4527a0;
  color: #fff;
  border-color: #5731c9;
}
[data-headerbg=color_4][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_4] .header .search-area .input-group-text {
  border-color: #5731c9;
  background-color: transparent;
}
[data-headerbg=color_4][data-theme-version=dark] .header .form-control,
[data-headerbg=color_4] .header .form-control {
  border-color: #5731c9;
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left input,
[data-headerbg=color_4] .header-left input {
  border-color: #5731c9;
  background-color: #5731c9 !important;
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_4] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_4] .header-left input:focus {
  border-color: #331d77;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_4] .header-left .search_icon {
  background-color: #5731c9 !important;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_4] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_4] .header-left .btn {
  background-color: #331d77;
  border-color: #331d77;
}
[data-headerbg=color_4][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_4][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_4][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_4] .header-left .btn:hover,
[data-headerbg=color_4] .header-left .btn:focus,
[data-headerbg=color_4] .header-left .btn:active {
  background-color: #22134e;
  border-color: #22134e;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_4] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #5731c9;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_4] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_4][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_4] .header-right .pulse-css:after,
[data-headerbg=color_4] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_4] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_4] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_4] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_4] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_4][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_4] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_4] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_4] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_4][data-theme-version=dark] .nav-control,
[data-headerbg=color_4] .nav-control {
  background-color: #5731c9;
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_4] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_4][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_4] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_4] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_4][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_4] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_4][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_4] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_4][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_4] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_4][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_4] .search-area .form-control:hover {
  border-color: #5731c9;
}
[data-headerbg=color_4][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_4] .search-area .form-control:focus {
  border-color: #5731c9;
}

[data-headerbg=color_5][data-theme-version=dark] .header,
[data-headerbg=color_5] .header {
  background-color: #c62828;
  color: #fff;
  border-color: #da4747;
}
[data-headerbg=color_5][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_5] .header .search-area .input-group-text {
  border-color: #da4747;
  background-color: transparent;
}
[data-headerbg=color_5][data-theme-version=dark] .header .form-control,
[data-headerbg=color_5] .header .form-control {
  border-color: #da4747;
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left input,
[data-headerbg=color_5] .header-left input {
  border-color: #da4747;
  background-color: #da4747 !important;
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_5] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_5] .header-left input:focus {
  border-color: #9c1f1f;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_5] .header-left .search_icon {
  background-color: #da4747 !important;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_5] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_5] .header-left .btn {
  background-color: #9c1f1f;
  border-color: #9c1f1f;
}
[data-headerbg=color_5][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_5][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_5][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_5] .header-left .btn:hover,
[data-headerbg=color_5] .header-left .btn:focus,
[data-headerbg=color_5] .header-left .btn:active {
  background-color: #711717;
  border-color: #711717;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_5] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #da4747;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_5] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_5][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_5] .header-right .pulse-css:after,
[data-headerbg=color_5] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_5] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_5] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_5] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_5] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_5][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_5] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_5] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_5] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_5][data-theme-version=dark] .nav-control,
[data-headerbg=color_5] .nav-control {
  background-color: #da4747;
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_5] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_5][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_5] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_5] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_5][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_5] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_5][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_5] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_5][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_5] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_5][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_5] .search-area .form-control:hover {
  border-color: #da4747;
}
[data-headerbg=color_5][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_5] .search-area .form-control:focus {
  border-color: #da4747;
}

[data-headerbg=color_6][data-theme-version=dark] .header,
[data-headerbg=color_6] .header {
  background-color: #283593;
  color: #fff;
  border-color: #3343bb;
}
[data-headerbg=color_6][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_6] .header .search-area .input-group-text {
  border-color: #3343bb;
  background-color: transparent;
}
[data-headerbg=color_6][data-theme-version=dark] .header .form-control,
[data-headerbg=color_6] .header .form-control {
  border-color: #3343bb;
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left input,
[data-headerbg=color_6] .header-left input {
  border-color: #3343bb;
  background-color: #3343bb !important;
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_6] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_6] .header-left input:focus {
  border-color: #1d276b;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_6] .header-left .search_icon {
  background-color: #3343bb !important;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_6] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_6] .header-left .btn {
  background-color: #1d276b;
  border-color: #1d276b;
}
[data-headerbg=color_6][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_6][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_6][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_6] .header-left .btn:hover,
[data-headerbg=color_6] .header-left .btn:focus,
[data-headerbg=color_6] .header-left .btn:active {
  background-color: #121843;
  border-color: #121843;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_6] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #3343bb;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_6] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_6][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_6] .header-right .pulse-css:after,
[data-headerbg=color_6] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_6] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_6] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_6] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_6] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_6][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_6] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_6] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_6] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_6][data-theme-version=dark] .nav-control,
[data-headerbg=color_6] .nav-control {
  background-color: #3343bb;
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_6] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_6][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_6] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_6] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_6][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_6] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_6][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_6] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_6][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_6] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_6][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_6] .search-area .form-control:hover {
  border-color: #3343bb;
}
[data-headerbg=color_6][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_6] .search-area .form-control:focus {
  border-color: #3343bb;
}

[data-headerbg=color_7][data-theme-version=dark] .header,
[data-headerbg=color_7] .header {
  background-color: #7356f1;
  color: #fff;
  border-color: #9a85f5;
}
[data-headerbg=color_7][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_7] .header .search-area .input-group-text {
  border-color: #9a85f5;
  background-color: transparent;
}
[data-headerbg=color_7][data-theme-version=dark] .header .form-control,
[data-headerbg=color_7] .header .form-control {
  border-color: #9a85f5;
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left input,
[data-headerbg=color_7] .header-left input {
  border-color: #9a85f5;
  background-color: #9a85f5 !important;
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_7] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_7] .header-left input:focus {
  border-color: #4c27ed;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_7] .header-left .search_icon {
  background-color: #9a85f5 !important;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_7] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_7] .header-left .btn {
  background-color: #4c27ed;
  border-color: #4c27ed;
}
[data-headerbg=color_7][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_7][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_7][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_7] .header-left .btn:hover,
[data-headerbg=color_7] .header-left .btn:focus,
[data-headerbg=color_7] .header-left .btn:active {
  background-color: #3511d0;
  border-color: #3511d0;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_7] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #9a85f5;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_7] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_7][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_7] .header-right .pulse-css:after,
[data-headerbg=color_7] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_7] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_7] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_7] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_7] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_7][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_7] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_7] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_7] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_7][data-theme-version=dark] .nav-control,
[data-headerbg=color_7] .nav-control {
  background-color: #9a85f5;
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_7] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_7][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_7] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_7] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_7][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_7] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_7][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_7] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_7][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_7] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_7][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_7] .search-area .form-control:hover {
  border-color: #9a85f5;
}
[data-headerbg=color_7][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_7] .search-area .form-control:focus {
  border-color: #9a85f5;
}

[data-headerbg=color_8][data-theme-version=dark] .header,
[data-headerbg=color_8] .header {
  background-color: #3695eb;
  color: #fff;
  border-color: #64adf0;
}
[data-headerbg=color_8][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_8] .header .search-area .input-group-text {
  border-color: #64adf0;
  background-color: transparent;
}
[data-headerbg=color_8][data-theme-version=dark] .header .form-control,
[data-headerbg=color_8] .header .form-control {
  border-color: #64adf0;
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left input,
[data-headerbg=color_8] .header-left input {
  border-color: #64adf0;
  background-color: #64adf0 !important;
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_8] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_8] .header-left input:focus {
  border-color: #167cd8;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_8] .header-left .search_icon {
  background-color: #64adf0 !important;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_8] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_8] .header-left .btn {
  background-color: #167cd8;
  border-color: #167cd8;
}
[data-headerbg=color_8][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_8][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_8][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_8] .header-left .btn:hover,
[data-headerbg=color_8] .header-left .btn:focus,
[data-headerbg=color_8] .header-left .btn:active {
  background-color: #1161aa;
  border-color: #1161aa;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_8] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #64adf0;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_8] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_8][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_8] .header-right .pulse-css:after,
[data-headerbg=color_8] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_8] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_8] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_8] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_8] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_8][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_8] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_8] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_8] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_8][data-theme-version=dark] .nav-control,
[data-headerbg=color_8] .nav-control {
  background-color: #64adf0;
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_8] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_8][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_8] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_8] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_8][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_8] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_8][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_8] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_8][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_8] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_8][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_8] .search-area .form-control:hover {
  border-color: #64adf0;
}
[data-headerbg=color_8][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_8] .search-area .form-control:focus {
  border-color: #64adf0;
}

[data-headerbg=color_9][data-theme-version=dark] .header,
[data-headerbg=color_9] .header {
  background-color: #00838f;
  color: #fff;
  border-color: #00b2c2;
}
[data-headerbg=color_9][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_9] .header .search-area .input-group-text {
  border-color: #00b2c2;
  background-color: transparent;
}
[data-headerbg=color_9][data-theme-version=dark] .header .form-control,
[data-headerbg=color_9] .header .form-control {
  border-color: #00b2c2;
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left input,
[data-headerbg=color_9] .header-left input {
  border-color: #00b2c2;
  background-color: #00b2c2 !important;
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_9] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_9] .header-left input:focus {
  border-color: #00545c;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_9] .header-left .search_icon {
  background-color: #00b2c2 !important;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_9] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_9] .header-left .btn {
  background-color: #00545c;
  border-color: #00545c;
}
[data-headerbg=color_9][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_9][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_9][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_9] .header-left .btn:hover,
[data-headerbg=color_9] .header-left .btn:focus,
[data-headerbg=color_9] .header-left .btn:active {
  background-color: #002629;
  border-color: #002629;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_9] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #00b2c2;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_9] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_9][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_9] .header-right .pulse-css:after,
[data-headerbg=color_9] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_9] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_9] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_9] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_9] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_9][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_9] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_9] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_9] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_9][data-theme-version=dark] .nav-control,
[data-headerbg=color_9] .nav-control {
  background-color: #00b2c2;
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_9] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_9][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_9] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_9] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_9][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_9] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_9][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_9] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_9][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_9] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_9][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_9] .search-area .form-control:hover {
  border-color: #00b2c2;
}
[data-headerbg=color_9][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_9] .search-area .form-control:focus {
  border-color: #00b2c2;
}

[data-headerbg=color_10][data-theme-version=dark] .header,
[data-headerbg=color_10] .header {
  background-color: #ff8f16;
  color: #fff;
  border-color: #ffa849;
}
[data-headerbg=color_10][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_10] .header .search-area .input-group-text {
  border-color: #ffa849;
  background-color: transparent;
}
[data-headerbg=color_10][data-theme-version=dark] .header .form-control,
[data-headerbg=color_10] .header .form-control {
  border-color: #ffa849;
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left input,
[data-headerbg=color_10] .header-left input {
  border-color: #ffa849;
  background-color: #ffa849 !important;
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_10] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_10] .header-left input:focus {
  border-color: #e27500;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_10] .header-left .search_icon {
  background-color: #ffa849 !important;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_10] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_10] .header-left .btn {
  background-color: #e27500;
  border-color: #e27500;
}
[data-headerbg=color_10][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_10][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_10][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_10] .header-left .btn:hover,
[data-headerbg=color_10] .header-left .btn:focus,
[data-headerbg=color_10] .header-left .btn:active {
  background-color: #af5b00;
  border-color: #af5b00;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_10] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #ffa849;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_10] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_10][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_10] .header-right .pulse-css:after,
[data-headerbg=color_10] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_10] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_10] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_10] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_10] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_10][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_10] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_10] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_10] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_10][data-theme-version=dark] .nav-control,
[data-headerbg=color_10] .nav-control {
  background-color: #ffa849;
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_10] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_10][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_10] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_10] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_10][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_10] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_10][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_10] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_10][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_10] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_10][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_10] .search-area .form-control:hover {
  border-color: #ffa849;
}
[data-headerbg=color_10][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_10] .search-area .form-control:focus {
  border-color: #ffa849;
}

[data-headerbg=color_11][data-theme-version=dark] .header,
[data-headerbg=color_11] .header {
  background-color: #6673fd;
  color: #fff;
  border-color: #98a1fe;
}
[data-headerbg=color_11][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_11] .header .search-area .input-group-text {
  border-color: #98a1fe;
  background-color: transparent;
}
[data-headerbg=color_11][data-theme-version=dark] .header .form-control,
[data-headerbg=color_11] .header .form-control {
  border-color: #98a1fe;
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left input,
[data-headerbg=color_11] .header-left input {
  border-color: #98a1fe;
  background-color: #98a1fe !important;
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_11] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_11] .header-left input:focus {
  border-color: #3445fc;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_11] .header-left .search_icon {
  background-color: #98a1fe !important;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_11] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_11] .header-left .btn {
  background-color: #3445fc;
  border-color: #3445fc;
}
[data-headerbg=color_11][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_11][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_11][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_11] .header-left .btn:hover,
[data-headerbg=color_11] .header-left .btn:focus,
[data-headerbg=color_11] .header-left .btn:active {
  background-color: #0318fa;
  border-color: #0318fa;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_11] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #98a1fe;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_11] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_11][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_11] .header-right .pulse-css:after,
[data-headerbg=color_11] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_11] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_11] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_11] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_11] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_11][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_11] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_11] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_11] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_11][data-theme-version=dark] .nav-control,
[data-headerbg=color_11] .nav-control {
  background-color: #98a1fe;
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_11] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_11][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_11] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_11] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_11][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_11] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_11][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_11] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_11][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_11] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_11][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_11] .search-area .form-control:hover {
  border-color: #98a1fe;
}
[data-headerbg=color_11][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_11] .search-area .form-control:focus {
  border-color: #98a1fe;
}

[data-headerbg=color_12][data-theme-version=dark] .header,
[data-headerbg=color_12] .header {
  background-color: #558b2f;
  color: #fff;
  border-color: #6cb13c;
}
[data-headerbg=color_12][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_12] .header .search-area .input-group-text {
  border-color: #6cb13c;
  background-color: transparent;
}
[data-headerbg=color_12][data-theme-version=dark] .header .form-control,
[data-headerbg=color_12] .header .form-control {
  border-color: #6cb13c;
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left input,
[data-headerbg=color_12] .header-left input {
  border-color: #6cb13c;
  background-color: #6cb13c !important;
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_12] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_12] .header-left input:focus {
  border-color: #3e6522;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_12] .header-left .search_icon {
  background-color: #6cb13c !important;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_12] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_12] .header-left .btn {
  background-color: #3e6522;
  border-color: #3e6522;
}
[data-headerbg=color_12][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_12][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_12][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_12] .header-left .btn:hover,
[data-headerbg=color_12] .header-left .btn:focus,
[data-headerbg=color_12] .header-left .btn:active {
  background-color: #263f15;
  border-color: #263f15;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_12] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #6cb13c;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_12] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_12][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_12] .header-right .pulse-css:after,
[data-headerbg=color_12] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_12] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_12] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_12] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_12] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_12][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_12] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_12] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_12] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_12][data-theme-version=dark] .nav-control,
[data-headerbg=color_12] .nav-control {
  background-color: #6cb13c;
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_12] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_12][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_12] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_12] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_12][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_12] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_12][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_12] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_12][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_12] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_12][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_12] .search-area .form-control:hover {
  border-color: #6cb13c;
}
[data-headerbg=color_12][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_12] .search-area .form-control:focus {
  border-color: #6cb13c;
}

[data-headerbg=color_13][data-theme-version=dark] .header,
[data-headerbg=color_13] .header {
  background-color: #2a2a2a;
  color: #fff;
  border-color: #444444;
}
[data-headerbg=color_13][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_13] .header .search-area .input-group-text {
  border-color: #444444;
  background-color: transparent;
}
[data-headerbg=color_13][data-theme-version=dark] .header .form-control,
[data-headerbg=color_13] .header .form-control {
  border-color: #444444;
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left input,
[data-headerbg=color_13] .header-left input {
  border-color: #444444;
  background-color: #444444 !important;
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_13] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_13] .header-left input:focus {
  border-color: #111111;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_13] .header-left .search_icon {
  background-color: #444444 !important;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_13] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_13] .header-left .btn {
  background-color: #111111;
  border-color: #111111;
}
[data-headerbg=color_13][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_13][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_13][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_13] .header-left .btn:hover,
[data-headerbg=color_13] .header-left .btn:focus,
[data-headerbg=color_13] .header-left .btn:active {
  background-color: black;
  border-color: black;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_13] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #444444;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_13] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_13][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_13] .header-right .pulse-css:after,
[data-headerbg=color_13] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_13] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_13] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_13] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_13] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_13][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_13] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_13] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_13] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_13][data-theme-version=dark] .nav-control,
[data-headerbg=color_13] .nav-control {
  background-color: #444444;
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_13] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_13][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_13] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_13] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_13][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_13] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_13][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_13] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_13][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_13] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_13][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_13] .search-area .form-control:hover {
  border-color: #444444;
}
[data-headerbg=color_13][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_13] .search-area .form-control:focus {
  border-color: #444444;
}

[data-headerbg=color_14][data-theme-version=dark] .header,
[data-headerbg=color_14] .header {
  background-color: #1367c8;
  color: #fff;
  border-color: #2480ea;
}
[data-headerbg=color_14][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_14] .header .search-area .input-group-text {
  border-color: #2480ea;
  background-color: transparent;
}
[data-headerbg=color_14][data-theme-version=dark] .header .form-control,
[data-headerbg=color_14] .header .form-control {
  border-color: #2480ea;
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left input,
[data-headerbg=color_14] .header-left input {
  border-color: #2480ea;
  background-color: #2480ea !important;
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_14] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_14] .header-left input:focus {
  border-color: #0f4f99;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_14] .header-left .search_icon {
  background-color: #2480ea !important;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_14] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_14] .header-left .btn {
  background-color: #0f4f99;
  border-color: #0f4f99;
}
[data-headerbg=color_14][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_14][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_14][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_14] .header-left .btn:hover,
[data-headerbg=color_14] .header-left .btn:focus,
[data-headerbg=color_14] .header-left .btn:active {
  background-color: #0a376b;
  border-color: #0a376b;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_14] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #2480ea;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_14] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_14][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_14] .header-right .pulse-css:after,
[data-headerbg=color_14] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_14] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_14] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_14] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_14] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_14][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_14] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_14] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_14] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_14][data-theme-version=dark] .nav-control,
[data-headerbg=color_14] .nav-control {
  background-color: #2480ea;
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_14] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_14][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_14] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_14] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_14][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_14] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_14][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_14] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_14][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_14] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_14][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_14] .search-area .form-control:hover {
  border-color: #2480ea;
}
[data-headerbg=color_14][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_14] .search-area .form-control:focus {
  border-color: #2480ea;
}

[data-headerbg=color_15][data-theme-version=dark] .header,
[data-headerbg=color_15] .header {
  background-color: #FF005C;
  color: #fff;
  border-color: #ff337d;
}
[data-headerbg=color_15][data-theme-version=dark] .header .search-area .input-group-text,
[data-headerbg=color_15] .header .search-area .input-group-text {
  border-color: #ff337d;
  background-color: transparent;
}
[data-headerbg=color_15][data-theme-version=dark] .header .form-control,
[data-headerbg=color_15] .header .form-control {
  border-color: #ff337d;
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left input,
[data-headerbg=color_15] .header-left input {
  border-color: #ff337d;
  background-color: #ff337d !important;
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left input::placeholder,
[data-headerbg=color_15] .header-left input::placeholder {
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left input:focus,
[data-headerbg=color_15] .header-left input:focus {
  border-color: #cc004a;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left .search_icon,
[data-headerbg=color_15] .header-left .search_icon {
  background-color: #ff337d !important;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left .search_icon i,
[data-headerbg=color_15] .header-left .search_icon i {
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left .btn,
[data-headerbg=color_15] .header-left .btn {
  background-color: #cc004a;
  border-color: #cc004a;
}
[data-headerbg=color_15][data-theme-version=dark] .header-left .btn:hover, [data-headerbg=color_15][data-theme-version=dark] .header-left .btn:focus, [data-headerbg=color_15][data-theme-version=dark] .header-left .btn:active,
[data-headerbg=color_15] .header-left .btn:hover,
[data-headerbg=color_15] .header-left .btn:focus,
[data-headerbg=color_15] .header-left .btn:active {
  background-color: #990037;
  border-color: #990037;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .dropdown > a,
[data-headerbg=color_15] .header-right .dropdown > a {
  color: rgba(255, 255, 255, 0.7);
  border-color: #ff337d;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .pulse-css,
[data-headerbg=color_15] .header-right .pulse-css {
  background-color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .pulse-css:after, [data-headerbg=color_15][data-theme-version=dark] .header-right .pulse-css:before,
[data-headerbg=color_15] .header-right .pulse-css:after,
[data-headerbg=color_15] .header-right .pulse-css:before {
  background-color: inherit;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .notification_dropdown .nav-link,
[data-headerbg=color_15] .header-right .notification_dropdown .nav-link {
  background-color: transparent;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg=color_15] .header-right .notification_dropdown .nav-link svg path {
  fill: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .right-sidebar a,
[data-headerbg=color_15] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg=color_15] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_15][data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_15] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg=color_15] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg=color_15] .header-right .header-profile > a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_15][data-theme-version=dark] .nav-control,
[data-headerbg=color_15] .nav-control {
  background-color: #ff337d;
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .hamburger .line,
[data-headerbg=color_15] .hamburger .line {
  background-color: rgba(255, 255, 255, 0.7);
}
[data-headerbg=color_15][data-theme-version=dark] .header-left .dashboard_bar,
[data-headerbg=color_15] .header-left .dashboard_bar {
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .header-info .text-black,
[data-headerbg=color_15] .header-info .text-black {
  color: #fff !important;
}
[data-headerbg=color_15][data-theme-version=dark] .search-area:hover .form-control,
[data-headerbg=color_15] .search-area:hover .form-control {
  border-color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_15][data-theme-version=dark] .search-area .input-group-text i,
[data-headerbg=color_15] .search-area .input-group-text i {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
}
[data-headerbg=color_15][data-theme-version=dark] .search-area .form-control::placeholder,
[data-headerbg=color_15] .search-area .form-control::placeholder {
  color: #fff;
}
[data-headerbg=color_15][data-theme-version=dark] .search-area .form-control:hover,
[data-headerbg=color_15] .search-area .form-control:hover {
  border-color: #ff337d;
}
[data-headerbg=color_15][data-theme-version=dark] .search-area .form-control:focus,
[data-headerbg=color_15] .search-area .form-control:focus {
  border-color: #ff337d;
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_2] .dlabnav {
  background-color: #143b64;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_2] .dlabnav .menu-tabs li a.active {
  color: #143b64;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_2] .dlabnav .add-menu-sidebar {
  background: #143b64;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_2] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_2] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_2] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_2] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_2] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_2] .dlabnav .metismenu ul {
  background-color: #143b64 !important;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_2] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_2] .dlabnav .metismenu ul > li {
  border-color: #1d548f;
}

[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_2] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_2] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_2] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_2] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_2] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_2] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_2][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_2] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_2][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #1d548f;
}
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_2][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_2][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #1d548f;
}
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_2][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_2][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_2][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #1d548f;
}
[data-sidebarbg=color_2][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_2][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_2][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_2][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_2][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_3] .dlabnav {
  background-color: #002381;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_3] .dlabnav .menu-tabs li a.active {
  color: #002381;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_3] .dlabnav .add-menu-sidebar {
  background: #002381;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_3] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_3] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_3] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_3] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_3] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_3] .dlabnav .metismenu ul {
  background-color: #002381 !important;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_3] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_3] .dlabnav .metismenu ul > li {
  border-color: #0031b4;
}

[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_3] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_3] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_3] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_3] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_3] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_3] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_3][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_3] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_3][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #0031b4;
}
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_3][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_3][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #0031b4;
}
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_3][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_3][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_3][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #0031b4;
}
[data-sidebarbg=color_3][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_3][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_3][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_3][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_3][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_4] .dlabnav {
  background-color: #4527a0;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_4] .dlabnav .menu-tabs li a.active {
  color: #4527a0;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_4] .dlabnav .add-menu-sidebar {
  background: #4527a0;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_4] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_4] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_4] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_4] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_4] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_4] .dlabnav .metismenu ul {
  background-color: #4527a0 !important;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_4] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_4] .dlabnav .metismenu ul > li {
  border-color: #5731c9;
}

[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_4] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_4] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_4] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_4] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_4] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_4] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_4][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_4] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_4][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #5731c9;
}
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_4][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_4][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #5731c9;
}
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_4][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_4][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_4][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #5731c9;
}
[data-sidebarbg=color_4][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_4][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_4][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_4][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_4][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_5] .dlabnav {
  background-color: #c62828;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_5] .dlabnav .menu-tabs li a.active {
  color: #c62828;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_5] .dlabnav .add-menu-sidebar {
  background: #c62828;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_5] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_5] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_5] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_5] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_5] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_5] .dlabnav .metismenu ul {
  background-color: #c62828 !important;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_5] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_5] .dlabnav .metismenu ul > li {
  border-color: #da4747;
}

[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_5] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_5] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_5] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_5] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_5] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_5] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_5][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_5] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_5][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #da4747;
}
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_5][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_5][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #da4747;
}
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_5][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_5][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_5][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #da4747;
}
[data-sidebarbg=color_5][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_5][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_5][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_5][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_5][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_6] .dlabnav {
  background-color: #283593;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_6] .dlabnav .menu-tabs li a.active {
  color: #283593;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_6] .dlabnav .add-menu-sidebar {
  background: #283593;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_6] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_6] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_6] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_6] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_6] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_6] .dlabnav .metismenu ul {
  background-color: #283593 !important;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_6] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_6] .dlabnav .metismenu ul > li {
  border-color: #3343bb;
}

[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_6] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_6] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_6] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_6] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_6] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_6] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_6][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_6] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_6][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #3343bb;
}
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_6][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_6][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #3343bb;
}
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_6][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_6][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_6][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #3343bb;
}
[data-sidebarbg=color_6][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_6][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_6][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_6][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_6][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_7] .dlabnav {
  background-color: #7356f1;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_7] .dlabnav .menu-tabs li a.active {
  color: #7356f1;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_7] .dlabnav .add-menu-sidebar {
  background: #7356f1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_7] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_7] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_7] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_7] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_7] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_7] .dlabnav .metismenu ul {
  background-color: #7356f1 !important;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_7] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_7] .dlabnav .metismenu ul > li {
  border-color: #9a85f5;
}

[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_7] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_7] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_7] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_7] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_7] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_7] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_7][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_7] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_7][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #9a85f5;
}
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_7][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_7][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #9a85f5;
}
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_7][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_7][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_7][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #9a85f5;
}
[data-sidebarbg=color_7][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_7][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_7][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_7][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_7][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_8] .dlabnav {
  background-color: #3695eb;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_8] .dlabnav .menu-tabs li a.active {
  color: #3695eb;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_8] .dlabnav .add-menu-sidebar {
  background: #3695eb;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_8] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_8] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_8] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_8] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_8] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_8] .dlabnav .metismenu ul {
  background-color: #3695eb !important;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_8] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_8] .dlabnav .metismenu ul > li {
  border-color: #64adf0;
}

[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_8] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_8] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_8] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_8] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_8] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_8] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_8][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_8] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_8][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #64adf0;
}
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_8][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_8][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #64adf0;
}
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_8][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_8][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_8][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #64adf0;
}
[data-sidebarbg=color_8][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_8][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_8][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_8][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_8][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_9] .dlabnav {
  background-color: #00838f;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_9] .dlabnav .menu-tabs li a.active {
  color: #00838f;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_9] .dlabnav .add-menu-sidebar {
  background: #00838f;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_9] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_9] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_9] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_9] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_9] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_9] .dlabnav .metismenu ul {
  background-color: #00838f !important;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_9] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_9] .dlabnav .metismenu ul > li {
  border-color: #00b2c2;
}

[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_9] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_9] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_9] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_9] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_9] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_9] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_9][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_9] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_9][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #00b2c2;
}
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_9][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_9][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #00b2c2;
}
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_9][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_9][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_9][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #00b2c2;
}
[data-sidebarbg=color_9][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_9][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_9][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_9][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_9][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_10] .dlabnav {
  background-color: #ff8f16;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_10] .dlabnav .menu-tabs li a.active {
  color: #ff8f16;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_10] .dlabnav .add-menu-sidebar {
  background: #ff8f16;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_10] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_10] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_10] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_10] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_10] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_10] .dlabnav .metismenu ul {
  background-color: #ff8f16 !important;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_10] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_10] .dlabnav .metismenu ul > li {
  border-color: #ffa849;
}

[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_10] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_10] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_10] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_10] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_10] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_10] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_10][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_10] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_10][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #ffa849;
}
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_10][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_10][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #ffa849;
}
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_10][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_10][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_10][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #ffa849;
}
[data-sidebarbg=color_10][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_10][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_10][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_10][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_10][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_11] .dlabnav {
  background-color: #6673fd;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_11] .dlabnav .menu-tabs li a.active {
  color: #6673fd;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_11] .dlabnav .add-menu-sidebar {
  background: #6673fd;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_11] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_11] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_11] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_11] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_11] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_11] .dlabnav .metismenu ul {
  background-color: #6673fd !important;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_11] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_11] .dlabnav .metismenu ul > li {
  border-color: #98a1fe;
}

[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_11] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_11] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_11] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_11] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_11] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_11] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_11][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_11] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_11][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #98a1fe;
}
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_11][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_11][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #98a1fe;
}
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_11][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_11][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_11][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #98a1fe;
}
[data-sidebarbg=color_11][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_11][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_11][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_11][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_11][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_12] .dlabnav {
  background-color: #558b2f;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_12] .dlabnav .menu-tabs li a.active {
  color: #558b2f;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_12] .dlabnav .add-menu-sidebar {
  background: #558b2f;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_12] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_12] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_12] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_12] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_12] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_12] .dlabnav .metismenu ul {
  background-color: #558b2f !important;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_12] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_12] .dlabnav .metismenu ul > li {
  border-color: #6cb13c;
}

[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_12] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_12] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_12] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_12] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_12] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_12] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_12][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_12] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_12][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #6cb13c;
}
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_12][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_12][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #6cb13c;
}
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_12][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_12][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_12][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #6cb13c;
}
[data-sidebarbg=color_12][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_12][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_12][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_12][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_12][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_13] .dlabnav {
  background-color: #2a2a2a;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_13] .dlabnav .menu-tabs li a.active {
  color: #2a2a2a;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_13] .dlabnav .add-menu-sidebar {
  background: #2a2a2a;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_13] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_13] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_13] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_13] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_13] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_13] .dlabnav .metismenu ul {
  background-color: #2a2a2a !important;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_13] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_13] .dlabnav .metismenu ul > li {
  border-color: #444444;
}

[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_13] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_13] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_13] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_13] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_13] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_13] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_13][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_13] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_13][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #444444;
}
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_13][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_13][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #444444;
}
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_13][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_13][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_13][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #444444;
}
[data-sidebarbg=color_13][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_13][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_13][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_13][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_13][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_14] .dlabnav {
  background-color: #1367c8;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_14] .dlabnav .menu-tabs li a.active {
  color: #1367c8;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_14] .dlabnav .add-menu-sidebar {
  background: #1367c8;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_14] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_14] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_14] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_14] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_14] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_14] .dlabnav .metismenu ul {
  background-color: #1367c8 !important;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_14] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_14] .dlabnav .metismenu ul > li {
  border-color: #2480ea;
}

[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_14] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_14] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_14] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_14] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_14] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_14] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_14][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_14] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_14][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #2480ea;
}
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_14][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_14][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #2480ea;
}
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_14][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_14][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_14][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #2480ea;
}
[data-sidebarbg=color_14][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_14][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_14][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_14][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_14][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav,
[data-sidebarbg=color_15] .dlabnav {
  background-color: #FF005C;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .menu-tabs li a.active,
[data-sidebarbg=color_15] .dlabnav .menu-tabs li a.active {
  color: #FF005C;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .add-menu-sidebar,
[data-sidebarbg=color_15] .dlabnav .add-menu-sidebar {
  background: #FF005C;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu .nav-label,
[data-sidebarbg=color_15] .dlabnav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu li a,
[data-sidebarbg=color_15] .dlabnav .metismenu li a {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu li a i,
[data-sidebarbg=color_15] .dlabnav .metismenu li a i {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu li a:before,
[data-sidebarbg=color_15] .dlabnav .metismenu li a:before {
  background-color: #fff;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu li.mm-active a,
[data-sidebarbg=color_15] .dlabnav .metismenu li.mm-active a {
  color: #fff;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_15] .dlabnav .metismenu ul {
  background-color: #FF005C !important;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li > a,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li > a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li > a svg,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li > a g [fill],
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:hover > a,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:focus > a,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li.mm-active > a {
  color: #fff;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a g [fill], [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a g [fill], [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a g [fill],
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:hover > a g [fill],
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:focus > a g [fill],
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li.mm-active > a g [fill] {
  fill: #fff;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:hover > a svg, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li:focus > a svg, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li.mm-active > a svg,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:hover > a svg,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li:focus > a svg,
[data-sidebarbg=color_15] .dlabnav .metismenu ul > li.mm-active > a svg {
  color: #fff;
}
[data-layout=horizontal][data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul > li, [data-sidebarbg=color_15] .dlabnav .metismenu ul > li {
  border-color: #ff337d;
}

[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul a,
[data-sidebarbg=color_15] .dlabnav .metismenu ul a {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul a.mm-active,
[data-sidebarbg=color_15] .dlabnav .metismenu ul a:hover,
[data-sidebarbg=color_15] .dlabnav .metismenu ul a:focus,
[data-sidebarbg=color_15] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul,
[data-sidebarbg=color_15] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .metismenu ul:after,
[data-sidebarbg=color_15] .dlabnav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_15][data-theme-version=dark] .dlabnav .copyright,
[data-sidebarbg=color_15] .dlabnav .copyright {
  color: rgba(255, 255, 255, 0.7);
}
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul,
[data-sidebarbg=color_15][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu li > ul {
  background-color: #ff337d;
}
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_15][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_15][data-sidebar-style=mini][data-layout=vertical] .dlabnav .metismenu > li:hover > a {
  background-color: #ff337d;
}
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a,
[data-sidebarbg=color_15][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu li > ul,
[data-sidebarbg=color_15][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li.mm-active > a,
[data-sidebarbg=color_15][data-sidebar-style=full][data-layout=vertical] .menu-toggle .dlabnav .metismenu > li:hover > a {
  background-color: #ff337d;
}
[data-sidebarbg=color_15][data-theme-version=dark][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul,
[data-sidebarbg=color_15][data-sidebar-style=icon-hover][data-layout=vertical] .dlabnav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}
[data-sidebarbg=color_15][data-theme-version=dark][data-layout=horizontal] .dlabnav .metismenu > li,
[data-sidebarbg=color_15][data-layout=horizontal] .dlabnav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sidebarbg=color_15][data-sidebar-style=modern] .dlabnav .metismenu > li.mm-active > a {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-headerbg=transparent] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg=color_1] .nav-header .hamburger.is-active .line,
[data-headerbg=color_1] .nav-header .hamburger .line {
  background: var(--primary);
}

@media (min-width: 992px) {
  [data-container=boxed] {
    background-color: var(--primary);
  }
  [data-container=boxed] #main-wrapper {
    max-width: 1199px;
    margin: 0 auto;
    background-color: #f4f4f4;
  }
  [direction=rtl][data-container=boxed] #main-wrapper {
    text-align: right;
  }
  [data-theme-version=dark][data-container=boxed] .footer {
    background-color: #1e2746;
  }
}
[data-theme-version=dark][data-layout=horizontal][data-container=boxed] .content-body {
  border-left: 1px solid #333a54;
}

[data-sidebar-style=compact][data-container=boxed] .content-body {
  margin-left: 11.2rem;
}

[data-theme-version=dark][data-container=boxed][data-sidebar-style=overlay] .content-body {
  border-left: 1px solid #333a54;
}

@media only screen and (min-width: 1350px) {
  [data-layout=vertical][data-sidebar-style=overlay][data-container=boxed][data-header-position=fixed] .header {
    width: 1199px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1349px) {
  [data-layout=vertical][data-sidebar-style=overlay][data-container=boxed][data-header-position=fixed] .header {
    width: 1199px;
  }
}
[data-header-position=fixed][data-layout=horizontal][data-container=boxed] .dlabnav {
  max-width: 1199px;
}

[data-layout=horizontal][data-container=boxed][data-header-position=fixed] .header {
  width: 1199px;
}

[data-layout=horizontal][data-container=boxed][data-header-position=fixed][data-sidebar-style=mini] .header {
  width: 1199px;
  padding-left: 12.5rem;
}

[data-container=boxed][data-sidebar-style=mini] .nav-header {
  width: 5rem;
}

[data-container=boxed] .header {
  padding-left: 11.3rem;
  width: 74.9375rem;
}
[data-container=boxed] .nav-header {
  width: 12.5rem;
}
[data-container=boxed] .nav-header .brand-logo {
  padding-left: 30px;
  padding-right: 30px;
}
[data-container=boxed] .metismenu.fixed {
  left: auto;
  max-width: 1199px;
}
[data-container=boxed] .page-titles {
  margin-bottom: 1rem;
  padding: 15px 15px;
  margin-left: 0px;
  margin-right: 0px;
}
[data-theme-version=dark][data-container=boxed] .content-body {
  background: #181f39;
  border-right: 1px solid #333a54;
}

[data-container=boxed] .content-body .container-fluid, [data-container=boxed] .content-body .container-sm, [data-container=boxed] .content-body .container-md, [data-container=boxed] .content-body .container-lg, [data-container=boxed] .content-body .container-xl, [data-container=boxed] .content-body .container-xxl {
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

[data-layout=vertical][data-container=boxed][data-sidebar-position=fixed][data-header-position=static][data-sidebar-style=overlay] .nav-header {
  position: absolute;
}
[data-layout=vertical][data-container=boxed][data-sidebar-position=fixed][data-header-position=static][data-sidebar-style=overlay] .menu-toggle .dlabnav {
  position: absolute;
}

[data-container=boxed][data-sidebar-position=fixed][data-layout=horizontal] .dlabnav.fixed {
  left: auto;
  max-width: 1199px;
}

@media (min-width: 992px) {
  [data-container=wide-boxed] #main-wrapper {
    max-width: 1480px;
    margin: 0 auto;
  }
}
[data-theme-version=dark][data-container=wide-boxed] .footer {
  background-color: #1e2746;
}

[data-header-position=fixed][data-container=wide-boxed] .header {
  width: 92.5rem;
}

[data-theme-version=dark][data-container=wide-boxed][data-sidebar-style=overlay] .content-body {
  border-left: 1px solid #333a54;
}

@media (min-width: 992px) {
  [data-container=wide-boxed][data-sidebar-style=full] .header {
    width: 100%;
  }
  [data-container=wide-boxed][data-sidebar-style=full] .menu-toggle .header {
    width: 100%;
  }
  [data-header-position=fixed][data-container=wide-boxed][data-sidebar-style=full] .header {
    max-width: 1480px;
  }
  [data-header-position=fixed][data-container=wide-boxed][data-sidebar-style=full] .menu-toggle .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container=wide-boxed][data-sidebar-style=mini] .header {
    width: 100%;
  }
  [data-header-position=fixed][data-container=wide-boxed][data-sidebar-style=mini] .header {
    max-width: 1480px;
  }
  [data-header-position=fixed][data-layout=horizontal][data-container=wide-boxed][data-sidebar-style=mini] .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container=wide-boxed][data-sidebar-style=compact] .header {
    width: 100%;
  }
  [data-header-position=fixed][data-container=wide-boxed][data-sidebar-style=compact] .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container=wide-boxed][data-sidebar-style=overlay] .header {
    width: 100%;
  }
  [data-header-position=fixed][data-container=wide-boxed][data-sidebar-style=overlay] .header {
    max-width: 1480px;
  }
}

[data-layout=horizontal][data-container=wide-boxed] .header {
  width: 92.5rem;
}
[data-sidebar-style=full][data-header-position=fixed][data-layout=horizontal][data-container=wide-boxed] .header {
  max-width: 1480px;
}

[data-sidebar-style=mini][data-layout=horizontal][data-container=wide-boxed] .header {
  width: 100%;
}

[data-theme-version=dark][data-layout=horizontal][data-container=wide-boxed] .content-body {
  border-left: 1px solid #333a54;
}

[data-layout=horizontal][data-container=wide-boxed][data-sidebar-position=fixed] .dlabnav.fixed {
  max-width: 1480px;
  transition: none;
}
[data-layout=horizontal][data-container=wide-boxed][data-sidebar-position=fixed][direction=rtl] .dlabnav.fixed {
  right: 5%;
}

[data-layout=horizontal][data-container=wide-boxed][data-sidebar-position=fixed][data-header-position=fixed] .dlabnav {
  max-width: 1480px;
  left: unset;
}
[data-layout=horizontal][data-container=wide-boxed][data-sidebar-position=fixed][data-header-position=fixed][direction=rtl] .dlabnav {
  right: 5%;
}

[data-layout=vertical][data-container=wide-boxed] .dlabnav {
  position: absolute;
}

[data-sidebar-style=compact][data-container=wide-boxed] .content-body {
  margin-left: 11.1rem;
}

[data-primary=color_2] {
  --primary: #143b64;
  --secondary: #5C3799;
  --primary-hover: #0c223a;
  --primary-dark: black;
  --rgba-primary-1: rgba(20, 59, 100, 0.1);
  --rgba-primary-2: rgba(20, 59, 100, 0.2);
  --rgba-primary-3: rgba(20, 59, 100, 0.3);
  --rgba-primary-4: rgba(20, 59, 100, 0.4);
  --rgba-primary-5: rgba(20, 59, 100, 0.5);
  --rgba-primary-6: rgba(20, 59, 100, 0.6);
  --rgba-primary-7: rgba(20, 59, 100, 0.7);
  --rgba-primary-8: rgba(20, 59, 100, 0.8);
  --rgba-primary-9: rgba(20, 59, 100, 0.9);
}

[data-primary=color_3] {
  --primary: #002381;
  --secondary: #5C3799;
  --primary-hover: #00154e;
  --primary-dark: black;
  --rgba-primary-1: rgba(0, 35, 129, 0.1);
  --rgba-primary-2: rgba(0, 35, 129, 0.2);
  --rgba-primary-3: rgba(0, 35, 129, 0.3);
  --rgba-primary-4: rgba(0, 35, 129, 0.4);
  --rgba-primary-5: rgba(0, 35, 129, 0.5);
  --rgba-primary-6: rgba(0, 35, 129, 0.6);
  --rgba-primary-7: rgba(0, 35, 129, 0.7);
  --rgba-primary-8: rgba(0, 35, 129, 0.8);
  --rgba-primary-9: rgba(0, 35, 129, 0.9);
}

[data-primary=color_4] {
  --primary: #4527a0;
  --secondary: #5C3799;
  --primary-hover: #331d77;
  --primary-dark: #100925;
  --rgba-primary-1: rgba(69, 39, 160, 0.1);
  --rgba-primary-2: rgba(69, 39, 160, 0.2);
  --rgba-primary-3: rgba(69, 39, 160, 0.3);
  --rgba-primary-4: rgba(69, 39, 160, 0.4);
  --rgba-primary-5: rgba(69, 39, 160, 0.5);
  --rgba-primary-6: rgba(69, 39, 160, 0.6);
  --rgba-primary-7: rgba(69, 39, 160, 0.7);
  --rgba-primary-8: rgba(69, 39, 160, 0.8);
  --rgba-primary-9: rgba(69, 39, 160, 0.9);
}

[data-primary=color_5] {
  --primary: #c62828;
  --secondary: #5C3799;
  --primary-hover: #9c1f1f;
  --primary-dark: #470e0e;
  --rgba-primary-1: rgba(198, 40, 40, 0.1);
  --rgba-primary-2: rgba(198, 40, 40, 0.2);
  --rgba-primary-3: rgba(198, 40, 40, 0.3);
  --rgba-primary-4: rgba(198, 40, 40, 0.4);
  --rgba-primary-5: rgba(198, 40, 40, 0.5);
  --rgba-primary-6: rgba(198, 40, 40, 0.6);
  --rgba-primary-7: rgba(198, 40, 40, 0.7);
  --rgba-primary-8: rgba(198, 40, 40, 0.8);
  --rgba-primary-9: rgba(198, 40, 40, 0.9);
}

[data-primary=color_6] {
  --primary: #283593;
  --secondary: #5C3799;
  --primary-hover: #1d276b;
  --primary-dark: #070a1b;
  --rgba-primary-1: rgba(40, 53, 147, 0.1);
  --rgba-primary-2: rgba(40, 53, 147, 0.2);
  --rgba-primary-3: rgba(40, 53, 147, 0.3);
  --rgba-primary-4: rgba(40, 53, 147, 0.4);
  --rgba-primary-5: rgba(40, 53, 147, 0.5);
  --rgba-primary-6: rgba(40, 53, 147, 0.6);
  --rgba-primary-7: rgba(40, 53, 147, 0.7);
  --rgba-primary-8: rgba(40, 53, 147, 0.8);
  --rgba-primary-9: rgba(40, 53, 147, 0.9);
}

[data-primary=color_7] {
  --primary: #7356f1;
  --secondary: #5C3799;
  --primary-hover: #4c27ed;
  --primary-dark: #290da1;
  --rgba-primary-1: rgba(115, 86, 241, 0.1);
  --rgba-primary-2: rgba(115, 86, 241, 0.2);
  --rgba-primary-3: rgba(115, 86, 241, 0.3);
  --rgba-primary-4: rgba(115, 86, 241, 0.4);
  --rgba-primary-5: rgba(115, 86, 241, 0.5);
  --rgba-primary-6: rgba(115, 86, 241, 0.6);
  --rgba-primary-7: rgba(115, 86, 241, 0.7);
  --rgba-primary-8: rgba(115, 86, 241, 0.8);
  --rgba-primary-9: rgba(115, 86, 241, 0.9);
}

[data-primary=color_8] {
  --primary: #3695eb;
  --secondary: #5C3799;
  --primary-hover: #167cd8;
  --primary-dark: #0c477c;
  --rgba-primary-1: rgba(54, 149, 235, 0.1);
  --rgba-primary-2: rgba(54, 149, 235, 0.2);
  --rgba-primary-3: rgba(54, 149, 235, 0.3);
  --rgba-primary-4: rgba(54, 149, 235, 0.4);
  --rgba-primary-5: rgba(54, 149, 235, 0.5);
  --rgba-primary-6: rgba(54, 149, 235, 0.6);
  --rgba-primary-7: rgba(54, 149, 235, 0.7);
  --rgba-primary-8: rgba(54, 149, 235, 0.8);
  --rgba-primary-9: rgba(54, 149, 235, 0.9);
}

[data-primary=color_9] {
  --primary: #00838f;
  --secondary: #5C3799;
  --primary-hover: #00545c;
  --primary-dark: black;
  --rgba-primary-1: rgba(0, 131, 143, 0.1);
  --rgba-primary-2: rgba(0, 131, 143, 0.2);
  --rgba-primary-3: rgba(0, 131, 143, 0.3);
  --rgba-primary-4: rgba(0, 131, 143, 0.4);
  --rgba-primary-5: rgba(0, 131, 143, 0.5);
  --rgba-primary-6: rgba(0, 131, 143, 0.6);
  --rgba-primary-7: rgba(0, 131, 143, 0.7);
  --rgba-primary-8: rgba(0, 131, 143, 0.8);
  --rgba-primary-9: rgba(0, 131, 143, 0.9);
}

[data-primary=color_10] {
  --primary: #ff8f16;
  --secondary: #5C3799;
  --primary-hover: #e27500;
  --primary-dark: #7c4000;
  --rgba-primary-1: rgba(255, 143, 22, 0.1);
  --rgba-primary-2: rgba(255, 143, 22, 0.2);
  --rgba-primary-3: rgba(255, 143, 22, 0.3);
  --rgba-primary-4: rgba(255, 143, 22, 0.4);
  --rgba-primary-5: rgba(255, 143, 22, 0.5);
  --rgba-primary-6: rgba(255, 143, 22, 0.6);
  --rgba-primary-7: rgba(255, 143, 22, 0.7);
  --rgba-primary-8: rgba(255, 143, 22, 0.8);
  --rgba-primary-9: rgba(255, 143, 22, 0.9);
}

[data-primary=color_11] {
  --primary: #6673fd;
  --secondary: #5C3799;
  --primary-hover: #3445fc;
  --primary-dark: #0314c7;
  --rgba-primary-1: rgba(102, 115, 253, 0.1);
  --rgba-primary-2: rgba(102, 115, 253, 0.2);
  --rgba-primary-3: rgba(102, 115, 253, 0.3);
  --rgba-primary-4: rgba(102, 115, 253, 0.4);
  --rgba-primary-5: rgba(102, 115, 253, 0.5);
  --rgba-primary-6: rgba(102, 115, 253, 0.6);
  --rgba-primary-7: rgba(102, 115, 253, 0.7);
  --rgba-primary-8: rgba(102, 115, 253, 0.8);
  --rgba-primary-9: rgba(102, 115, 253, 0.9);
}

[data-primary=color_12] {
  --primary: #558b2f;
  --secondary: #5C3799;
  --primary-hover: #3e6522;
  --primary-dark: #0f1908;
  --rgba-primary-1: rgba(85, 139, 47, 0.1);
  --rgba-primary-2: rgba(85, 139, 47, 0.2);
  --rgba-primary-3: rgba(85, 139, 47, 0.3);
  --rgba-primary-4: rgba(85, 139, 47, 0.4);
  --rgba-primary-5: rgba(85, 139, 47, 0.5);
  --rgba-primary-6: rgba(85, 139, 47, 0.6);
  --rgba-primary-7: rgba(85, 139, 47, 0.7);
  --rgba-primary-8: rgba(85, 139, 47, 0.8);
  --rgba-primary-9: rgba(85, 139, 47, 0.9);
}

[data-primary=color_13] {
  --primary: #2a2a2a;
  --secondary: #5C3799;
  --primary-hover: #111111;
  --primary-dark: black;
  --rgba-primary-1: rgba(42, 42, 42, 0.1);
  --rgba-primary-2: rgba(42, 42, 42, 0.2);
  --rgba-primary-3: rgba(42, 42, 42, 0.3);
  --rgba-primary-4: rgba(42, 42, 42, 0.4);
  --rgba-primary-5: rgba(42, 42, 42, 0.5);
  --rgba-primary-6: rgba(42, 42, 42, 0.6);
  --rgba-primary-7: rgba(42, 42, 42, 0.7);
  --rgba-primary-8: rgba(42, 42, 42, 0.8);
  --rgba-primary-9: rgba(42, 42, 42, 0.9);
}

[data-primary=color_14] {
  --primary: #1367c8;
  --secondary: #5C3799;
  --primary-hover: #0f4f99;
  --primary-dark: #061f3c;
  --rgba-primary-1: rgba(19, 103, 200, 0.1);
  --rgba-primary-2: rgba(19, 103, 200, 0.2);
  --rgba-primary-3: rgba(19, 103, 200, 0.3);
  --rgba-primary-4: rgba(19, 103, 200, 0.4);
  --rgba-primary-5: rgba(19, 103, 200, 0.5);
  --rgba-primary-6: rgba(19, 103, 200, 0.6);
  --rgba-primary-7: rgba(19, 103, 200, 0.7);
  --rgba-primary-8: rgba(19, 103, 200, 0.8);
  --rgba-primary-9: rgba(19, 103, 200, 0.9);
}

[data-primary=color_15] {
  --primary: #FF005C;
  --secondary: #5C3799;
  --primary-hover: #cc004a;
  --primary-dark: #660025;
  --rgba-primary-1: rgba(255, 0, 92, 0.1);
  --rgba-primary-2: rgba(255, 0, 92, 0.2);
  --rgba-primary-3: rgba(255, 0, 92, 0.3);
  --rgba-primary-4: rgba(255, 0, 92, 0.4);
  --rgba-primary-5: rgba(255, 0, 92, 0.5);
  --rgba-primary-6: rgba(255, 0, 92, 0.6);
  --rgba-primary-7: rgba(255, 0, 92, 0.7);
  --rgba-primary-8: rgba(255, 0, 92, 0.8);
  --rgba-primary-9: rgba(255, 0, 92, 0.9);
}

[data-theme-version=dark] {
  background: #181f39;
  color: #828690;
}
[data-theme-version=dark] h1, [data-theme-version=dark] .h1,
[data-theme-version=dark] h2,
[data-theme-version=dark] .h2,
[data-theme-version=dark] h3,
[data-theme-version=dark] .h3,
[data-theme-version=dark] h4,
[data-theme-version=dark] .h4,
[data-theme-version=dark] h5,
[data-theme-version=dark] .h5,
[data-theme-version=dark] h6,
[data-theme-version=dark] .h6 {
  color: #fff !important;
}
[data-theme-version=dark] a.link {
  color: #ddd;
}
[data-theme-version=dark] a.link:focus,
[data-theme-version=dark] a.link:hover {
  color: #002381;
}
[data-theme-version=dark] a:hover {
  color: #fff;
}
[data-theme-version=dark] .border-right {
  border-right: 1px solid #333a54 !important;
}
[data-theme-version=dark] .border-left {
  border-left: 1px solid #333a54 !important;
}
[data-theme-version=dark] .border-top {
  border-top: 1px solid #333a54 !important;
}
[data-theme-version=dark] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}
[data-theme-version=dark] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version=dark] .dropdown-menu {
  background-color: #181f39;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}
[data-theme-version=dark] .dropdown-menu .dropdown-item.selected, [data-theme-version=dark] .dropdown-menu .dropdown-item.selected.active, [data-theme-version=dark] .dropdown-menu .dropdown-item.active, [data-theme-version=dark] .dropdown-menu .dropdown-item:hover, [data-theme-version=dark] .dropdown-menu .dropdown-item:focus {
  background-color: #1e2746;
  color: #fff;
}
[data-theme-version=dark] .dropdown svg path {
  stroke: #fff;
}
[data-theme-version=dark] .nav-tabs.card-body-tabs {
  background-color: transparent;
}
[data-theme-version=dark] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff;
}
[data-theme-version=dark] .form-control:hover {
  border-color: #333a54;
}
[data-theme-version=dark] .author-profile .info-list li,
[data-theme-version=dark] .profile-card .card-footer,
[data-theme-version=dark] .author-profile .upload-link {
  border-color: #333a54;
}
[data-theme-version=dark] .author-profile .info-list li a,
[data-theme-version=dark] .author-profile .author-info span,
[data-theme-version=dark] .author-profile .info-list li span {
  color: rgba(255, 255, 255, 0.6);
}
[data-theme-version=dark] .author-profile .card-footer .form-control {
  border-color: #333a54;
  background: #181f39 !important;
}
[data-theme-version=dark] .sidebar-right .form-control {
  background-color: #fff;
  border-color: #EEEEEE;
}
[data-theme-version=dark] .sidebar-right .form-control .btn {
  border-color: #EEEEEE !important;
  color: #000 !important;
}
[data-theme-version=dark] .sidebar-right .form-control .dropdown-menu {
  background-color: #fff;
  border-color: #333333;
}
[data-theme-version=dark] .sidebar-right .form-control .dropdown-item.active.selected {
  background-color: #EEEEEE;
  border-color: #333333;
}
[data-theme-version=dark] .sidebar-right .form-control .dropdown-item:hover {
  background-color: #EEEEEE;
  border-color: #333333;
  color: #828690;
}
[data-theme-version=dark] .card {
  background-color: #1e2746;
  box-shadow: none;
  border-color: #181f39;
}
[data-theme-version=dark] .btn-outline-dark:hover {
  background-color: #181f39;
  border-color: #181f39;
}
[data-theme-version=dark] .tdl-holder input[type=text],
[data-theme-version=dark] .tdl-holder li {
  background-color: #1e2746;
  border-color: #333a54;
}
[data-theme-version=dark] .tdl-holder input[type=text]::placeholder {
  color: #828690;
}
[data-theme-version=dark] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff;
}
[data-theme-version=dark] .text-muted {
  color: #828690 !important;
}
[data-theme-version=dark] .modal-content {
  background: #1e2746;
}
[data-theme-version=dark] .modal-footer,
[data-theme-version=dark] .modal-header {
  border-color: #333a54;
}
[data-theme-version=dark] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}
[data-theme-version=dark] .close:hover {
  color: #fff;
}
[data-theme-version=dark] .table strong {
  color: #fff;
}
[data-theme-version=dark] .text-dark {
  color: #fff !important;
}
[data-theme-version=dark] .star-rating .product-review,
[data-theme-version=dark] .new-arrival-content .item {
  color: #fff;
}
[data-theme-version=dark] .custom-dropdown .dropdown-menu {
  border-color: #333a54;
}
[data-theme-version=dark] .widget-stat .media > span {
  background: #333a54;
  border-color: #333a54;
  color: #fff;
}
[data-theme-version=dark] .plus-minus-input .custom-btn {
  background: #181f39;
  border-color: #333a54;
}
[data-theme-version=dark] .dropdown-divider,
[data-theme-version=dark] .size-filter ul li {
  border-color: #333a54;
}
[data-theme-version=dark] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #181f39;
}
[data-theme-version=dark] .nav-tabs {
  border-color: #333a54;
  background-color: #181f39;
}
[data-theme-version=dark] .default-tab .nav-tabs {
  background-color: transparent;
}
[data-theme-version=dark] .custom-tab-1 .nav-tabs {
  background-color: transparent;
}
[data-theme-version=dark] .tab-width-icon .nav-tabs {
  background-color: transparent;
}
[data-theme-version=dark] .mail-list .list-group-item.active i {
  color: #fff;
}
[data-theme-version=dark] hr {
  border-color: #333a54;
}
[data-theme-version=dark] .grid-col {
  background: #181f39;
  color: #fff;
}
[data-theme-version=dark] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none;
}
[data-theme-version=dark] .noUi-target .noUi-connects {
  background: #333a54;
}
[data-theme-version=dark] .noUi-marker-large,
[data-theme-version=dark] .noUi-marker {
  background: #333a54;
}
[data-theme-version=dark] .input-group-text {
  background: #1e2746;
  color: #333333;
  border-color: #333a54;
}
[data-theme-version=dark] .note-editor.note-frame {
  border-color: #333a54;
}
[data-theme-version=dark] .note-editor.note-frame .btn {
  color: #fff;
}
[data-theme-version=dark] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel {
  border-color: #333a54;
}
[data-theme-version=dark] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333a54;
}
[data-theme-version=dark] #user-activity .nav-tabs .nav-link {
  border-color: #333a54;
}
[data-theme-version=dark] .list-group-item-action {
  color: #333333;
}
[data-theme-version=dark] .list-group-item-action:focus,
[data-theme-version=dark] .list-group-item-action:hover,
[data-theme-version=dark] .list-group-item-action:focus {
  background-color: #181f39;
  border-color: #181f39;
}
[data-theme-version=dark] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}
[data-theme-version=dark] .list-group-item.active:focus, [data-theme-version=dark] .list-group-item.active:hover, [data-theme-version=dark] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
[data-theme-version=dark] .swal2-popup {
  background: #1e2746;
}
[data-theme-version=dark] .daterangepicker {
  background-color: #1e2746;
  border-color: #333a54;
}
[data-theme-version=dark] .dataTables_wrapper .dataTables_filter {
  color: #fff;
}
[data-theme-version=dark] .dataTables_wrapper .dataTables_info {
  color: #fff;
}
[data-theme-version=dark] td .btn-link svg g circle {
  fill: #fff;
}
[data-theme-version=dark] #radialChart svg g circle {
  fill: #1e2746;
}
[data-theme-version=dark] #radialChart svg g text {
  fill: #ffffff;
}

[data-theme-version=dark] .header {
  background-color: #1e2746;
  border-color: #333a54;
}
[data-theme-version=dark] .header-left input {
  background: #181f39 !important;
  border-color: transparent;
  color: #fff !important;
}
[data-theme-version=dark] .header-left input:focus {
  box-shadow: none;
  border-color: #002381;
}
[data-theme-version=dark] .header-left input::placeholder {
  color: #fff;
}
[data-theme-version=dark] .header-left .search_icon {
  background: #181f39 !important;
}
[data-theme-version=dark] .header-left .search_icon i {
  color: #fff;
}
[data-theme-version=dark] .header-left .dashboard_bar {
  color: #fff;
}
[data-theme-version=dark] .header-right .right-sidebar a {
  color: #002381;
  border-color: #333a54;
}
[data-theme-version=dark] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff;
}
[data-theme-version=dark] .header-right .notification_dropdown .nav-link {
  background: #1e2746;
}
[data-theme-version=dark] .header-right .dropdown .nav-link {
  color: #fff;
  border-color: #333a54;
}
[data-theme-version=dark] .header-right .dropdown .nav-link svg path {
  fill: #fff;
}
[data-theme-version=dark] .header-right .dropdown .nav-link:hover {
  color: #fff;
}
[data-theme-version=dark] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version=dark] .nav-header,
[data-theme-version=dark] .nav-control {
  background-color: #1e2746;
}
[data-theme-version=dark] .nav-control {
  color: #fff;
}
[data-theme-version=dark] .brand-logo {
  color: #fff;
  border-color: #333a54;
}
[data-theme-version=dark] .brand-logo:hover {
  color: #fff;
}
[data-theme-version=dark] .brand-title svg g {
  fill: #fff;
}

[data-theme-version=dark][data-sidebar-style=mini] .dlabnav .metismenu li > ul {
  background-color: #1e2746;
}
[data-theme-version=dark] .fixed-content-box {
  background-color: #1e2746;
}
[data-theme-version=dark] .fixed-content-box .head-name {
  background: #1e2746;
  color: #fff;
  border-color: #333a54;
}
[data-theme-version=dark] .fixed-content-box + .header + .dlabnav {
  background-color: #181f39;
}
[data-theme-version=dark] .dlabnav {
  background-color: #1e2746;
  box-shadow: unset;
}
[data-theme-version=dark] .dlabnav .metismenu > li > a {
  color: #b3b3b3;
}
[data-theme-version=dark] .dlabnav .metismenu > li:hover > a, [data-theme-version=dark] .dlabnav .metismenu > li:focus > a, [data-theme-version=dark] .dlabnav .metismenu > li.mm-active > a {
  color: #fff;
}
[data-theme-version=dark] .dlabnav .metismenu > li:hover > a::after, [data-theme-version=dark] .dlabnav .metismenu > li:focus > a::after, [data-theme-version=dark] .dlabnav .metismenu > li.mm-active > a::after {
  border-color: #fff;
}
[data-theme-version=dark] .dlabnav .metismenu > li.mm-active ul ul {
  background-color: transparent;
}
[data-theme-version=dark] .dlabnav .metismenu ul:after {
  background-color: #333a54;
}
[data-theme-version=dark] .dlabnav .metismenu ul a:hover, [data-theme-version=dark] .dlabnav .metismenu ul a:focus, [data-theme-version=dark] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}
[data-theme-version=dark] .dlabnav .metismenu ul a:before {
  background-color: #fff;
}
[data-theme-version=dark] .dlabnav .metismenu a {
  color: #b3b3b3;
}
[data-theme-version=dark] .dlabnav .metismenu .has-arrow:after {
  border-color: #fff;
}
[data-theme-version=dark] .menu-toggle .dlabnav .metismenu ul.mm-collapse {
  background-color: #1e2746 !important;
}

[data-theme-version=dark] .footer {
  background-color: transparent;
}
[data-theme-version=dark] .footer .copyright {
  background-color: transparent;
}
[data-theme-version=dark] .footer .copyright p {
  color: #fff;
}

/* Components */
.accordion-primary .accordion__header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 15px 20px 0 var(--rgba-primary-1);
}
.accordion-primary .accordion__header.collapsed {
  background: var(--primary) -light;
  border-color: var(--primary) -light;
  color: #211c37;
  box-shadow: none;
}
[data-theme-version=dark] .accordion-primary .accordion__header.collapsed {
  background: var(--primary) -opacity;
  border-color: var(--primary) -opacity;
  color: #333333;
}

.accordion-primary-solid .accordion__header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 -10px 20px 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-primary-solid .accordion__header.collapsed {
  background: var(--primary) -light;
  border-color: var(--primary) -light;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[data-theme-version=dark] .accordion-primary-solid .accordion__header.collapsed {
  background: var(--primary) -opacity;
  border-color: var(--primary) -opacity;
  color: #333333;
}

.accordion-primary-solid .accordion__body {
  border: 2px solid var(--primary);
  border-top: none;
  box-shadow: 0 15px 20px 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion-danger .accordion__header {
  background: #fd5353;
  border-color: #fd5353;
  color: #fff;
  box-shadow: 0 15px 20px 0 rgba(253, 83, 83, 0.15);
}
.accordion-danger .accordion__header.collapsed {
  background: #ffeaea;
  border-color: #ffeaea;
  color: #211c37;
  box-shadow: none;
}
.accordion-danger-solid .accordion__header {
  background: #fd5353;
  border-color: #fd5353;
  color: #fff;
  box-shadow: 0 -10px 20px 0 rgba(253, 83, 83, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-danger-solid .accordion__header.collapsed {
  background: #ffeaea;
  border-color: #ffeaea;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[data-theme-version=dark] .accordion-danger-solid .accordion__header.collapsed {
  background: rgba(253, 83, 83, 0.15);
  border-color: rgba(253, 83, 83, 0.15);
  color: #333333;
}

.accordion-danger-solid .accordion__body {
  border: 2px solid #fd5353;
  border-top: none;
  box-shadow: 0 15px 20px 0 rgba(253, 83, 83, 0.15);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion__item {
  margin-bottom: 1.25rem;
}
.accordion__header {
  padding: 1rem 1.75rem;
  border: 1px solid #f0f1f5;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.25rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
[data-theme-version=dark] .accordion__header {
  color: #fff;
  border-color: #333a54;
}

.accordion__header--indicator {
  font-family: "feather";
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
}
[direction=rtl] .accordion__header--indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion__header--indicator.indicator_bordered {
  display: inline-block;
  width: 25px;
  text-align: center;
  height: 25px;
  border: 1px solid #f0f1f5;
  border-radius: 50%;
  line-height: 25px;
}
.accordion__header:not(.collapsed) .accordion__header--indicator::before {
  content: "\e99f";
}
.accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
  content: "\e99f";
}
.accordion__header.collapsed .accordion__header--indicator::before {
  content: "\e9bc";
}
.accordion__header.collapsed .accordion__header--indicator.style_two::before {
  content: "\e9bc";
}
.accordion__body--text {
  padding: 0.875rem 1.25rem;
}
.accordion-bordered .accordion__body {
  border: 1px solid #f0f1f5;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[data-theme-version=dark] .accordion-bordered .accordion__body {
  border-color: #333a54;
}

.accordion-bordered .accordion__header.collapsed {
  border-radius: 0.25rem;
}
.accordion-bordered .accordion__header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-no-gutter .accordion__item {
  margin-bottom: 0;
}
.accordion-no-gutter .accordion__item .accordion__header.collapsed {
  border-bottom: none;
}
.accordion-no-gutter .accordion__item:last-child .accordion__header {
  border-bottom: 1px solid #f0f1f5;
}
[data-theme-version=dark] .accordion-no-gutter .accordion__item:last-child .accordion__header {
  border-color: #333a54;
}

.accordion-no-gutter.accordion__bordered .accordion__item:not(:last-child) .accordion__body {
  border-bottom: none;
}
.accordion-left-indicator .accordion__header--text {
  padding-left: 2.5rem;
}
.accordion-left-indicator .accordion__header--indicator {
  right: auto;
  left: 1.5625rem;
}
.accordion-with-icon .accordion__header--text {
  padding-left: 2.5rem;
}
[direction=rtl] .accordion-with-icon .accordion__header--text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion__header--icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: "feather";
}
[direction=rtl] .accordion-with-icon .accordion__header--icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion__header--icon::before {
  content: "\e645";
}
.accordion-header-bg .accordion__header {
  background-color: #dedeed;
}
[data-theme-version=dark] .accordion-header-bg .accordion__header {
  background-color: #181f39;
  color: #fff;
}

.accordion-header-bg .accordion__header--primary {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}
[data-theme-version=dark] .accordion-header-bg .accordion__header--primary {
  background-color: var(--primary);
}

.accordion-header-bg .accordion__header--info {
  background-color: #002381;
  color: #fff;
  border-color: #002381;
}
[data-theme-version=dark] .accordion-header-bg .accordion__header--info {
  background-color: #002381;
}

.accordion-header-bg .accordion__header--success {
  background-color: #1EBA62;
  color: #fff;
  border-color: #1EBA62;
}
[data-theme-version=dark] .accordion-header-bg .accordion__header--success {
  background-color: #1EBA62;
}

.accordion-header-bg.accordion-no-gutter .accordion__header {
  border-color: transparent;
  border-radius: 0;
}
.accordion-header-bg.accordion-no-gutter .accordion__item:first-child .accordion__header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-header-bg.accordion-no-gutter .accordion__item:last-child .accordion__header {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion.accordion-no-gutter .accordion__header {
  border-radius: 0;
}
.accordion.accordion-no-gutter .accordion__header.collapsed {
  border-radius: 0;
}
.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0;
}
.accordion.accordion-no-gutter .accordion__item:first-child .accordion__header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion.accordion-no-gutter .accordion__item:last-child .accordion__header.collapsed {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion.accordion-no-gutter .accordion__item:last-child .accordion__body {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion-solid-bg .accordion__header {
  border-color: transparent;
  background-color: var(--primary) -light;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
[data-theme-version=dark] .accordion-solid-bg .accordion__header {
  background-color: #181f39;
}

.accordion-solid-bg .accordion__header.collapsed {
  border-radius: 0.25rem;
}
.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: var(--primary) -light;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
[data-theme-version=dark] .accordion-solid-bg .accordion__body {
  background-color: #181f39;
}

.accordion-active-header .accordion__header:not(.collapsed) {
  background-color: #002381;
  border-color: #002381;
  color: #fff;
}
.accordion-header-shadow .accordion__header {
  border: none;
  box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3);
}
.accordion-rounded-stylish .accordion__header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.accordion-rounded .accordion__header {
  border-radius: 0.25rem;
}
.accordion-gradient .accordion__header {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-gradient .accordion__header.collapsed {
  border-radius: 0.25rem;
}
.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.alert {
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
}
.alert p {
  line-height: 1.5;
}

.alert-rounded {
  border-radius: 30px;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.alert-primary {
  background: var(--primary) -light;
  border-color: var(--primary) -light;
  color: var(--primary);
}
[data-theme-version=dark] .alert-primary {
  background: var(--primary) -opacity;
  border-color: var(--primary) -opacity;
}

.alert-secondary {
  background: #d4c6ea;
  border-color: #d4c6ea;
  color: #5C3799;
}
[data-theme-version=dark] .alert-secondary {
  background: rgba(92, 55, 153, 0.5);
  border-color: rgba(92, 55, 153, 0.5);
  color: #333333;
}

.alert-success {
  background: #d0f7e1;
  border-color: #d0f7e1;
  color: #1EBA62;
}
[data-theme-version=dark] .alert-success {
  background: rgba(30, 186, 98, 0.1);
  border-color: rgba(30, 186, 98, 0.1);
}

.alert-warning {
  background: #fff9ef;
  border-color: #fff9ef;
  color: #FFAB2D;
}
[data-theme-version=dark] .alert-warning {
  background: rgba(255, 171, 45, 0.1);
  border-color: rgba(255, 171, 45, 0.1);
}

.alert-danger {
  background: #ffeaea;
  border-color: #ffeaea;
  color: #fd5353;
}
[data-theme-version=dark] .alert-danger {
  background: rgba(253, 83, 83, 0.15);
  border-color: rgba(253, 83, 83, 0.15);
}

.alert-info {
  background: #1b59ff;
  border-color: #1b59ff;
  color: #002381;
}
[data-theme-version=dark] .alert-info {
  background: rgba(0, 35, 129, 0.1);
  border-color: rgba(0, 35, 129, 0.1);
}

.alert-dark {
  background: #737373;
  border-color: #737373;
  color: #333333;
}
[data-theme-version=dark] .alert-dark {
  background: rgba(51, 51, 51, 0.35);
  border-color: rgba(51, 51, 51, 0.35);
  color: #333333;
}

.alert-light {
  background: #dedeed;
  border-color: #dedeed;
  color: #333333;
}
[data-theme-version=dark] .alert-light {
  background: rgba(222, 222, 237, 0.2);
  border-color: rgba(222, 222, 237, 0.2);
}

.alert-alt.alert-primary {
  border-left: 4px solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 4px solid #5C3799;
}

.alert-alt.alert-success {
  border-left: 4px solid #1EBA62;
}

.alert-alt.alert-warning {
  border-left: 4px solid #FFAB2D;
}

.alert-alt.alert-danger {
  border-left: 4px solid #fd5353;
}

.alert-alt.alert-info {
  border-left: 4px solid #002381;
}

.alert-alt.alert-dark {
  border-left: 4px solid #333333;
}

.alert-alt.alert-light {
  border-left: 4px solid #adadd2;
}

.alert-alt.alert-primary.solid {
  border-left: 4px solid #000002 !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 4px solid #24153b !important;
}

.alert-alt.alert-success.solid {
  border-left: 4px solid #0c4c28 !important;
}

.alert-alt.alert-warning.solid {
  border-left: 4px solid #ad6800 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 4px solid #ce0202 !important;
}

.alert-alt.alert-info.solid {
  border-left: 4px solid #000002 !important;
}

.alert-alt.alert-dark.solid {
  border-left: 4px solid black !important;
}

.alert-alt.alert-light.solid {
  border-left: 4px solid #8c8cc0 !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: #5C3799;
  color: #fff;
  border-color: #5C3799;
}

.alert.alert-success.solid {
  background: #1EBA62;
  color: #fff;
  border-color: #1EBA62;
}

.alert.alert-warning.solid {
  background: #FFAB2D;
  color: #fff;
  border-color: #FFAB2D;
}

.alert.alert-danger.solid {
  background: #fd5353;
  color: #fff;
  border-color: #fd5353;
}

.alert.alert-info.solid {
  background: #002381;
  color: #fff;
  border-color: #002381;
}

.alert.alert-dark.solid {
  background: #333333;
  color: #fff;
  border-color: #333333;
}

.alert.alert-light.solid {
  background: #dedeed;
  color: #333333;
  border-color: #dedeed;
}

.alert-right-icon > span i {
  font-size: 18px;
  margin-right: 5px;
}
.alert-right-icon .close i {
  font-size: 16px;
}

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #333333;
  border-color: #5C3799;
}

.alert.alert-outline-success {
  background: transparent;
  color: #1EBA62;
  border-color: #1EBA62;
}

.alert.alert-outline-info {
  background: transparent;
  color: #002381;
  border-color: #002381;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #FFAB2D;
  border-color: #FFAB2D;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #fd5353;
  border-color: #fd5353;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #333333;
  border-color: #333333;
}

.alert.alert-outline-light {
  background: transparent;
  color: #333333;
  border-color: #dedeed;
}

.alert-social {
  color: #fff;
}
.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}
.alert-social .alert-social-icon i {
  font-size: 42px;
}
.alert-social.facebook {
  background-color: #3b5998;
}
.alert-social.twitter {
  background-color: #1da1f2;
}
.alert-social.linkedin {
  background-color: #007bb6;
}
.alert-social.google-plus {
  background-color: #db4439;
}
.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}
.left-icon-big .alert-left-icon-big i {
  font-size: 35px;
  line-height: 1;
}
[direction=rtl] .left-icon-big .alert-left-icon-big,
[direction=rtl] .alert-social .alert-social-icon {
  margin-left: 0;
  margin-right: 0.9375rem;
}

.badge {
  line-height: 1.5;
  border-radius: 0.25rem;
  padding: 4px 10px;
  border: 1px solid transparent;
}

.badge-rounded {
  border-radius: 20px;
  padding: 3px 13px;
}

.badge-circle {
  border-radius: 100px;
}

.badge-secondary {
  color: #fff;
  background-color: #5C3799;
}
.badge-secondary:hover {
  color: #fff;
  background-color: #452a73;
}

.badge-outline-primary {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 1px solid #5C3799;
  color: #5C3799;
}
[data-theme-version=dark] .badge-outline-secondary {
  color: #333333;
}

.badge-outline-success {
  border: 1px solid #1EBA62;
  color: #1EBA62;
}

.badge-outline-info {
  border: 1px solid #002381;
  color: #002381;
}

.badge-outline-warning {
  border: 1px solid #FFAB2D;
  color: #FFAB2D;
}

.badge-outline-danger {
  border: 1px solid #fd5353;
  color: #fd5353;
}

.badge-outline-light {
  border: 1px solid #f0f1f5;
  color: #333333;
}
[data-theme-version=dark] .badge-outline-light {
  color: #333333;
}

.badge-outline-dark {
  border: 1px solid #333333;
  color: #333333;
}
[data-theme-version=dark] .badge-outline-dark {
  color: #333333;
}

.badge-xs {
  font-size: 10px;
  padding: 0px 5px;
  line-height: 18px;
}

.badge-sm {
  font-size: 11px;
  padding: 5px 8px;
  line-height: 11px;
}

.badge-lg {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 30px;
}

.badge-xl {
  font-size: 15px;
  padding: 0px 15px;
  line-height: 35px;
}

.badge-default {
  background: #ADB6C7;
}

.badge-success {
  background-color: #1EBA62;
}
.badge-success:hover {
  color: #fff;
  background-color: #229743;
}

.badge-info {
  background-color: #002381;
}
.badge-info:hover {
  color: #fff;
  background-color: #009ff9;
}

.badge-primary {
  background-color: var(--primary);
}
.badge-primary:hover {
  color: #fff;
  background-color: #163dc8;
}

.badge-warning {
  background-color: #FFAB2D;
  color: #212529;
}
.badge-warning:hover {
  color: #212529;
  background-color: #f99500;
}

.badge-danger {
  background-color: #fd5353;
}
.badge-danger:hover {
  color: #fff;
  background-color: #ff1c0e;
}

.badge-dark {
  background-color: #333333;
}
.badge-dark:hover {
  color: #212529;
  background-color: #989898;
}

.badge-light {
  background-color: #dedeed;
  color: #212529;
}
.badge-light:hover {
  color: #212529;
  background-color: #d3d7e7;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-success {
  background-color: #d0f7e1;
  color: #1EBA62;
}
[data-theme-version=dark] .light.badge-success {
  background-color: rgba(30, 186, 98, 0.1);
}

.light.badge-info {
  background-color: #1b59ff;
  color: #002381;
}
[data-theme-version=dark] .light.badge-info {
  background-color: rgba(0, 35, 129, 0.1);
}

.light.badge-primary {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}
[data-theme-version=dark] .light.badge-primary {
  background-color: var(--rgba-primary-1);
}

.light.badge-secondary {
  background-color: #d4c6ea;
  color: #5C3799;
}
[data-theme-version=dark] .light.badge-secondary {
  background-color: rgba(92, 55, 153, 0.5);
  color: #333333;
}

.light.badge-warning {
  background-color: #fff9ef;
  color: #FFAB2D;
}
[data-theme-version=dark] .light.badge-warning {
  background-color: rgba(255, 171, 45, 0.1);
}

.light.badge-danger {
  background-color: #ffeaea;
  color: #fd5353;
}
[data-theme-version=dark] .light.badge-danger {
  background-color: rgba(253, 83, 83, 0.15);
}

.light.badge-dark {
  background-color: #737373;
  color: #333333;
}
[data-theme-version=dark] .light.badge-dark {
  background-color: rgba(51, 51, 51, 0.35);
  color: #333333;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}
.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 5px;
  margin-bottom: 5px;
}
.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}
.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.welcome-text span,
.welcome-text p {
  display: none;
}

.page-titles {
  margin-bottom: 25px;
  display: block;
  margin-left: -40px;
  margin-right: -40px;
  padding: 20px 40px;
  margin-top: -15px;
  background: #F4F4F4;
  display: block;
}
[data-theme-version=dark] .page-titles {
  background: transparent;
}

.page-titles [class*=col-] {
  display: block !important;
  max-width: 100%;
  width: 100%;
  margin: 0;
}
@media only screen and (max-width: 1600px) {
  .page-titles {
    margin-left: -30px;
    margin-top: 0px;
    margin-right: -30px;
    margin-bottom: 30px;
    padding: 15px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .page-titles {
    margin-left: -15px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 0px;
    padding: 15px;
  }
}
.page-titles .justify-content-sm-end {
  align-items: center;
}
.page-titles h4, .page-titles .h4 {
  margin-bottom: 10px;
  margin-top: 0;
  color: #000;
  font-size: 1.75rem;
  font-weight: 600;
}
.page-titles h4 span, .page-titles .h4 span {
  font-size: 1.25rem;
}
.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  /* .breadcrumb-item + .breadcrumb-item {
      &:before {
          content: "\e606";
          font-family: 'simple-line-icons';
          // font-weight: 4;
          font-size: 10px;
      }
  } */
}
.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .page-titles .breadcrumb li {
    font-size: 15px;
  }
}
.page-titles .breadcrumb li a {
  color: #656773;
}
.page-titles .breadcrumb li.active a {
  color: var(--primary);
  font-weight: 600;
}
.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}
.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}
.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}
.page-titles .breadcrumb-widget h4, .page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}
@media only screen and (max-width: 575px) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

button {
  cursor: pointer;
}
button:focus {
  outline: 0;
  box-shadow: none;
}

.btn.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
}
.btn:hover, .btn:focus, .btn:active, .btn.active {
  outline: 0 !important;
}
@media only screen and (max-width: 1400px) {
  .btn {
    padding: 0.625rem 1rem;
  }
}
.btn-transparent {
  background-color: transparent;
}

.sharp {
  min-width: 40px;
  padding: 7px;
  height: 40px;
  min-height: 40px;
}

.sharp.btn-xs {
  padding: 3px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
}

.btn-success {
  color: #ffffff;
}
.btn-success:hover {
  color: #ffffff;
}

.btn-info {
  color: #fff;
}
.btn-info:hover {
  color: #fff;
}

.btn-danger {
  color: #fff;
}
.btn-danger:hover {
  color: #fff;
}

.light.tp-btn {
  background-color: transparent;
}
.light.btn-default {
  background: #ADB6C7;
}
.light.btn-success {
  background-color: #d0f7e1;
  border-color: #d0f7e1;
  color: #1EBA62;
}
.light.btn-success g [fill] {
  fill: #1EBA62;
}
[data-theme-version=dark] .light.btn-success {
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #1EBA62;
  border-color: #1EBA62;
  color: #fff;
}
.light.btn-success:hover g [fill] {
  fill: #fff;
}
.light.btn-info {
  background-color: #1b59ff;
  border-color: #1b59ff;
  color: #002381;
}
.light.btn-info g [fill] {
  fill: #002381;
}
[data-theme-version=dark] .light.btn-info {
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #002381;
  border-color: #002381;
  color: #fff;
}
.light.btn-info:hover g [fill] {
  fill: #fff;
}
.light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}
.light.btn-primary g [fill] {
  fill: var(--primary);
}
[data-theme-version=dark] .light.btn-primary {
  border-color: transparent;
}

.light.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.light.btn-primary:hover g [fill] {
  fill: #fff;
}
.light.btn-primary:hover svg path {
  fill: #fff;
}
.light.btn-secondary {
  background-color: #d4c6ea;
  border-color: #d4c6ea;
  color: #5C3799;
}
.light.btn-secondary g [fill] {
  fill: #5C3799;
}
[data-theme-version=dark] .light.btn-secondary {
  border-color: transparent;
  color: #5C3799;
}

.light.btn-secondary:hover {
  background-color: #5C3799;
  border-color: #5C3799;
  color: #fff;
}
.light.btn-secondary:hover g [fill] {
  fill: #fff;
}
.light.btn-warning {
  background-color: #fff9ef;
  border-color: #fff9ef;
  color: #FFAB2D;
}
.light.btn-warning g [fill] {
  fill: #FFAB2D;
}
[data-theme-version=dark] .light.btn-warning {
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #FFAB2D;
  border-color: #FFAB2D;
  color: #fff;
}
.light.btn-warning:hover g [fill] {
  fill: #fff;
}
.light.btn-danger {
  background-color: #ffeaea;
  border-color: #ffeaea;
  color: #fd5353;
}
.light.btn-danger g [fill] {
  fill: #fd5353;
}
[data-theme-version=dark] .light.btn-danger {
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #fd5353;
  border-color: #fd5353;
  color: #fff;
}
.light.btn-danger:hover g [fill] {
  fill: #fff;
}
.light.btn-dark {
  background-color: #737373;
  border-color: #737373;
  color: #333333;
}
.light.btn-dark g [fill] {
  fill: #333333;
}
[data-theme-version=dark] .light.btn-dark {
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #333333;
  border-color: #333333;
  color: #fff;
}
.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}
.btn.tp-btn.btn-default {
  background: #ADB6C7;
}
.btn.tp-btn.btn-success {
  color: #1EBA62;
}
.btn.tp-btn.btn-success g [fill] {
  fill: #1EBA62;
}
.btn.tp-btn.btn-success:hover {
  background-color: #1EBA62;
  border-color: #1EBA62;
  color: #fff;
}
.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-info {
  color: #002381;
}
.btn.tp-btn.btn-info g [fill] {
  fill: #002381;
}
.btn.tp-btn.btn-info:hover {
  background-color: #002381;
  border-color: #002381;
  color: #fff;
}
.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-primary {
  color: var(--primary);
}
.btn.tp-btn.btn-primary g [fill] {
  fill: var(--primary);
}
.btn.tp-btn.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-secondary {
  color: #5C3799;
}
.btn.tp-btn.btn-secondary g [fill] {
  fill: #5C3799;
}
.btn.tp-btn.btn-secondary:hover {
  background-color: #5C3799;
  border-color: #5C3799;
  color: #fff;
}
.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-warning {
  color: #FFAB2D;
}
.btn.tp-btn.btn-warning g [fill] {
  fill: #FFAB2D;
}
.btn.tp-btn.btn-warning:hover {
  background-color: #FFAB2D;
  border-color: #FFAB2D;
  color: #fff;
}
.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-danger {
  color: #fd5353;
}
.btn.tp-btn.btn-danger g [fill] {
  fill: #fd5353;
}
.btn.tp-btn.btn-danger:hover {
  background-color: #fd5353;
  border-color: #fd5353;
  color: #fff;
}
.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-light {
  color: #333333;
}
.btn.tp-btn.btn-light g [fill] {
  fill: #333333;
}
.btn.tp-btn.btn-light:hover {
  background-color: #dedeed;
  border-color: #dedeed;
  color: #333333;
}
.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn.btn-dark {
  color: #333333;
}
.btn.tp-btn.btn-dark g [fill] {
  fill: #333333;
}
.btn.tp-btn.btn-dark:hover {
  background-color: #333333;
  border-color: #333333;
  color: #fff;
}
.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}
.btn.tp-btn-light.btn-success {
  color: #1EBA62;
}
.btn.tp-btn-light.btn-success g [fill] {
  fill: #1EBA62;
}
.btn.tp-btn-light.btn-success:hover {
  background-color: #d0f7e1;
  border-color: #d0f7e1;
  color: #1EBA62;
}
.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #1EBA62;
}
.btn.tp-btn-light.btn-info {
  color: #002381;
}
.btn.tp-btn-light.btn-info g [fill] {
  fill: #002381;
}
.btn.tp-btn-light.btn-info:hover {
  background-color: #1b59ff;
  border-color: #1b59ff;
  color: #002381;
}
.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #002381;
}
.btn.tp-btn-light.btn-primary {
  color: var(--primary);
}
.btn.tp-btn-light.btn-primary g [fill] {
  fill: var(--primary);
}
.btn.tp-btn-light.btn-primary:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}
.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: var(--primary);
}
.btn.tp-btn-light.btn-secondary {
  color: #5C3799;
}
.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #5C3799;
}
.btn.tp-btn-light.btn-secondary:hover {
  background-color: #d4c6ea;
  border-color: #d4c6ea;
  color: #5C3799;
}
.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #5C3799;
}
.btn.tp-btn-light.btn-warning {
  color: #FFAB2D;
}
.btn.tp-btn-light.btn-warning g [fill] {
  fill: #FFAB2D;
}
.btn.tp-btn-light.btn-warning:hover {
  background-color: #fff9ef;
  border-color: #fff9ef;
  color: #FFAB2D;
}
.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #FFAB2D;
}
.btn.tp-btn-light.btn-danger {
  color: #fd5353;
}
.btn.tp-btn-light.btn-danger g [fill] {
  fill: #fd5353;
}
.btn.tp-btn-light.btn-danger:hover {
  background-color: #ffeaea;
  border-color: #ffeaea;
  color: #fd5353;
}
.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}
.btn.tp-btn-light.btn-dark {
  color: #333333;
}
.btn.tp-btn-light.btn-dark g [fill] {
  fill: #333333;
}
.btn.tp-btn-light.btn-dark:hover {
  background-color: #737373;
  border-color: #737373;
  color: #333333;
}
.btn.tp-btn-light.btn-dark:hover [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(var(--primary), 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(var(--primary), 0.2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(92, 55, 153, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(92, 55, 153, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 5px 15px 0 rgba(255, 171, 45, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(255, 171, 45, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 5px 15px 0 rgba(253, 83, 83, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(253, 83, 83, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 35, 129, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(0, 35, 129, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 5px 15px 0 rgba(30, 186, 98, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(30, 186, 98, 0.2) !important;
}

.btn-xxs {
  padding: 6px 15px;
  font-size: 11px;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  font-weight: 600;
}
@media only screen and (max-width: 1400px) {
  .btn-xs {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
  }
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.813rem !important;
  padding: 0.3rem 1rem;
}
@media only screen and (max-width: 1400px) {
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
  }
}

.btn-md {
  font-size: 0.813rem !important;
  padding: 0.4rem 1.25rem;
}
@media only screen and (max-width: 1400px) {
  .btn-md {
    padding: 0.5rem 1rem;
    font-size: 0.813rem;
  }
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.969rem 2rem;
  font-size: 1.125rem !important;
}
@media only screen and (max-width: 1400px) {
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem !important;
  }
}

.btn-xl {
  padding: 0.7rem 1rem;
}
.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-icon-right {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin: -0.8rem 0 -0.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem;
}
@media only screen and (max-width: 1400px) {
  .btn-icon-right {
    padding: 1rem 0 1rem 0.6rem;
  }
}

.btn-icon-left {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  margin: -0.5rem 0.75rem -0.5rem -1.188rem;
  padding: 0.5rem 0.8rem 0.5rem;
  float: left;
}
@media only screen and (max-width: 1199px) {
  .btn-icon-left {
    margin: -0.5rem 0.75rem -0.5rem -0.8rem;
  }
}

[direction=rtl] .btn-icon-left {
  margin: -0.5rem -1.5rem -0.5rem 1rem;
}
[direction=rtl] .btn-icon-right {
  border-left: 0px solid white;
  display: inline-block;
  margin: -0.8rem 1rem -0.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 1px solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: 0.7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}
.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}
.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}
.btn-circle-md i {
  font-size: 2.4rem;
}
.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}
.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 110px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.size-1 {
  min-width: 160px !important;
  font-size: 24px;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 20px;
  min-width: 130px !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 14px;
  min-width: 110px !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 14px;
  min-width: 100px !important;
}

.size-5 {
  font-size: 14px;
  min-width: 90px !important;
  padding: 0.22rem 0.75rem;
}

.size-6 {
  font-size: 13px;
  min-width: 80px !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 12px;
  min-width: 60px !important;
  padding: 0.001rem 0.75rem;
}

.btn.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}
.btn.btn-outline-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn-light {
  background: #dedeed;
  border-color: #dedeed;
}
[data-theme-version=dark] .btn-light {
  background-color: #1e2746;
  border-color: #333a54;
  color: #fff;
}

.btn-light:active, .btn-light:focus, .btn-light:hover {
  background: #d7d7db;
  border-color: #d7d7db;
}
[data-theme-version=dark] .btn-light:active, [data-theme-version=dark] .btn-light:focus, [data-theme-version=dark] .btn-light:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-light {
  color: #333333;
}
[data-theme-version=dark] .btn-outline-light {
  color: #333333;
}

[data-theme-version=dark] .btn-outline-secondary {
  color: #333333;
}

[data-theme-version=dark] .btn-outline-dark {
  color: #333333;
}

.btn-dark {
  background: #333333;
  border-color: #333333;
  color: #fff;
}
.btn-dark:active, .btn-dark:focus, .btn-dark:hover {
  background: #1a1a1a;
  color: #fff;
  border-color: #1a1a1a;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}
.btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}
.btn-twitter:active, .btn-twitter:focus, .btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}
.btn-youtube:active, .btn-youtube:focus, .btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}
.btn-instagram:active, .btn-instagram:focus, .btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}
.btn-pinterest:active, .btn-pinterest:focus, .btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}
.btn-linkedin:active, .btn-linkedin:focus, .btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}
.btn-google-plus:active, .btn-google-plus:focus, .btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}
.btn-google:active, .btn-google:focus, .btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}
.btn-snapchat:active, .btn-snapchat:focus, .btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}
.btn-whatsapp:active, .btn-whatsapp:focus, .btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}
.btn-tumblr:active, .btn-tumblr:focus, .btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}
.btn-reddit:active, .btn-reddit:focus, .btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}
.btn-spotify:active, .btn-spotify:focus, .btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}
.btn-yahoo:active, .btn-yahoo:focus, .btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}
.btn-dribbble:active, .btn-dribbble:focus, .btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}
.btn-skype:active, .btn-skype:focus, .btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}
.btn-quora:active, .btn-quora:focus, .btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}
.btn-vimeo:active, .btn-vimeo:focus, .btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.btn-block {
  display: block;
  width: 100%;
}

.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 12px 23px 0px rgba(92, 55, 153, 0.04);
  height: calc(100% - 30px);
}
@media only screen and (max-width: 575px) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}
.card-body {
  padding: 1.875rem;
}
@media only screen and (max-width: 575px) {
  .card-body {
    padding: 1rem;
  }
}
.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.card-title--large {
  font-size: 1.5rem;
}
.card-title--medium {
  font-size: 1rem;
}
.card-title--small {
  font-size: 0.875rem;
}
.card-header {
  border-color: #f0f1f5;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
}
@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}
[data-theme-version=dark] .card-header {
  border-color: #333a54;
}

.card-header h4, .card-header .h4 {
  margin-bottom: 0;
}
.card-header .card-title {
  margin-bottom: 0px;
}
.card-header .subtitle {
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
}
.card-footer {
  border-color: #f0f1f5;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}
[data-theme-version=dark] .card-footer {
  border-color: #333a54;
}

@media only screen and (max-width: 575px) {
  .card-footer {
    padding: 1rem;
  }
}

.transparent-card.card {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}

.card-action > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  background: black;
  color: #fff;
  margin-right: 8px;
}
[data-theme-version=dark] .card-action > a {
  background: #181f39;
}

.card-action > a:last-child {
  margin-right: 0;
}
.card-action > a:hover, .card-action > a:focus {
  background: black;
}
[data-theme-version=dark] .card-action > a:hover, [data-theme-version=dark] .card-action > a:focus {
  background: #181f39;
}

.card-action > a i, .card-action > a span {
  font-size: 1rem;
}
.card-action .dropdown {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  margin-right: 8px;
  top: -2px;
  position: relative;
  display: inline-block;
  background: black;
  color: var(--primary);
}
[data-theme-version=dark] .card-action .dropdown {
  background: #181f39;
}

.card-action .dropdown:hover, .card-action .dropdown:focus {
  background: black;
}
[data-theme-version=dark] .card-action .dropdown:hover, [data-theme-version=dark] .card-action .dropdown:focus {
  background: #181f39;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 27px;
  color: #fff;
}
.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: #00195d;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #f0f1f5;
  cursor: pointer;
  border-radius: 0.375rem;
}
.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.carousel-custom-prev, .carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}
.carousel-custom-prev i, .carousel-custom-next i {
  font-size: 1rem;
}
.carousel-custom-prev:hover, .carousel-custom-next:hover {
  background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}
.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 0;
}
.carousel-caption h5, .carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}
.carousel-caption p {
  margin-bottom: 0;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid var(--primary);
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  margin-top: 0;
  border-radius: 0.25rem;
  overflow: hidden;
}
.dropdown-menu .dropdown-item {
  font-size: 16px;
  color: #333333;
  padding: 0.5rem 1.75rem;
}
@media only screen and (max-width: 1400px) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
}
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
  background: #F4F5F9;
}
.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  color: var(--primary) !important;
}
[direction=rtl] .dropdown-menu {
  right: auto !important;
}

.dropdown-toggle-split {
  padding: 0 10px;
  opacity: 0.85;
}
.dropdown-toggle-split:after {
  margin-left: 0 !important;
}
.dropdown-toggle-split:active, .dropdown-toggle-split:focus, .dropdown-toggle-split:hover {
  opacity: 1;
}

/*.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
	content:"\f107";
	font-family:FontAwesome;
	border: 0;
    vertical-align: middle;
    margin-left: 0.25em;
	line-height: 1;
}*/
/*.dropup .dropdown-toggle:after{
	content:"\f106";
}*/
.dropleft .dropdown-toggle::before {
  content: "\f104";
}

.dropright .dropdown-toggle::before {
  content: "\f105";
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}
.custom-dropdown .dropdown-menu {
  border: 0px;
  min-width: 160px;
}

.card-action .custom-dropdown {
  margin: 0px;
  background: #1b59ff;
}
.card-action .custom-dropdown.show, .card-action .custom-dropdown:focus, .card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}
.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 9px;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 5px;
  width: 5px;
  background: rgba(51, 51, 51, 0.4);
  border-radius: 5px;
  display: block;
}
.dropdown .dropdown-dots:after, .dropdown .dropdown-dots:before {
  content: "";
  height: 5px;
  width: 5px;
  background: rgba(51, 51, 51, 0.4);
  position: absolute;
  border-radius: 5px;
}
.dropdown .dropdown-dots:after {
  right: -8px;
}
.dropdown .dropdown-dots:before {
  left: -8px;
}
.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}
.dropdown .dropdown-dots.text-white:after, .dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.2rem 0.8rem;
}
.label-fixed {
  width: 7.5rem;
  padding: 0.6rem 0;
}
.label-fixed-lg {
  width: 9.5rem;
  padding: 0.6rem 0;
}
.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}
.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}
.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}
.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}
.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #5C3799;
  color: #fff;
}

.label-info {
  background: #002381;
  color: #fff;
}

.label-success {
  background: #1EBA62;
  color: #fff;
}

.label-warning {
  background: #FFAB2D;
  color: #fff;
}

.label-danger {
  background: #fd5353;
  color: #fff;
}

.label-light {
  background: #dedeed;
  color: #000;
}

.label-dark {
  background: #333333;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fff9f9;
  color: #fd5353;
}
[data-theme-version=dark] code {
  background: rgba(253, 83, 83, 0.1);
}

.heading-labels {
  color: #333333;
}
.heading-labels > * {
  margin-bottom: 0.8rem;
}
.heading-labels h1 .label, .heading-labels .h1 .label {
  font-size: 18px;
  font-weight: normal;
  padding: 0.4rem 0.9rem;
}
.heading-labels h2 .label, .heading-labels .h2 .label {
  font-size: 16px;
  font-weight: normal;
  padding: 0.3rem 0.9rem;
}
.heading-labels h3 .label, .heading-labels .h3 .label {
  font-size: 14px;
  font-weight: normal;
}
.heading-labels h4 .label, .heading-labels .h4 .label {
  font-size: 12px;
  font-weight: normal;
}
.heading-labels h5 .label, .heading-labels .h5 .label {
  font-size: 12px;
  font-weight: normal;
}
.heading-labels h6 .label, .heading-labels .h6 .label {
  font-size: 12px;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #f0f1f5;
  padding: 1rem 1.5rem;
  color: #7e7e7e;
  border-radius: 0;
}
[data-theme-version=dark] .list-group-item {
  border-color: #333a54;
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.list-group-item + .list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #fff;
  background-color: #5C3799;
  border-color: #5C3799;
}

[class*=bg-] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.media img {
  border-radius: 0.25rem;
}

.vertical-card__menu:hover {
  box-shadow: none;
}
.vertical-card__menu--image {
  text-align: center;
}
.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.vertical-card__menu--status.closed {
  background: #fd5353;
}
.vertical-card__menu--status.closed:after {
  border-top: 10px solid #fd5353;
}
.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 11px solid #fd5353;
  border-bottom: 10px solid #fd5353;
}
.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}
.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}
.vertical-card__menu--rating {
  font-size: 0.75rem;
}
.vertical-card__menu--title {
  font-weight: 700;
}
.vertical-card__menu--button button {
  font-size: 0.75rem;
}
.vertical-card__menu .card-footer {
  padding: 15px 20px;
  background-color: #f0f1f5;
  border-top: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .vertical-card__menu .card-footer {
    padding: 15px;
  }
}
.vertical-card__menu .card-footer span {
  color: #333333;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
  display: block;
}
.horizontal-card__menu .card-body {
  padding: 0;
  padding: 20px 30px;
}
.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
  max-width: unset;
  flex-basis: 100%;
}
.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}
.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}
.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}
.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}
.horizontal-card__menu--rating {
  font-size: 0.625rem;
}
.horizontal-card__menu--footer {
  margin-top: 10px;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}
.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 30px;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 0.25rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 20px;
}
.pagination .page-item {
  margin-right: 0.2rem;
}
.pagination .page-item.page-indicator .page-link {
  padding: 0;
  font-size: 14px;
}
.pagination .page-item.page-indicator:hover .page-link {
  color: #333333;
}
.pagination .page-item .page-link {
  text-align: center;
  width: 45px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #333333;
  z-index: 0;
  border: 1px solid #f0f1f5;
}
[data-theme-version=dark] .pagination .page-item .page-link {
  border-color: #333a54;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i, .pagination .page-item .page-link span {
  color: #fff;
}
.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}
.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}
.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 10px 20px 0px var(--rgba-primary-1);
}
[data-theme-version=dark] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #333333;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.pagination .page-item:last-child .page-link {
  margin-right: 0;
}
[direction=rtl] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}
.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}
[data-theme-version=dark] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #fd5353;
  border-color: #fd5353;
}
[data-theme-version=dark] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #fd5353;
  border-color: #fd5353;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #002381;
  border-color: #002381;
}
[data-theme-version=dark] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #002381;
  border-color: #002381;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFAB2D;
  border-color: #FFAB2D;
}
[data-theme-version=dark] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFAB2D;
  border-color: #FFAB2D;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}
[data-theme-version=dark] .pagination-primary .page-item .page-link {
  background: rgba(0, 35, 129, 0.2);
  border-color: transparent;
  color: var(--primary);
}

.pagination-primary .page-item:hover .page-link, .pagination-primary .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 10px 20px 0px rgba(0, 35, 129, 0.2);
}
[data-theme-version=dark] .pagination-primary .page-item:hover .page-link, [data-theme-version=dark] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #ffeaea;
  border-color: #ffeaea;
  color: #fd5353;
}
[data-theme-version=dark] .pagination-danger .page-item .page-link {
  background: rgba(253, 83, 83, 0.15);
  border-color: transparent;
  color: #fd5353;
}

.pagination-danger .page-item:hover .page-link, .pagination-danger .page-item.active .page-link {
  background: #fd5353;
  border-color: #fd5353;
  box-shadow: 0 10px 20px 0px rgba(253, 83, 83, 0.2);
}
[data-theme-version=dark] .pagination-danger .page-item:hover .page-link, [data-theme-version=dark] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #1b59ff;
  border-color: #1b59ff;
  color: #002381;
}
[data-theme-version=dark] .pagination-info .page-item .page-link {
  background: rgba(0, 35, 129, 0.1);
  border-color: transparent;
  color: #002381;
}

.pagination-info .page-item:hover .page-link, .pagination-info .page-item.active .page-link {
  background: #002381;
  border-color: #002381;
  box-shadow: 0 10px 20px 0px rgba(0, 35, 129, 0.2);
}
.pagination-warning .page-item .page-link {
  background: #fff9ef;
  border-color: #fff9ef;
  color: #FFAB2D;
}
[data-theme-version=dark] .pagination-warning .page-item .page-link {
  background: rgba(255, 171, 45, 0.1);
  border-color: transparent;
  color: #FFAB2D;
}

.pagination-warning .page-item:hover .page-link, .pagination-warning .page-item.active .page-link {
  background: #FFAB2D;
  border-color: #FFAB2D;
  box-shadow: 0 10px 20px 0px rgba(255, 171, 45, 0.2);
}
.pagination-gutter .page-item {
  margin-right: 7px;
}
.pagination-gutter .page-item .page-link {
  border-radius: 0.25rem !important;
}
.pagination-circle .page-item {
  margin-right: 7px;
}
.pagination-circle .page-item .page-link, .pagination-circle .page-item.page-indicator .page-link {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  border-radius: 50% !important;
  padding: 0;
}
.pagination.pagination-md .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 12px;
}
.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 10px;
}
.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-success {
  background-color: #1EBA62;
}

.progress-bar-info {
  background-color: #002381;
}

.progress-bar-danger {
  background-color: #fd5353;
}

.progress-bar-warning {
  background-color: #FFAB2D;
}

.progress-bar-pink {
  background-color: #EB62D0;
}

.progress {
  height: 10px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.25rem;
}
[data-theme-version=dark] .progress {
  background-color: #181f39;
}

.progress-bar {
  border-radius: 4px;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}
@media (max-width: 767.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}
@media (max-width: 767.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}
@keyframes myanimation {
  0% {
    width: 0;
  }
}
.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}
.ribbon__one {
  top: 15px;
  left: -11px;
  min-height: 20px;
  min-width: 52px;
  text-align: center;
  padding: 3px 10px;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}
.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #239132;
  border-left: 11px solid transparent;
  left: 0;
  content: "";
  bottom: -10px;
}
.ribbon__two {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #FFAB2D;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  right: 15px;
  top: 15px;
  border-radius: 3px;
}
.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #fd5353;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.ribbon__four {
  left: 8px;
  top: -8px;
  width: 110px;
  height: 50px;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ribbon__four:after {
  right: -5px;
}
.ribbon__four:before {
  left: -5px;
}
.ribbon__four:after, .ribbon__four:before {
  z-index: -1;
  background-color: #003fe7;
  top: 3px;
  transform: rotate(45deg);
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
}
.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 50px;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}
.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.ribbon-curve {
  position: absolute;
  top: 0;
  right: -6px;
  width: 10px;
  height: 11px;
  border-top: 11px solid #3AB54B;
  border-bottom: 10px solid #3AB54B;
  border-right: 5px solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #F5F5F5;
  width: 8px;
  height: 8px;
}
.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #333333;
}

.multi-steps > li.is-active ~ li:before, .multi-steps > li.is-active:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}
.multi-steps > li.is-active ~ li:after, .multi-steps > li.is-active:after {
  background-color: #f0f1f5;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
  font-size: 0.75rem;
}
.multi-steps > li:before {
  content: "\2713";
  display: block;
  margin: 0 auto 4px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  width: 25px;
  height: 25px;
  line-height: 21px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 5px;
}
.multi-steps > li:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 12px;
  left: 50%;
  top: 12px;
}
[direction=rtl] .multi-steps > li:after {
  left: auto;
  right: 50%;
}

.multi-steps > li:last-child:after {
  display: none;
}
.multi-steps > li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}
.multi-steps > li.is-active ~ li {
  color: #333333;
}
.multi-steps > li.is-active ~ li:before {
  background-color: #f0f1f5;
  border-color: #f0f1f5;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
}
.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}
.default-tab .nav-link:focus, .default-tab .nav-link:hover, .default-tab .nav-link.active {
  color: var(--primary);
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.25rem 0.25rem 0 0;
}
[data-theme-version=dark] .default-tab .nav-link:focus, [data-theme-version=dark] .default-tab .nav-link:hover, [data-theme-version=dark] .default-tab .nav-link.active {
  background-color: var(--primary) -opacity;
  border-color: transparent transparent #333a54 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  border-width: 0 0px 3px 0;
}
.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #002381;
}
.custom-tab-1 .nav-link:focus, .custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #002381;
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0px 3px 0;
}
[data-theme-version=dark] .custom-tab-1 .nav-link:focus, [data-theme-version=dark] .custom-tab-1 .nav-link:hover, [data-theme-version=dark] .custom-tab-1 .nav-link.active {
  background-color: var(--primary) -opacity;
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show > .nav-link {
  background: #4e7eff;
  color: #002381;
  box-shadow: none;
}
[data-theme-version=dark] .nav-pills.light .nav-link.active,
[data-theme-version=dark] .nav-pills.light .show > .nav-link {
  background: rgba(0, 35, 129, 0.2);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-shadow: 0 0 20px 0 rgba(0, 35, 129, 0.2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 8px;
}
.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}
.tooltip-wrapper button {
  margin-bottom: 0.5rem;
}
.tooltip-wrapper button:focus {
  box-shadow: none;
}
.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 12px;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}
.widget-timeline .timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  left: 9px;
  margin-right: -1.5px;
  background: #dedeed;
}
[data-theme-version=dark] .widget-timeline .timeline:before {
  background: #333a54;
}

[direction=rtl] .widget-timeline .timeline:before {
  left: auto;
  right: 9px;
  margin-right: auto;
  margin-left: -1.5px;
}

.widget-timeline .timeline > li {
  margin-bottom: 15px;
  position: relative;
}
.widget-timeline .timeline > li:before {
  content: " ";
  display: table;
}
.widget-timeline .timeline > li:after {
  content: " ";
  display: table;
  clear: both;
}
.widget-timeline .timeline > li > .timeline-panel {
  border-radius: 0.25rem;
  padding: 15px 20px;
  position: relative;
  display: block;
  margin-left: 40px;
  border-width: 1px;
  border-style: solid;
}
.widget-timeline .timeline > li > .timeline-panel span {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
}
.widget-timeline .timeline > li > .timeline-panel p {
  font-size: 14px;
}
.widget-timeline .timeline > li > .timeline-panel:after {
  content: "";
  width: 10px;
  height: 10px;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0px 0px 1px 1px;
  display: block;
  position: absolute;
  left: -5px;
  transform: rotate(45deg);
  top: 15px;
}
.widget-timeline .timeline > li > .timeline-badge {
  border-radius: 50%;
  height: 22px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 22px;
  border-width: 2px;
  border-style: solid;
  background: #fff;
  padding: 4px;
}
[data-theme-version=dark] .widget-timeline .timeline > li > .timeline-badge {
  background-color: #1e2746;
}

.widget-timeline .timeline > li > .timeline-badge:after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
}
[direction=rtl] .widget-timeline .timeline > li > .timeline-badge {
  left: auto;
  right: 19px;
}

.widget-timeline .timeline-body > p {
  font-size: 12px;
}
.widget-timeline .timeline-badge.primary {
  border-color: #4e7eff;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.primary {
  border-color: var(--primary) -opacity;
}

.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary) !important;
  box-shadow: 0 5px 10px 0 rgba(0, 35, 129, 0.2);
}
.widget-timeline .timeline-badge.primary + .timeline-panel {
  background: #4e7eff;
  border-color: #4e7eff;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.primary + .timeline-panel {
  border-color: transparent;
  background-color: var(--primary) -opacity;
}

.widget-timeline .timeline-badge.success {
  border-color: #d0f7e1;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.success {
  border-color: rgba(30, 186, 98, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #1EBA62 !important;
  box-shadow: 0 5px 10px 0 rgba(30, 186, 98, 0.2);
}
.widget-timeline .timeline-badge.success + .timeline-panel {
  background: #d0f7e1;
  border-color: #d0f7e1;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.success + .timeline-panel {
  background-color: rgba(30, 186, 98, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #fff9ef;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 171, 45, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #FFAB2D !important;
  box-shadow: 0 5px 10px 0 rgba(255, 171, 45, 0.2);
}
.widget-timeline .timeline-badge.warning + .timeline-panel {
  background: #fff9ef;
  border-color: #fff9ef;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.warning + .timeline-panel {
  background-color: rgba(255, 171, 45, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #ffeaea;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.danger {
  border-color: rgba(253, 83, 83, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #fd5353 !important;
  box-shadow: 0 5px 10px 0 rgba(253, 83, 83, 0.2);
}
.widget-timeline .timeline-badge.danger + .timeline-panel {
  background: #ffeaea;
  border-color: #ffeaea;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.danger + .timeline-panel {
  background-color: rgba(253, 83, 83, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #1b59ff;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.info {
  border-color: rgba(0, 35, 129, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #002381 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 35, 129, 0.2);
}
.widget-timeline .timeline-badge.info + .timeline-panel {
  background: #1b59ff;
  border-color: #1b59ff;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.info + .timeline-panel {
  background-color: rgba(0, 35, 129, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #737373;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.dark {
  border-color: rgba(51, 51, 51, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #333333 !important;
  box-shadow: 0 5px 10px 0 rgba(51, 51, 51, 0.2);
}
.widget-timeline .timeline-badge.dark + .timeline-panel {
  background: #737373;
  border-color: #737373;
}
[data-theme-version=dark] .widget-timeline .timeline-badge.dark + .timeline-panel {
  background-color: rgba(51, 51, 51, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}
.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 5px;
  border-radius: 0;
  padding: 5px 10px 5px 15px;
}
.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
  border: 0;
  left: -9px;
  width: 7px;
  height: 7px;
}
.widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel {
  border-color: #002381;
}
.widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel:after {
  background: #002381;
}
.widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel {
  border-color: #1EBA62;
}
.widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel:after {
  background: #1EBA62;
}
.widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel {
  border-color: #FFAB2D;
}
.widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel:after {
  background: #FFAB2D;
}
.widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel {
  border-color: #fd5353;
}
.widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel:after {
  background: #fd5353;
}
.widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel {
  border-color: #002381;
}
.widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel:after {
  background: #002381;
}
.widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel {
  border-color: #333333;
}
.widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel:after {
  background: #333333;
}

.widget-stat .media {
  padding: 0px 0;
  align-items: center;
}
.widget-stat .media > span {
  height: 85px;
  width: 85px;
  border-radius: 50px;
  padding: 10px 12px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
}
@media only screen and (max-width: 1400px) {
  .widget-stat .media > span {
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
}
.widget-stat .media .media-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}
@media only screen and (max-width: 1400px) {
  .widget-stat .media .media-body p {
    font-size: 14px;
  }
}
[data-theme-version=dark] .widget-stat .media .media-body p {
  color: #c4c9d5;
}

.widget-stat .media .media-body small,
.widget-stat .media .media-body .small {
  font-size: 75%;
}
.widget-stat .media .media-body h3, .widget-stat .media .media-body .h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}
.widget-stat .media .media-body h4, .widget-stat .media .media-body .h4 {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}
.widget-stat .media .media-body span {
  margin-left: 5px;
}
.widget-stat[class*=bg-] .media > span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}
.widget-stat[class*=bg-] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction=rtl] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 10px;
}

.dlab-widget-1 .card {
  background: #FFE7DB;
}
.dlab-widget-1 .card .card-body p {
  color: #f87533;
}
.dlab-widget-1 .icon {
  float: right;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 28px;
}

.bgl-primary {
  background: #4e7eff;
  border-color: #4e7eff;
}
[data-theme-version=dark] .bgl-primary {
  background-color: rgba(0, 35, 129, 0.2);
  border-color: rgba(0, 35, 129, 0.2);
}

.bgl-secondary {
  background: #d4c6ea;
  border-color: #d4c6ea;
}
[data-theme-version=dark] .bgl-secondary {
  background-color: rgba(92, 55, 153, 0.5);
  border-color: rgba(92, 55, 153, 0.5);
}

.bgl-success {
  background: #d0f7e1;
  border-color: #d0f7e1;
}
[data-theme-version=dark] .bgl-success {
  background-color: rgba(30, 186, 98, 0.1);
  border-color: rgba(30, 186, 98, 0.1);
}

.bgl-warning {
  background: #fff9ef;
  border-color: #fff9ef;
}
[data-theme-version=dark] .bgl-warning {
  background-color: rgba(255, 171, 45, 0.1);
  border-color: rgba(255, 171, 45, 0.1);
}

.bgl-danger {
  background: #ffeaea;
  border-color: #ffeaea;
}
[data-theme-version=dark] .bgl-danger {
  background-color: rgba(253, 83, 83, 0.15);
  border-color: rgba(253, 83, 83, 0.15);
}

.bgl-info {
  background: #1b59ff;
  border-color: #1b59ff;
}
[data-theme-version=dark] .bgl-info {
  background-color: rgba(0, 35, 129, 0.1);
  border-color: rgba(0, 35, 129, 0.1);
}

.bg-primary-light {
  background: rgba(78, 126, 255, 0.5);
}
[data-theme-version=dark] .bg-primary-light {
  background-color: rgba(0, 35, 129, 0.05);
}

.bg-secondary-light {
  background: rgba(212, 198, 234, 0.5);
}
[data-theme-version=dark] .bg-secondary-light {
  background-color: rgba(92, 55, 153, 0.05);
}

.bg-success-light {
  background: rgba(208, 247, 225, 0.5);
}
[data-theme-version=dark] .bg-success-light {
  background-color: rgba(30, 186, 98, 0.05);
}

.bg-warning-light {
  background: rgba(255, 249, 239, 0.5);
}
[data-theme-version=dark] .bg-warning-light {
  background-color: rgba(255, 171, 45, 0.05);
}

.bg-danger-light {
  background: rgba(255, 234, 234, 0.5);
}
[data-theme-version=dark] .bg-danger-light {
  background-color: rgba(253, 83, 83, 0.05);
}

.bg-info-light {
  background: rgba(27, 89, 255, 0.5);
}
[data-theme-version=dark] .bg-info-light {
  background-color: rgba(0, 35, 129, 0.05);
}

.bgl-dark {
  background: #737373;
  border-color: #737373;
}

.bgl-light {
  background: #dedeed;
  border-color: #dedeed;
}

.overlay-box {
  position: relative;
  z-index: 1;
}
.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: #002381;
  z-index: -1;
}

.rating-bar {
  font-size: 13px;
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}
.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}
.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .card-list {
    height: calc(100% - 40px);
  }
}
.card-list.list-left {
  margin-top: 15px;
  margin-left: 15px;
}
.card-list.list-left .card-header {
  padding-left: 0;
}
.card-list.list-left .card-header .photo {
  margin: -15px 15px 0 -15px;
}
.card-list.list-right {
  margin-top: 15px;
  margin-right: 15px;
  text-align: right;
}
.card-list.list-right .card-header {
  padding-right: 0;
}
.card-list.list-right .card-header .photo {
  margin: -15px -15px 0 15px;
}
.card-list.list-right .card-header .photo img {
  width: 100%;
}
.card-list.list-center {
  margin-top: 15px;
}
.card-list.list-center .card-header .photo {
  margin: -15px 0 0 0;
}
.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -20px;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.widget-media .timeline .timeline-panel .media {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}
.widget-media .timeline .timeline-panel .media-primary {
  background: #4e7eff;
  color: #002381;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-primary {
  background-color: rgba(0, 35, 129, 0.2);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #1b59ff;
  color: #002381;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-info {
  background-color: rgba(0, 35, 129, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #fff9ef;
  color: #FFAB2D;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-warning {
  background-color: rgba(255, 171, 45, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #ffeaea;
  color: #fd5353;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-danger {
  background-color: rgba(253, 83, 83, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #d0f7e1;
  color: #1EBA62;
}
[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-success {
  background-color: rgba(30, 186, 98, 0.1);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 14px;
  line-height: 1.5;
}
.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 5px;
}
.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*=bg-] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.form-select {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  height: 45px;
}

select[multiple] {
  height: auto;
}

.form-control {
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #cccccc;
  color: #333333;
  height: 45px;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1400px) {
  .form-control {
    height: 41px;
  }
}
.form-control:hover, .form-control:focus, .form-control.active {
  box-shadow: none;
  background: #fff;
  color: #333333;
  border-color: var(--rgba-primary-8);
}
.form-control-lg {
  min-height: 3.75rem;
  padding: 0.25rem 1.1rem;
  font-size: 1.09375rem;
  border-radius: 0.25rem;
}
.form-control-sm {
  min-height: 2.5rem;
  height: auto;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.875rem 1.5rem;
}

.input-rounded {
  border-radius: 100px;
}

.input-group-append .input-group-text {
  border-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.input-group-prepend .input-group-text:nth-child(2) {
  border-radius: 0;
  border-bottom-right-radius: 0rem;
  border-top-right-radius: 0rem;
}

[data-theme-version=light] .input-primary .form-control,
[data-theme-version=dark] .input-primary .form-control {
  border-color: #002381;
}
[data-theme-version=light] .input-primary .input-group-text,
[data-theme-version=dark] .input-primary .input-group-text {
  background-color: #002381;
  color: #fff;
}
[data-theme-version=light] .input-danger .form-control,
[data-theme-version=dark] .input-danger .form-control {
  border-color: #fd5353;
}
[data-theme-version=light] .input-danger .input-group-text,
[data-theme-version=dark] .input-danger .input-group-text {
  background-color: #fd5353;
  color: #fff;
}
[data-theme-version=light] .input-info .form-control,
[data-theme-version=dark] .input-info .form-control {
  border-color: #002381;
}
[data-theme-version=light] .input-info .input-group-text,
[data-theme-version=dark] .input-info .input-group-text {
  background-color: #002381;
  color: #fff;
}
[data-theme-version=light] .input-success .form-control,
[data-theme-version=dark] .input-success .form-control {
  border-color: #1EBA62;
}
[data-theme-version=light] .input-success .input-group-text,
[data-theme-version=dark] .input-success .input-group-text {
  background-color: #1EBA62;
  color: #fff;
}
[data-theme-version=light] .input-warning .form-control,
[data-theme-version=dark] .input-warning .form-control {
  border-color: #FFAB2D;
}
[data-theme-version=light] .input-warning .input-group-text,
[data-theme-version=dark] .input-warning .input-group-text {
  background-color: #FFAB2D;
  color: #fff;
}

[data-theme-version=light] .input-primary-o .form-control,
[data-theme-version=dark] .input-primary-o .form-control {
  border-color: #002381;
}
[data-theme-version=light] .input-primary-o .input-group-text,
[data-theme-version=dark] .input-primary-o .input-group-text {
  background-color: #ffffff;
  border-color: #cccccc;
  color: #002381;
}
[data-theme-version=light] .input-danger-o .form-control,
[data-theme-version=dark] .input-danger-o .form-control {
  border-color: #fd5353;
}
[data-theme-version=light] .input-danger-o .input-group-text,
[data-theme-version=dark] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #fd5353;
  color: #fd5353;
}
[data-theme-version=light] .input-info-o .form-control,
[data-theme-version=dark] .input-info-o .form-control {
  border-color: #002381;
}
[data-theme-version=light] .input-info-o .input-group-text,
[data-theme-version=dark] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #002381;
  color: #002381;
}
[data-theme-version=light] .input-success-o .form-control,
[data-theme-version=dark] .input-success-o .form-control {
  border-color: #1EBA62;
}
[data-theme-version=light] .input-success-o .input-group-text,
[data-theme-version=dark] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #1EBA62;
  color: #1EBA62;
}
[data-theme-version=light] .input-warning-o .form-control,
[data-theme-version=dark] .input-warning-o .form-control {
  border-color: #FFAB2D;
}
[data-theme-version=light] .input-warning-o .input-group-text,
[data-theme-version=dark] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #FFAB2D;
  color: #FFAB2D;
}

.input-group-text {
  background: #ffffff;
  border: 1px solid #cccccc;
  min-width: 50px;
  display: flex;
  justify-content: center;
  padding: 0.532rem 0.75rem;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin: 0;
}
.input-group-text i {
  font-size: 16px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.dtp .dtp-buttons {
  padding: 0 1em 1em 1em;
  text-align: right;
  background-color: #fff;
}

.custom-file-label {
  height: 40px;
  padding: 0.5rem 0.75rem;
  border-radius: 0;
}

.input-group-prepend .btn {
  z-index: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
}

.custom-select {
  background: none;
  border-color: #f0f1f5;
  color: #333333;
}
.custom-select:focus {
  box-shadow: none;
  border-color: #002381;
  color: #002381;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 1 auto;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  background: #fff;
  border-color: #f0f1f5;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.75rem 0.75rem 0;
}

.custom-file-label {
  height: 40px;
  padding: 0.5rem 0.75rem;
  border-radius: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #f0f1f5;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.custom-file-label:after {
  background: #656C73;
  border: 1px solid #f0f1f5;
  color: #fff;
  border-radius: 0;
}
[data-theme-version=dark] .custom-file-label:after {
  background: #333a54;
  border-color: #333a54;
  color: #333333;
}

.custom_file_input .custom-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.custom-file-label {
  background: #fff;
  border-color: #f0f1f5;
}
[data-theme-version=dark] .custom-file-label {
  background: #181f39;
  border-color: #333a54;
}

.input-group .input-group-text + .form-file .form-control {
  border-radius: inherit;
  margin: 0;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

textarea.form-control {
  min-height: auto;
  height: auto;
}

form.profile-form .form-label {
  display: block;
}

.form-control[type=file] {
  line-height: 33px;
}

.form-control-sm[type=file] {
  line-height: 32px;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  padding: 25px;
  border: 1px solid #F0F1F5;
  border-radius: 4px;
  margin-bottom: 2rem;
}

legend {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-top: -0.25rem;
  background-color: #fff;
  display: inline-block;
  float: none;
  padding: 0 2px;
  width: auto;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: var(--bs-danger);
  padding-left: 0.25rem;
  font-weight: 600;
}

.form-control:disabled, .form-control[readonly] {
  background: #efefef;
  opacity: 1;
  border-color: #efefef !important;
  cursor: default;
}

:root {
  --primary: #002381;
  --secondary: #5C3799;
  --primary-hover: #00154e;
  --primary-dark: black;
  --rgba-primary-1: rgba(0, 35, 129, 0.1);
  --rgba-primary-2: rgba(0, 35, 129, 0.2);
  --rgba-primary-3: rgba(0, 35, 129, 0.3);
  --rgba-primary-4: rgba(0, 35, 129, 0.4);
  --rgba-primary-5: rgba(0, 35, 129, 0.5);
  --rgba-primary-6: rgba(0, 35, 129, 0.6);
  --rgba-primary-7: rgba(0, 35, 129, 0.7);
  --rgba-primary-8: rgba(0, 35, 129, 0.8);
  --rgba-primary-9: rgba(0, 35, 129, 0.9);
  --rgba-secondary-1: rgba(92, 55, 153, 0.1);
  --rgba-secondary-2: rgba(92, 55, 153, 0.2);
  --rgba-secondary-3: rgba(92, 55, 153, 0.3);
  --rgba-secondary-4: rgba(92, 55, 153, 0.4);
  --rgba-secondary-5: rgba(92, 55, 153, 0.5);
  --rgba-secondary-6: rgba(92, 55, 153, 0.6);
  --rgba-secondary-7: rgba(92, 55, 153, 0.7);
  --rgba-secondary-8: rgba(92, 55, 153, 0.8);
  --rgba-secondary-9: rgba(92, 55, 153, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #424562;
}

:root {
  --width: 17.5rem;
  --height: 5rem;
}

.badge.bg-primary {
  background: var(--primary);
}

/* input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: $primary;
    }

} */
.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  border-color: #EEEEEE;
  height: 1.25rem;
  background-color: #F4F4F4;
}
[data-theme-version=dark] .form-check-input {
  background-color: #181f39;
  border-color: #333a54;
}

.rtl .form-check-label:before, .rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  line-height: normal;
}

[data-theme-version=dark] .form-check-label {
  color: #fff;
}

[data-theme-version=dark] .form-group label {
  color: #fff;
}

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600;
}
.toggle-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.3125rem;
  border-radius: 1.25rem !important;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}
.toggle-switch .form-check-input:focus {
  background-image: none !important;
}
.toggle-switch .form-check-input:checked {
  background: var(--rgba-primary-2);
  background-image: none !important;
}
.toggle-switch .form-check-input:checked:after {
  left: 1.25rem;
  background: var(--primary);
}
.toggle-switch .form-check-input:focus {
  box-shadow: none;
}
.toggle-switch .form-check-input:after {
  width: 1.25rem;
  background: #909090;
  height: 1.25rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.1875rem;
  left: 0;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.toggle-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}
.toggle-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}
.toggle-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
}
.toggle-switch .form-check-label {
  cursor: pointer;
}

.form-check-input:focus ~ .form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #EEEEEE;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}
[data-theme-version=dark] .form-check-label::before {
  background-color: transparent;
  border-color: #EEEEEE;
}

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem;
}

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem;
}

.checkbox-info .form-check-input:focus {
  border-color: #002381;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 129, 0.25);
}
.checkbox-info .form-check-input:checked {
  background-color: #002381;
  border-color: #002381;
}
[data-theme-version=dark] .checkbox-info .form-check-input:checked {
  border-color: transparent;
}

.checkbox-danger .form-check-input:focus {
  border-color: #fd5353;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 83, 83, 0.25);
}
.checkbox-danger .form-check-input:checked {
  background-color: #fd5353;
  border-color: #fd5353;
}
[data-theme-version=dark] .checkbox-danger .form-check-input:checked {
  border-color: transparent;
}

.checkbox-success .form-check-input:focus {
  border-color: #1EBA62;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 186, 98, 0.25);
}
.checkbox-success .form-check-input:checked {
  background-color: #1EBA62;
  border-color: #1EBA62;
}
[data-theme-version=dark] .checkbox-success .form-check-input:checked {
  border-color: transparent;
}

.checkbox-warning .form-check-input:focus {
  border-color: #FFAB2D;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 171, 45, 0.25);
}
.checkbox-warning .form-check-input:checked {
  background-color: #FFAB2D;
  border-color: #FFAB2D;
}
[data-theme-version=dark] .checkbox-warning .form-check-input:checked {
  border-color: transparent;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #5C3799;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(92, 55, 153, 0.25);
}
.checkbox-secondary .form-check-input:checked {
  background-color: #5C3799;
  border-color: #5C3799;
}
[data-theme-version=dark] .checkbox-secondary .form-check-input:checked {
  border-color: transparent;
}

.check-switch {
  padding-left: 2.5rem;
}
.check-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}
.check-switch .form-check-label span {
  line-height: 1;
}
.check-switch .form-check-label:after, .check-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}
.check-switch .form-check-input:checked ~ .form-check-label::before {
  background: #fff;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.js-switch + .switchery {
  border-radius: 50px;
  margin-right: 4rem;
}
@media (max-width: 575.98px) {
  .js-switch + .switchery {
    margin-right: 1rem;
  }
}
.js-switch + .switchery > small, .js-switch + .switchery > .small {
  top: 2px;
}
.js-switch.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem;
}
.js-switch.js-switch-lg + .switchery > small, .js-switch.js-switch-lg + .switchery > .small {
  width: 1.75rem;
  height: 1.75rem;
}
.js-switch.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem;
}
.js-switch.js-switch-md + .switchery > small, .js-switch.js-switch-md + .switchery > .small {
  width: 1.25rem;
  height: 1.25rem;
}
.js-switch.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem;
}
.js-switch.js-switch-sm + .switchery > small, .js-switch.js-switch-sm + .switchery > .small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}
.js-switch-square + .switchery {
  border-radius: 0;
}
.js-switch-square + .switchery > small, .js-switch-square + .switchery > .small {
  border-radius: 0;
  top: 2px;
}
.js-switch-square.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem;
}
.js-switch-square.js-switch-lg + .switchery > small, .js-switch-square.js-switch-lg + .switchery > .small {
  width: 1.75rem;
  height: 1.75rem;
}
.js-switch-square.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem;
}
.js-switch-square.js-switch-md + .switchery > small, .js-switch-square.js-switch-md + .switchery > .small {
  width: 1.25rem;
  height: 1.25rem;
}
.js-switch-square.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem;
}
.js-switch-square.js-switch-sm + .switchery > small, .js-switch-square.js-switch-sm + .switchery > .small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction=rtl] .asColorPicker-trigger {
  right: 99%;
  left: auto;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: 0.5rem;
}

.asColorPicker-trigger {
  overflow: hidden;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.daterangepicker td.active {
  background-color: #002381;
}
.daterangepicker td.active:hover {
  background-color: #002381;
}
.daterangepicker button.applyBtn {
  background-color: #002381;
  border-color: #002381;
}

.datepicker.datepicker-dropdown {
  background: #f2f4fa;
  border-radius: 1px;
  border: 1px solid #EEEEEE;
}
.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
  height: 30px;
  width: 30px !important;
  padding: 0;
  text-align: center;
  font-weight: 300;
  border-radius: 50px;
}
.datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #fff;
}
.datepicker.datepicker-dropdown th.datepicker-switch, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
  font-weight: 300;
  color: #333;
}
.datepicker.datepicker-dropdown th.dow {
  font-weight: 300;
}
.datepicker table tr td.selected, .datepicker table tr td.active.active {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  border: 0;
}
.datepicker table tr td.today {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}
.datepicker table tr td.today:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}
.datepicker table tr td.today.disabled {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}
.datepicker table tr td.today.disabled:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.picker__select--month, .picker__select--year {
  height: 2.5em;
}
.picker__input {
  background-color: transparent !important;
}
[data-theme-version=dark] .picker__input {
  background-color: transparent !important;
  border: 1px solid #333a54;
}

.asColorPicker-wrap .form-control {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.25rem;
  border: 1px solid #cccccc;
  height: 56px;
  background: #fff;
}
[data-theme-version=dark] .select2-container--default .select2-selection--single {
  background: #181f39;
  border-color: #333a54;
}

.select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 56px;
  color: #333333;
  padding-left: 15px;
  min-height: 40px;
}

.select2-container--default .select2-selection--multiple {
  border-color: #cccccc;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #f0f1f5;
  background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.select2-container .select2-selection--multiple {
  min-height: 56px;
  padding: 8px;
  color: #333333;
  border-radius: 0.25rem;
  border: 1px solid #cccccc;
}
[data-theme-version=dark] .select2-container .select2-selection--multiple {
  background: #181f39;
  border-color: #333a54;
}

[data-theme-version=dark] .select2-search--dropdown .select2-search__field {
  background: #1e2746;
  border-color: #333a54;
}

.select2-dropdown {
  border-color: #dedeed;
}
[data-theme-version=dark] .select2-dropdown {
  background: #181f39;
  border-color: #333a54;
}

.swal2-popup .swal2-content {
  color: #333333;
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #cccccc;
  background-color: var(--bs-primary-light);
  border-radius: 0.475rem !important;
}

.dropzone .dz-message {
  margin: 0;
  display: flex;
  text-align: left;
}

.dropzone .dz-message .icon-upload-cloud {
  font-size: 3rem;
  color: var(--primary);
}

.dropzone .dz-preview {
  border-radius: 0.475rem !important;
  margin: 0.75rem;
}

.dropzone .dz-preview .dz-image {
  border-radius: 0.475rem !important;
  z-index: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  background: var(--bs-gray-200);
}

.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  margin-left: -20px !important;
  margin-top: -20px !important;
}

.dropzone .dz-success-mark svg,
.dropzone .dz-error-mark svg {
  height: 40px !important;
  width: 40px !important;
}

.dropzone .dz-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.65rem;
  width: 1.65rem;
  font-size: 1rem;
  text-indent: -9999px;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  background-color: var(--bs-body-bg) !important;
  box-shadow: var(--bs-box-shadow);
  border-radius: 100%;
  top: -0.825rem;
  right: -0.825rem;
}

.dropzone .dz-remove:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  content: "";
  mask-size: 40%;
  -webkit-mask-size: 40%;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-gray-600);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-600%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-600%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.dropzone .dz-remove:hover:after {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--primary);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.dropzone .dz-error-message {
  color: var(--bs-danger-inverse);
  background: var(--bs-danger);
}

.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
}

.dropzone.dropzone-queue .dz-message {
  display: none;
}

.dropzone.dropzone-queue .dropzone-panel .dropzone-upload,
.dropzone.dropzone-queue .dropzone-panel .dropzone-remove-all {
  display: none;
}

.dropzone.dropzone-queue .dropzone-item {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  border-radius: 0.475rem;
  padding: 0.5rem 1rem;
  background-color: var(--bs-gray-100);
}

.dropzone.dropzone-queue .dropzone-item .dropzone-file {
  flex-grow: 1;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--bs-gray-600);
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename:hover {
  color: var(--bs-primary);
}

.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--bs-danger);
  text-overflow: ellipsis;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-progress {
  width: 15%;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
  height: 5px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
    transition: none;
  }
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s ease;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete > i {
  transition: color 0.2s ease;
  font-size: 1rem;
  color: var(--bs-gray-600);
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover {
  transition: color 0.2s ease;
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover > i {
  color: var(--bs-primary);
}

.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: color 0.2s ease;
}

.note-toolbar.card-header {
  flex-wrap: wrap !important;
}

.note-icon-caret,
.note-popover {
  display: none;
}

.note-editor.note-frame .panel-heading {
  padding: 0.6rem 1rem 1rem;
  z-index: 1;
}

.note-editor.note-frame {
  border-color: #f0f1f5;
}

.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
}
[data-theme-version=dark] .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #181f39;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

/* Hide font family options in Summernote toolbar */
.note-editor .note-toolbar .note-fontname {
  display: none;
}

.signature-pad canvas {
  color: #002381;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  touch-action: none;
  user-select: none;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .table {
    font-size: 1rem !important;
  }
}
.table strong {
  color: #333333;
}
.table td {
  border-color: #f0f1f5;
  padding: 0.5rem 0.75rem;
}
[data-theme-version=dark] .table td {
  border-color: #333a54;
}

.table.shadow-hover tr:hover {
  background-color: #fff;
  box-shadow: 0 0px 30px rgba(0, 35, 129, 0.15);
}
[data-theme-version=dark] .table.shadow-hover tr:hover {
  background-color: #181f39;
}

.table.bg-secondary-hover th, .table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}
.table.bg-secondary-hover tr:hover {
  background: #5C3799;
}
.table.bg-secondary-hover tr:hover td, .table.bg-secondary-hover tr:hover th {
  color: #fff !important;
}
.table.bg-info-hover th, .table.bg-info-hover td {
  border: none;
  font-weight: 500;
}
.table.bg-info-hover tr:hover {
  background: #002381;
}
.table.bg-info-hover tr:hover td, .table.bg-info-hover tr:hover th {
  color: #fff !important;
}
.table.bg-warning-hover th, .table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}
.table.bg-warning-hover tr:hover {
  background: #FFAB2D;
}
.table.bg-warning-hover tr:hover td, .table.bg-warning-hover tr:hover th {
  color: #fff !important;
}
.table thead th {
  border-bottom-width: 1px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-color: #f0f1f5;
}
[data-theme-version=dark] .table thead th {
  border-color: #333a54;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #f0f1f5;
}
[data-theme-version=dark] .table tbody tr td {
  border-color: #333a54;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}
.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}
.table .thead-info th {
  background-color: #002381;
  color: #fff;
}
.table .thead-warning th {
  background-color: #FFAB2D;
  color: #fff;
}
.table .thead-danger th {
  background-color: #fd5353;
  color: #fff;
}
.table .thead-success th {
  background-color: #1EBA62;
  color: #fff;
}
.table.primary-table-bordered {
  border: 1px solid #f0f1f5;
}
[data-theme-version=dark] .table.primary-table-bordered {
  border-color: #333a54;
}

.table.primary-table-bg-hover thead th {
  background-color: var(--primary-dark);
  color: #fff;
  border-bottom: none;
}
.table.primary-table-bg-hover tbody tr {
  background-color: var(--primary);
  color: #fff;
  transition: all 0.1s ease;
}
.table.primary-table-bg-hover tbody tr:hover {
  background-color: var(--rgba-primary-4);
}
.table.primary-table-bg-hover tbody tr:not(:last-child) td, .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid var(--primary-hover);
}
.table-responsive-tiny {
  min-width: 18.75rem;
}
.table-responsive-sm {
  min-width: 30rem !important;
}
.table-responsive-md {
  min-width: 36rem !important;
}
.table-responsive-lg {
  min-width: 60.9375rem !important;
}
.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}
[data-theme-version=dark] .table-primary,
[data-theme-version=dark] .table-primary > th,
[data-theme-version=dark] .table-primary > td {
  background-color: rgba(0, 35, 129, 0.2);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d0f7e1;
  color: #1EBA62;
}
[data-theme-version=dark] .table-success,
[data-theme-version=dark] .table-success > th,
[data-theme-version=dark] .table-success > td {
  background-color: rgba(30, 186, 98, 0.1);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #1b59ff;
  color: #002381;
}
[data-theme-version=dark] .table-info,
[data-theme-version=dark] .table-info > th,
[data-theme-version=dark] .table-info > td {
  background-color: rgba(0, 35, 129, 0.1);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff9ef;
  color: #FFAB2D;
}
[data-theme-version=dark] .table-warning,
[data-theme-version=dark] .table-warning > th,
[data-theme-version=dark] .table-warning > td {
  background-color: rgba(255, 171, 45, 0.1);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffeaea;
  color: #fd5353;
}
[data-theme-version=dark] .table-danger,
[data-theme-version=dark] .table-danger > th,
[data-theme-version=dark] .table-danger > td {
  background-color: rgba(253, 83, 83, 0.15);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f2f4fa;
}
[data-theme-version=dark] .table-active,
[data-theme-version=dark] .table-active > th,
[data-theme-version=dark] .table-active > td {
  background-color: #181f39;
}

.card-table th:first-child, .card-table td:first-child {
  padding-left: 30px;
}
@media only screen and (max-width: 575px) {
  .card-table th:first-child, .card-table td:first-child {
    padding-left: 15px;
  }
}
.card-table th:last-child, .card-table td:last-child {
  padding-right: 30px;
}
@media only screen and (max-width: 575px) {
  .card-table th:last-child, .card-table td:last-child {
    padding-right: 15px;
  }
}

.table.table-responsive-md .custom-checkbox {
  display: flex;
  align-items: center;
}
.table.table-responsive-md .form-check-input {
  margin-bottom: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: #f9f9f9;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #f6f6f6;
}

table.dataTable {
  color: #000;
  border-radius: 0.25rem;
}
[data-theme-version=dark] table.dataTable {
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 1rem 0.75rem;
  border-bottom: 1px solid #f0f1f5;
}
@media only screen and (max-width: 1400px) {
  table.dataTable thead th,
table.dataTable thead td {
    padding: 8px 15px;
  }
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody td {
  color: var(--bs-body-color);
  background: #fff !important;
}
[data-theme-version=dark] table.dataTable tbody td {
  background-color: #1e2746 !important;
  border-color: #333a54 !important;
}

table.dataTable thead th {
  color: #000;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 400;
}
[data-theme-version=dark] table.dataTable thead th {
  color: #fff;
  border-color: #333a54;
}

@media only screen and (max-width: 1400px) {
  table.dataTable thead th {
    font-size: 16px;
  }
}

table.dataTable tbody td {
  font-size: 16px;
  padding: 0.6rem 0.75rem;
}

table.dataTable tfoot th {
  padding: 0.75rem 0.75rem;
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable.border-no td {
  border-color: #fff !important;
}

table.dataTable tfoot th {
  color: #333333;
  font-weight: 600;
}
[data-theme-version=dark] table.dataTable tfoot th {
  color: #fff;
}

.dataTables_wrapper input[type=search], .dataTables_wrapper input[type=text], .dataTables_wrapper select {
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
  color: rgb(113, 93, 93);
  border-radius: 5px;
}
[data-theme-version=dark] .dataTables_wrapper input[type=search], [data-theme-version=dark] .dataTables_wrapper input[type=text], [data-theme-version=dark] .dataTables_wrapper select {
  background: #181f39;
  border-color: #333a54;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px;
}
[data-theme-version=dark] .dataTables_wrapper .dataTables_length {
  color: #fff;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 80px !important;
  margin: 0 5px;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.min-w850 {
  min-width: 850px;
}

.rounded-lg {
  min-width: 30px;
}

.dataTables_scroll {
  padding: 1rem 0;
}
.dataTables_scrollFoot {
  padding-top: 1rem;
}

@media (max-width: 767.98px) {
  .dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTablesCard {
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  border-radius: 0;
}
[data-theme-version=dark] .dataTablesCard {
  background-color: #1e2746;
}
[data-theme-version=dark] .dataTablesCard tbody tr:hover {
  background: #1e2746 !important;
}

.dataTablesCard tbody tr:hover {
  background: #fff !important;
}
.dataTablesCard tbody tr:hover td {
  background: transparent !important;
}

.dataTables_wrapper .dataTables_info {
  padding: 15px 0;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #f0f1f5;
}
[data-theme-version=dark] table.dataTable.row-border tbody th,
[data-theme-version=dark] table.dataTable.row-border tbody td,
[data-theme-version=dark] table.dataTable.display tbody th,
[data-theme-version=dark] table.dataTable.display tbody td {
  border-color: #333a54;
}

table.dataTable thead .sorting {
  background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate {
  background: transparent;
  border-radius: 0.25rem;
}
.dataTables_wrapper .dataTables_paginate span {
  border-radius: 0;
  display: inline-block;
  margin: 0 0;
}
.dataTables_wrapper .dataTables_paginate span a {
  color: #fff;
  background: transparent !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 0 !important;
  padding: 7px 14px;
  border: 0px solid var(--primary) !important;
  background: var(--rgba-primary-1) !important;
  border-radius: 0.25rem;
  color: var(--primary) !important;
  font-size: 16px;
  margin: 3px 3px;
  display: inline-block;
}
[data-theme-version=dark] .dataTables_wrapper .dataTables_paginate .paginate_button {
  background: #1e2746 !important;
  color: #fff !important;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 8px 15px;
    font-size: 14px;
  }
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  background: var(--primary) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  background: transparent !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  margin: 4px;
}
[data-theme-version=dark] .dataTables_wrapper .dataTables_paginate .paginate_button.previous, [data-theme-version=dark] .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  border-color: #333a54 !important;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background: var(--primary) !important;
  color: #fff !important;
}

table.dataTable thead th {
  color: var(--primary);
}

@media (min-width: 768px) {
  .dataTables_wrapper .dataTables_filter {
    float: left;
    text-align: right;
    margin-right: 1rem;
  }
}
.dataTables_wrapper input[type=search] {
  margin-left: 1rem;
  padding: 0.5rem 0.5rem !important;
}

.dataTables_wrapper .dataTables_length {
  margin-top: 20px;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 0;
}

table.dataTable .form-select {
  height: 40px;
}

.dt-buttons .btn {
  padding: 0.5rem 1rem !important;
}

.enhanced-table {
  width: 100% !important;
}

.dataTables_length,
.dataTables_filter,
.dt-buttons,
.dataTables_paginate,
.dataTables_info {
  float: none !important;
  text-align: left !important;
  margin: 0 !important;
}

.dataTables_length {
  margin: 15px 0 !important;
}

.dataTables_wrapper .dataTables_paginate span {
  display: inline !important;
}

table.dataTable thead th.enhanced-table-column-not-sortable {
  color: var(--bs-body-color) !important;
}