//customize bootstrap

//to ignore cli warning about deprecated mixin

$enable-deprecation-messages: false;
$ignore-warning             : true;

// Custom class not bootstrap

$fn:'HelveticaNeue'; // fn = font normal 
$fm:'HelveticaNeueMed'; // fm = font Medium 
$border-radius: 0.75rem;

// Body

$body-bg   : #f7f7f7; // #f4f4f4
$body-color: #333333; // #7e7e7e
$headings-color: #424562;

// Typography

$font-family-base: 'Roboto', sans-serif;
$headings-font-weight: 500;

$font-size-base     : 0.875rem !default;
$font-weight-base   : 400 !default;

$h1-font-size       : 2.25rem;
$h2-font-size       : 1.875rem;
$h3-font-size       : 1.5rem;
$h4-font-size       : 1.125rem;
$h5-font-size       : 1rem;
$h6-font-size       : 0.938rem;

// Color system

$white   : #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black   : #000 !default;

$blue  : #374C98;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink  : #EB62D0 !default;
$red   : #EE3232;
$orange: #ff9900 !default;
$yellow: #FFFA6F;
$green : #297F00;
$teal  : #20c997 !default;
$cyan  : #3065D0;

$grays: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900),
  $grays);

$muted     : #89879f;
$text-muted: #89879f;
$mine-shaft: #262626;
$ebony-clay: #232833;

$colors: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge((
	"blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800),
  $colors);

$primary  : #002381 !default; // #2953E8
$secondary: #5C3799 !default;
$success  : #1EBA62 !default;
$info     : #002381 !default; // #00ADA3
$warning  : #FFAB2D !default;
$danger   : #fd5353 !default;
$light    : #dedeed !default;
$dark     : #333333; // #5b5e81

:root{
	--primary: #{$primary};
	--secondary: #{$secondary};
	--primary-hover: #{darken($primary, 10)};
	--primary-dark: #{darken($primary, 30)};
	--rgba-primary-1: #{rgba($primary, 0.1)};
	--rgba-primary-2: #{rgba($primary, 0.2)};
	--rgba-primary-3: #{rgba($primary, 0.3)};
	--rgba-primary-4: #{rgba($primary, 0.4)};
	--rgba-primary-5: #{rgba($primary, 0.5)};
	--rgba-primary-6: #{rgba($primary, 0.6)};
	--rgba-primary-7: #{rgba($primary, 0.7)};
	--rgba-primary-8: #{rgba($primary, 0.8)};
	--rgba-primary-9: #{rgba($primary, 0.9)};
	--rgba-secondary-1: #{rgba($secondary, 0.1)};
	--rgba-secondary-2: #{rgba($secondary, 0.2)};
	--rgba-secondary-3: #{rgba($secondary, 0.3)};
	--rgba-secondary-4: #{rgba($secondary, 0.4)};
	--rgba-secondary-5: #{rgba($secondary, 0.5)};
	--rgba-secondary-6: #{rgba($secondary, 0.6)};
	--rgba-secondary-7: #{rgba($secondary, 0.7)};
	--rgba-secondary-8: #{rgba($secondary, 0.8)};
	--rgba-secondary-9: #{rgba($secondary, 0.9)};
	--font-family-base: #{$font-family-base};
	--font-family-title: #{$font-family-base};
	--font-family-base: #{$font-family-base};
	--font-family-title: #{$font-family-base};
	--title: #{$headings-color}; 
}


$theme-colors: () !default;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark),
  $theme-colors);

:root{
	--width: 17.5rem;
	--height: 5rem;
}

.badge.bg-primary{
	background: var(--primary);
}


$heading-primary-size: 1.8rem;

$sub-heading-size: 1.6rem !default;

$grid-gutter-width: 30px;

$dropdown-lik-color: $body-color;

$border-color: #EEEEEE;

$headings-color:#3d4465 !default;

$rounded : 0.75rem;

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px);
  
// finish customizing bootstrap  

$strong: #646c9a;
$border: #f0f1f5;
$shadow: 0px 0Epx 40px 0px rgba(82,63,105,0.1);
$body-bg: #F4F4F4;

// Solid Colors

$white: #fff;
$black: #000;
$charade: #23252F;

// Dark Version Colors

$d-ctd: #ddd;
$d-ctl: #828690;
$d-border: #333a54;
$d-bg: #181f39;
$dark-card: #1e2746;
$dark_bg_lighter: #1E2A4A;

// Primary Light Colors

$primary-light: lighten($primary, 40%);
$secondary-light: lighten($secondary, 44%);
$success-light: lighten($success, 47%);
$warning-light: lighten($warning, 38%);
$danger-light: lighten($danger, 30%);
$info-light: lighten($info, 30%);
$dark-light: lighten($dark, 25%);

// Opacity 

$primary-opacity: rgba($primary, 0.2);
$secondary-opacity: rgba($secondary, 0.5);
$success-opacity: rgba($success, 0.1);
$warning-opacity: rgba($warning, 0.1);
$danger-opacity: rgba($danger, 0.15);
$info-opacity: rgba($info, 0.1);
$dark-opacity: rgba($dark, 0.35);

// Light Version Color

$l-ctd: #464a53;
$l-ctl: #828690;
$l-border: #eaeaea;
$l-bg: #f2f4fa;
$heading: #333;

// Material Colors

$dusty-gray: #999999;
$gallery: #EEEEEE;
$gray: #898989;
$input-border-color: $border;

// Social Colors

$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #FF0000;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$riverBed: #4C585C;
$vimeo: #1ab7ea;

// Misc Colors

$iron: #DDDFE1;
$grey: #D2D6DE;
$pale-sky: #6a707e;
$athensGray: #F0F0F2;
$sky: #2290FF;

// Colors use for color pallets of the dashboard configuration

$color_pallate_1: $white;
$color_pallate_2: #143b64;
$color_pallate_3: $primary;
$color_pallate_4: #4527a0;
$color_pallate_5: #c62828;
$color_pallate_6: #283593;
$color_pallate_7: #7356f1;
$color_pallate_8: #3695eb;
$color_pallate_9: #00838f;
$color_pallate_10: #ff8f16;
$color_pallate_11: #6673fd;
$color_pallate_12: #558b2f;
$color_pallate_13: #2a2a2a;
$color_pallate_14: #1367c8;
$color_pallate_15: #FF005C;

// utility variables

$radius: 0.25rem; 


//

