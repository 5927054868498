//demo styles

table.dataTable{
	color:$black;
	border-radius:$radius;
	@at-root [data-theme-version="dark"] & {
		color:$white;
	}
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding: 10px 0;
	//@include respond('laptop') {
	//	padding:5px 0;
	//}
}
table.dataTable thead th, 
table.dataTable thead td {
    padding: 1rem 0.75rem;
    border-bottom: 1px solid $border;
    @include respond('laptop') {
		padding:8px 15px;
	}
}

table.dataTable tfoot th,
table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody td{
    color: var(--bs-body-color);
	background: #fff!important;
	@at-root [data-theme-version="dark"] & {
		background-color:$dark-card !important;
		border-color:$d-border !important;
	}
}

table.dataTable thead th{
    color: $black;
	font-size:16px;
	white-space: nowrap;
    font-weight: 400;
    @at-root [data-theme-version="dark"] & {
        color: $white;
		border-color: $d-border;
    }
	@include respond('laptop') {
		font-size:16px;
	}
}

table.dataTable tbody td{
	font-size:16px;
    padding: 0.6rem 0.75rem;
	//@include respond('laptop') {
	//	padding:8px 15px;
	//	font-size:14px;
	//}
}

table.dataTable tfoot th {
	padding: 0.75rem 0.75rem;
}

table.dataTable tr.selected{
    color: var(--primary);
}
table.dataTable.border-no td{
	border-color:$white!important;
}
table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid #ccc;
        padding: 0.5rem 0.5rem;
		//margin-left: 1rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 5px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		   @at-root [data-theme-version="dark"] & {
			color:$white;
		   }
		.bootstrap-select{
			width: 80px!important;
			margin: 0 5px;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}
.min-w850{
	min-width:850px;
}

.rounded-lg{
	min-width:30px;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
} 
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}
.dataTablesCard{
	background-color: #fff;
	box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
	border-radius: 0;
	@at-root [data-theme-version="dark"] & {
		background-color:$dark-card;
		tbody tr:hover{
			background:$dark-card!important;
		}
	}
	tbody tr:hover{
		background:$white!important;
		td{
			background:transparent!important;
		}
	}
}



.dataTables_wrapper .dataTables_info {
    padding:15px 0;
}


table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}
table.dataTable thead .sorting{
	background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate{
	background:transparent;
	border-radius:$radius;
	span{
		border-radius:0;
		display: inline-block;
		margin: 0 0;
			
		a{
			color:$white;
			background: transparent !important;
		}
	}
	.paginate_button{
		border: 0 !important;
		padding: 7px 14px;
		border:0px solid var(--primary) !important;
		background: var(--rgba-primary-1) !important;
		border-radius: $radius;
		color:var(--primary) !important;
		font-size:16px;
		margin: 3px 3px;
		display:inline-block;
		 @at-root [data-theme-version="dark"] & {
			background: $dark-card !important;
			color:$white !important;
		 }
		@include respond('laptop') {
			padding: 8px 15px;
			font-size:14px;
		}
		&:hover,
		&.current:hover,
		&.current{
			color:$white !important;
			background:var(--primary) !important;
		}
		
		&.previous,
		&.next{
			background:transparent!important;
			color:var(--primary) !important;
			border:1px solid var(--primary) !important;
			margin: 4px;
			 @at-root [data-theme-version="dark"] & {
				border-color:$d-border !important;
				color:$white !important;
			 }
			&:hover{
				background:var(--primary) !important;
				color:$white !important;
			}
			
		}
	}
}

table.dataTable thead th {
  color: var(--primary);
}  

@media (min-width: 768px) {

	.dataTables_wrapper .dataTables_filter {
	  float: left;
	  text-align: right;
	  margin-right: 1rem;
	}

}	

.dataTables_wrapper input[type="search"] {
	margin-left: 1rem;
	padding: 0.5rem 0.5rem !important;
}

.dataTables_wrapper .dataTables_length {
  margin-top: 20px;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
	border-top: 0;
}

table.dataTable .form-select {
	height: 40px;
}

.dt-buttons .btn {
	padding: 0.5rem 1rem !important;
}

.enhanced-table {
	width: 100% !important;
}

.dataTables_length, 
.dataTables_filter,
.dt-buttons,
.dataTables_paginate,
.dataTables_info {
	float: none !important;
	text-align: left !important;
	margin: 0 !important;
}

.dataTables_length {
	margin: 15px 0 !important;
}

.dataTables_wrapper .dataTables_paginate span {
  display: inline !important;
}  

table.dataTable thead th.enhanced-table-column-not-sortable {
	color: var(--bs-body-color) !important;
}



















